import React from "react";
import ReactPaginate from "react-paginate";

export default function PaginatedItems({
  total,
  pageNo,
  setPageNo,
  handlePageChange = () => {},
  pageSize = 50,
}) {
  const itemsPerPage = pageSize;
  const pageCount = Math.ceil(total / itemsPerPage);

  const handlePageClick = (event) => {
    handlePageChange();
    setPageNo(event.selected + 1);
    // const newOffset = ((event.selected + 1) * itemsPerPage) % total;
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div></div>
      <ReactPaginate
        className={"pagination"}
        previousClassName={`page-item first-page `}
        previousLinkClassName={`pagination-link ${
          pageNo === 1 ? "disabled" : ""
        }`}
        nextClassName={"page-item last-page"}
        nextLinkClassName={`pagination-link ${
          pageNo === pageCount ? "disabled" : ""
        }`}
        pageClassName={"page-item "}
        pageLinkClassName={"pagination-link"}
        activeLinkClassName={"active-page"}
        breakLabel="..."
        nextLabel={<i className="fa-solid fa-angle-right"></i>}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<i className="fa-solid fa-angle-left"></i>}
        renderOnZeroPageCount={null}
        forcePage={pageNo - 1}
      />
      <p style={{ marginTop: "2rem", marginBottom: "0px" }}>
        Total Results: <b>{total}</b>
      </p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getStates, sendClientCardRequest } from "../../api/server";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ValidationSchema = Yup.object().shape({
  firm_name: Yup.string().required("Firm name is required"),
  care_of: Yup.string(),
  addr1: Yup.string().required("Address is required"),
  addr2: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.number().required("Zip is required"),
  phone: Yup.string("Phone number must be number").required(
    "Phone number is required"
  ),
  email: Yup.string().email("Invalid email").required("Email is required"),
  amount: Yup.number().required("Amount is required"),
});

const RequestClientCards = () => {
  const [statesData, setStatesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    await sendClientCardRequest(values)
      .then((res) => {
        toast.success("Request submitted successfully");
        resetForm();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await getStates();
        const data = response.data;
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states data:", error);
      }
    };
    fetchStatesData();
  }, []);

  return (
    <div>
      <Header />
      <div className="theme_container">
        <div className="my-profile">
          <h1 id="firstcourse">
            Request<span className="text_success"> Client Cards</span>
          </h1>
          <div className="head mt-60">
            <h1 className="f18">Order Information</h1>
          </div>
        </div>
        <div className="row mt-40">
          <Formik
            initialValues={{
              type: "Atty",
              firm_name: "",
              care_of: "",
              addr1: "",
              addr2: "",
              city: "",
              state: "",
              zip: "",
              phone: "",
              email: "",
              amount: "",
            }}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setValues, errors }) => (
              <Form>
                <div className="row mt-40">
                  <div className="col-lg-6 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Law Firm Name</label>
                      <Field
                        type="text"
                        name="firm_name"
                        className="form-control"
                        placeholder="Enter law firm name"
                      />
                      <ErrorMessage
                        name="firm_name"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">C/O</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="care_of"
                        placeholder="Enter C/O"
                      />
                      <ErrorMessage
                        name="care_of"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                </div>
                <div className="head mt-40">
                  <h1 className="f18">Contact Info</h1>
                </div>
                <div className="row mt-40">
                  <div className="col-lg-6 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Address Line 1</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="addr1"
                        placeholder="Enter your address"
                      />
                      <ErrorMessage
                        name="addr1"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Address Line 2</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="addr2"
                        placeholder="Enter your address"
                      />
                      <ErrorMessage
                        name="addr2"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">City</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="Enter your city"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">State</label>

                      <Field name="state">
                        {({ field }) => (
                          <select
                            {...field}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                state: e.target.value,
                              });
                            }}
                            className="form-control"
                          >
                            {statesData.length > 0 && (
                              <option value="">Select a State</option>
                            )}

                            {statesData.length > 0 ? (
                              statesData.map((state, index) => (
                                <option key={index} value={state.state}>
                                  {state.state}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                        )}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Zip Code</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="zip"
                        placeholder="Enter zip code"
                      />
                      <ErrorMessage
                        name="zip"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Phone Number</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Enter your phone no."
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">E-mail Address</label>
                      <Field
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter certificate email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="input_wrap">
                      <label className="label-title" htmlFor="securedLoanType">
                        Cards Needed Per Month
                      </label>
                      <Field
                        as="select"
                        id="securedLoanType"
                        name="amount"
                        className="form-control custom_select"
                      >
                        <option value="">Quantity</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                      </Field>
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                </div>

                <div className="btn_wrap text-center mt-40">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn_brnd"
                    onClick={() => {
                      if (Object.keys(errors).length > 0) {
                        toast.error("Form validation failed");
                      }
                    }}
                  >
                    {loading ? (
                      <ThreeDots
                        height="20"
                        width="110"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Submit Order"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RequestClientCards;

import React from "react";
import { ThreeDots } from "react-loader-spinner";
import cross from "../assets/images/SiteImg/cross.png";

const DeleteModal = ({
  loading,
  showModal,
  setDelete,
  handleSubmit,
  type = "submit",
}) => {
  return (
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
      className={`modal ${showModal !== "" ? "show" : ""} fade custom_modal `}
      style={{
        display: showModal !== "" ? "block" : "none",
        background: "rgba(0,0,0,0.5)",
      }}
    >
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ maxWidth: "600px", margin: "0px auto" }}
        >
          <button
            type="button"
            className="btn_close"
            data-bs-dismiss="modal"
            onClick={() => {
              setDelete("");
            }}
            disabled={loading}
          >
            <img src={cross} alt="cross" className="cross_btn" />
          </button>
          <div className="modal-body">
            <div className="modal_content">
              <h1 className="f-26 text-center">
                Are you <span className="text_success">sure</span> ?
              </h1>
              <p className="f-16 text-center mt-12">
                Do you really want to delete it permanently?
              </p>
              <div className="btn_wrap text-center">
                <button
                  className={`btn_brnd btn_delete ${loading ? "deleting" : ""}`}
                  disabled={loading}
                  type={type}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <ThreeDots
                      height="20"
                      width="52"
                      radius="9"
                      color="#ed1717"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  className="btn_brnd"
                  type="button"
                  onClick={() => {
                    setDelete("");
                  }}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;

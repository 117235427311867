import React, { useEffect, useState } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { Link, useLocation } from "react-router-dom";
import { getBudgetanalysisDataAdmin } from "../../api/server";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setBudgetAnalysis } from "../../store/userSlice";
import { ThreeDots } from "react-loader-spinner";

const BudgetAnalysis = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");

  const [showData, setshowData] = useState();
  const [getLoading, setGetLoading] = useState(true);

  function formatValue(value) {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) ? parsedValue.toFixed(2) : 0;
  }

  useEffect(() => {
    getBudgetanalysisDataAdmin(userId)
      .then((res) => {
        setshowData(res.data);
        dispatch(setBudgetAnalysis(res.data));
        setGetLoading(false);
      })
      .catch((err) => {
        setGetLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <h1 className="card-title mb-4">Budget Analysis</h1>
          <section className="breadcrumb-section">
            <nav
              style={{ "--bs-breadcrumb-divider": " '>';" }}
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Clients Budget Analysis</a>
                </li>
                <li className="breadcrumb-item " aria-current="page">
                  Disclosures
                </li>
                <li className="breadcrumb-item " aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </section>
          {getLoading ? (
            <p>
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </p>
          ) : (
            showData && (
              <section className="budget-analysis">
                <div className="card main-card">
                  <div className="card-body have-border">
                    <h6>Analyzing your unique situation.</h6>
                    <p>
                      Prepared for:{" "}
                      <b>
                        {showData.firstname} {showData.lastname}
                      </b>{" "}
                      by Evergreen Financial Counseling.
                      <br />
                      Now that you have entered all of your information, it's
                      time to look for patterns and identify your options. The
                      more
                      <br />
                      information you provided, the more assistance we can
                      provide. Be hopeful! We promise you will feel better after
                      <br />
                      going through this exercise.
                    </p>
                  </div>

                  <div className="card-body have-border">
                    <h6>Monthly Household Income</h6>
                    <p className="mb-0">
                      <b className="text-dark">GROSS</b> income{" "}
                      <b className="text_success">
                        ${showData.Tab1.monthlyHouseholdIncome.Gross}
                      </b>
                    </p>
                    <p className="mb-0">
                      Amount withheld for deductions, credits, and taxes{" "}
                      <b className="text_success">
                        -${showData.Tab1.monthlyHouseholdIncome.Deductions}
                      </b>
                    </p>
                    <p className="mb-0">
                      NET income{" "}
                      <b className="text_success">
                        ${showData.Tab1.monthlyHouseholdIncome.Netincome}
                      </b>
                    </p>
                    <p className="mb-0">
                      Household expenses{" "}
                      <b className="text_danger">
                        -$
                        {formatValue(
                          showData.Tab1.monthlyHouseholdIncome.Householdexpenses
                        )}
                      </b>
                    </p>
                    <p className="mb-0">
                      Debt payments{" "}
                      <b className="text_danger">
                        -$
                        {formatValue(
                          showData.Tab1.monthlyHouseholdIncome.debtPayments
                        )}
                      </b>
                    </p>
                    <p className="mb-0">
                      <td
                        dangerouslySetInnerHTML={{
                          __html:
                            showData.Tab1.monthlyHouseholdIncome.leftoverText,
                        }}
                      />
                    </p>
                  </div>
                  <div className="card-body have-border">
                    <h6>How bad is it?</h6>
                    <p>
                      Before we talk about solutions, let's assign a letter
                      grade to your financial situation. Someone who is able to
                      meet all of their monthly obligations, have some leftover
                      for savings, and has a debt-to-income ratio of under 36%
                      would get an A grade.
                    </p>
                  </div>
                  <div className="card-body ">
                    <h6>Grade Scale</h6>
                    <div className="row">
                      <div className="col-lg-9">
                        <p className="mb-0">
                          {" "}
                          <b className="text_success">A</b>- to{" "}
                          <b className="text_success">B</b> Easy budget
                          adjustments will solve issues within 6 months.
                        </p>
                        <p className="mb-0">
                          {" "}
                          <b className="text_success"> B </b>- to{" "}
                          <b className="text_danger">C</b> Can be solved with
                          moderate sustained effort within 12 months.
                        </p>
                        <p className="mb-0">
                          <b className="text_danger">C</b>- to{" "}
                          <b className="text_danger">D</b>- Long term changes
                          and hard work for several years will solve your
                          issues.
                        </p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd ">
                          <span>
                            {showData.Tab1.monthlyHouseholdIncome.Grade}
                          </span>
                          Your Current Grade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card main-card">
                  <div className="card-body have-border">
                    <h6 className="mb-4">How'd this happen?</h6>
                    <h6 className="mb-2">Illness or Injury</h6>
                    <p>
                      You shared you have recently experienced an illness in
                      your life. Until someone has personally undergone a
                      debilitating illness it can be hard to understand just how
                      difficult it is. While an illness is usually a trial of
                      the body, it has the ability to affect all aspects of a
                      person's life. Professionally a person might struggle to
                      perform well at work, or emotionally a person might
                      experience depression and anxiety, both of which can
                      impact your financial well being.
                    </p>
                  </div>
                  <div className="card-body ">
                    <h6>Unemployment, underemployment</h6>
                    <p>
                      You have recently struggled with unemployment or
                      underemployment. These factors have obvious financial
                      consequences and are unfortunately all too common today. A
                      high percentage ofAmerican people are affected by our
                      country's current recession. Please know, you are not
                      alone.
                    </p>
                  </div>
                </div>
                <div className="card main-card">
                  <div className="card-body have-border">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h6>Assess Your Assets</h6>
                        <p>
                          The value of your assets is always changing. Some
                          things appreciate or go up in value and some things
                          depreciate - even cash goes down in value due to
                          inflation. It's a good idea to think about the value
                          of your assets periodically to see plan how they will
                          help you down the road. Remember that an asset is
                          something that can provide value now and in the
                          future.
                        </p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd ">
                          <span>${showData.Tab1.Assets.Assets}</span>
                          Your total assets
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body have-border">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h6>Limit Your Liabilities</h6>
                        <p>
                          The value of your assets is always changing. Some
                          things appreciate or go up in value and some things
                          depreciate - even cash goes down in value due to
                          inflation. It's a good idea to think about the value
                          of your assets periodically to see plan how they will
                          help you down the road. Remember that an asset is
                          something that can provide value now and in the
                          future.
                        </p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd ">
                          <span>${showData.Tab1.Assets.Liabilities}</span>
                          Your total liabilities
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body ">
                    <div className="row align-items-center">
                      <div className="col-lg-9">
                        <h6>Nurture Your Net Worth</h6>
                        <p>
                          Your net worth does not define your value as a person,
                          but a little planning now can give you more freedom in
                          the future. Nothing will increase your net worth more
                          than paying down your liabilities and then putting
                          additional funds in intrest earning investments. By
                          doing this, compound intrest will go from being your
                          worst enemy to your best friend.
                        </p>
                      </div>
                      <div className="col-lg-3 text-lg-end">
                        <button className="grade-btn btn_brnd ">
                          <span>${showData.Tab1.Assets.Networth}</span>
                          Your total net worth
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card main-card">
                  <div className="card-body ">
                    <h6>
                      Look at your expenses below. Does everything look right?
                      If not, you can go back at anytime and make adjustments.
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="sub-info-box">
                          <h6>Housing</h6>
                          <p>
                            {" "}
                            Rent Or Mortgage $
                            {formatValue(showData.Tab1.Housing.Rent)}
                          </p>
                          <p>
                            {" "}
                            Mainenance $
                            {formatValue(showData.Tab1.Housing.Mainenance)}
                          </p>
                          <p>
                            {" "}
                            Mortgage Insurance $
                            {formatValue(
                              showData.Tab1.Housing.Mortgage_Insurance
                            )}
                          </p>
                          <p>
                            {" "}
                            Property Insurance $
                            {formatValue(
                              showData.Tab1.Housing.Property_Insurance
                            )}
                          </p>
                          <p>
                            {" "}
                            Taxes ${formatValue(showData.Tab1.Housing.Taxes)}
                          </p>
                          <p>
                            Subtotal $
                            {formatValue(showData.Tab1.Housing.Subtotal)}
                          </p>
                          <p>Recommended spending 25-35%</p>
                          <p>Your spending {showData.Tab1.Housing.Spending}%</p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Utilities</h6>
                          <p>
                            {" "}
                            Internet $
                            {formatValue(showData.Tab1.Utilities.Internet)}
                          </p>
                          <p>
                            Electricity $
                            {formatValue(showData.Tab1.Utilities.Electricity)}
                          </p>
                          <p>
                            Garbage $
                            {formatValue(showData.Tab1.Utilities.Garbage)}
                          </p>
                          <p>Gas ${formatValue(showData.Tab1.Utilities.Gas)}</p>
                          <p>
                            Home Phone $
                            {formatValue(showData.Tab1.Utilities.Home_Phone)}
                          </p>

                          <p>
                            Television $
                            {formatValue(showData.Tab1.Utilities.Television)}
                          </p>
                          <p>
                            Water ${formatValue(showData.Tab1.Utilities.Water)}
                          </p>
                          <p>
                            Other ${formatValue(showData.Tab1.Utilities.Other)}
                          </p>
                          <p>
                            Subtotal $
                            {formatValue(showData.Tab1.Utilities.Subtotal)}
                          </p>
                          <p>Recommended spending 5-10%</p>
                          <p>
                            Your spending {showData.Tab1.Utilities.Spending}%
                          </p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Food</h6>
                          <p>
                            Groceries $
                            {formatValue(showData.Tab1.Food.Groceries)}
                          </p>
                          <p>
                            Fast Food $
                            {formatValue(showData.Tab1.Food.Fast_Food)}
                          </p>
                          <p>
                            Pet Food ${formatValue(showData.Tab1.Food.Pet_Food)}
                          </p>
                          <p>
                            Alcohol ${formatValue(showData.Tab1.Food.Alcohol)}
                          </p>
                          <p>
                            Coffee ${formatValue(showData.Tab1.Food.Coffee)}
                          </p>
                          <p>
                            Restaurant $
                            {formatValue(showData.Tab1.Food.Restaurant)}
                          </p>
                          <p>Other ${formatValue(showData.Tab1.Food.Other)}</p>
                          <p>
                            Subtotal ${formatValue(showData.Tab1.Food.Subtotal)}
                          </p>
                          <p>Recommended spending 5-15%</p>
                          <p>Your spending {showData.Tab1.Food.Spending}%</p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Transportation</h6>
                          <p>
                            Car Insurance $
                            {formatValue(
                              showData.Tab1.Transportation.Insurance
                            )}
                          </p>
                          <p>
                            Gas And Oil $
                            {formatValue(showData.Tab1.Transportation.Gas)}
                          </p>
                          <p>
                            Parking $
                            {formatValue(showData.Tab1.Transportation.Parking)}
                          </p>
                          <p>
                            Transportation $
                            {formatValue(
                              showData.Tab1.Transportation.Transportation
                            )}
                          </p>
                          <p>
                            Other $
                            {formatValue(showData.Tab1.Transportation.Other)}
                          </p>

                          <p>
                            Subtotal $
                            {formatValue(showData.Tab1.Transportation.Subtotal)}
                          </p>
                          <p>Recommended spending 10-15%</p>
                          <p>
                            Your spending{" "}
                            {showData.Tab1.Transportation.Spending}%
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="sub-info-box">
                          <h6>Miscellaneous</h6>
                          <p>
                            Cell Phone $
                            {formatValue(
                              showData.Tab1.Miscellaneous.Cell_Phone
                            )}
                          </p>
                          <p>
                            Hair/barber Shop $
                            {formatValue(showData.Tab1.Miscellaneous.Hair)}
                          </p>
                          <p>
                            Laundry $
                            {formatValue(showData.Tab1.Miscellaneous.Laundry)}
                          </p>
                          <p>
                            Clothing $
                            {formatValue(showData.Tab1.Miscellaneous.Clothing)}
                          </p>
                          <p>
                            Alimony $
                            {formatValue(showData.Tab1.Miscellaneous.Alimony)}
                          </p>
                          <p>
                            Child Support $
                            {formatValue(
                              showData.Tab1.Miscellaneous.ChildSupport
                            )}
                          </p>
                          <p>
                            Electronics $
                            {formatValue(
                              showData.Tab1.Miscellaneous.Electronics
                            )}
                          </p>
                          <p>
                            Hobbies $
                            {formatValue(showData.Tab1.Miscellaneous.Hobbies)}
                          </p>
                          <p>
                            Life Insurance $
                            {formatValue(
                              showData.Tab1.Miscellaneous.Life_Insurance
                            )}
                          </p>
                          <p>
                            Magazines $
                            {formatValue(showData.Tab1.Miscellaneous.Magazines)}
                          </p>
                          <p>
                            Personal Grooming $
                            {formatValue(
                              showData.Tab1.Miscellaneous.Personal_Grooming
                            )}
                          </p>
                          <p>
                            Other Misc $
                            {formatValue(
                              showData.Tab1.Miscellaneous.Other_Misc
                            )}
                          </p>
                          <p>
                            Subtotal $
                            {formatValue(showData.Tab1.Miscellaneous.Subtotal)}
                          </p>
                          <p>Recommended spending 10-15%</p>
                          <p>
                            Your spending {showData.Tab1.Miscellaneous.Spending}
                            %
                          </p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Recreation</h6>
                          <p>
                            Media ${formatValue(showData.Tab1.Recreation.Media)}
                          </p>
                          <p>
                            Movies $
                            {formatValue(showData.Tab1.Recreation.Movies)}
                          </p>
                          <p>
                            Music ${formatValue(showData.Tab1.Recreation.Music)}
                          </p>
                          <p>
                            Newspaper $
                            {formatValue(showData.Tab1.Recreation.Newspaper)}
                          </p>
                          <p>
                            Parties $
                            {formatValue(showData.Tab1.Recreation.Parties)}
                          </p>
                          <p>
                            Other ${formatValue(showData.Tab1.Recreation.Other)}
                          </p>
                          <p>
                            Subtotal $
                            {formatValue(showData.Tab1.Recreation.Subtotal)}
                          </p>
                          <p>Recommended spending 5-10%</p>
                          <p>
                            Your spending {showData.Tab1.Recreation.Spending}%
                          </p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Gifts</h6>
                          <p>Gifts ${formatValue(showData.Tab1.Gift.Gifts)}</p>
                          <p>
                            Donations $
                            {formatValue(showData.Tab1.Gift.Donations)}
                          </p>
                          <p>
                            Tithing ${formatValue(showData.Tab1.Gift.Tithing)}
                          </p>
                          <p>
                            Subtotal ${formatValue(showData.Tab1.Gift.Subtotal)}
                          </p>
                          <p>Recommended spending 10-15%</p>
                          <p>Your spending {showData.Tab1.Gift.Spending}%</p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Health</h6>
                          <p>
                            Insurance $
                            {formatValue(showData.Tab1.Health.Insurance)}
                          </p>
                          <p>
                            Doctor ${formatValue(showData.Tab1.Health.Doctor)}
                          </p>
                          <p>
                            Eye Care/Glasses/Contacts $
                            {formatValue(showData.Tab1.Health.EyeCare)}
                          </p>
                          <p>
                            Medicine/Perscriptions $
                            {formatValue(showData.Tab1.Health.Medicine)}
                          </p>
                          <p>
                            Dental ${formatValue(showData.Tab1.Health.Dental)}
                          </p>
                          <p>Gym ${formatValue(showData.Tab1.Health.Gym)}</p>
                          <p>
                            Other ${formatValue(showData.Tab1.Health.Other)}
                          </p>

                          <p>Recommended spending 10-15%</p>
                          <p>
                            Subtotal $
                            {formatValue(showData.Tab1.Health.Subtotal)}
                          </p>
                          <p>Your spending {showData.Tab1.Health.Spending}%</p>
                        </div>
                        <div className="sub-info-box">
                          <h6>Fixed Payments</h6>
                          {showData.Tab1.Fixed_Payments.fixPayment.map(
                            (payment, index) => (
                              <p key={index}>
                                {payment[0]} ${payment[1]}
                              </p>
                            )
                          )}

                          <p>
                            Subtotal ${showData.Tab1.Fixed_Payments.fixTotal}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center my-4">
                  <Link
                    to={`/editstudent?id=${userId}`}
                    className="text_success mt-4 me-3"
                  >
                    <u>Previous</u>
                  </Link>
                  <Link
                    to={`/income-analysis?id=${userId}`}
                    className="btn_brnd-outline mt-4 me-3"
                  >
                    Next
                  </Link>
                  <Link
                    to={`/addinfomultistepuser?id=${userId}`}
                    className="btn_brnd mt-4 me-3"
                  >
                    Add More Info to My Budget
                  </Link>
                </div>
              </section>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BudgetAnalysis;

import React, { useState, useEffect } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import PaginatedItems from "./pagination";
import {
  addCoupons,
  deleteCoupons,
  getCoupons,
  getAttorneyData,
  deleteAtterneyCode,
  updateDiscount,
} from "../../api/server";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import DeleteModal from "../../common/deleteModal";
import EditModal from "../../common/editModal";

const validationSchema = Yup.object({
  couponCode: Yup.string().required("Coupon Code is required"),
  amountOff: Yup.number()
    .required("Amount Off is required")
    .positive("Amount Off must be greater than 0"),
  invoiceAmount: Yup.number()
    .required("Invoice Amount is required")
    .moreThan(-1, "Invoice Amount can't be negative"),
  telephoneAmount: Yup.number()
    .required("Telephone Amount is required")
    .moreThan(-1, "Telephone Amount can't be negative"),
});

const ManageCouponCodes = () => {
  const [deleteCoupon, setDeleteCoupon] = useState("");
  const [editModal, setEditModal] = useState("");
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pageCache, setPageCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const [discountValue, setDiscountValue] = useState();
  const { handleChange, touched, values, errors, handleSubmit, handleBlur } =
    useFormik({
      initialValues: {
        couponCode: "",
        amountOff: "",
        invoiceAmount: "",
        telephoneAmount: "",
        billed: "yes",
      },
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        const body = { ...values, billed: values.billed ? "yes" : "no" };
        setPostLoading(true);
        addCoupons(body)
          .then((res) => {
            let updatedResponse = response;
            if (response.length > 9) {
              updatedResponse = response.slice(0, response.length - 1);
            }
            const newData = [res.data.data, ...updatedResponse];
            const newTotal = total + 1;
            setResponse(newData);
            setTotal(newTotal);
            setPageCache({
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            });
            toast.success("Attorney added successfully");
            setPostLoading(false);
            resetForm();
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setPostLoading(false);
          });
      },
    });

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);
        try {
          const res = await getAttorneyData(pageNo);
          const newData = res.data.data.result;
          const newTotal = res.data.data.total;

          // Update the cache with new data
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          // Set state with new data
          setResponse(newData);
          setTotal(newTotal);
        } catch (err) {
          toast.error(err.response.data.error);
        } finally {
          setGetLoading(false);
        }
      }
    };

    fetchData();
  }, [pageNo, pageCache, response]);

  const handleDeleteCoupon = async (id) => {
    setDeleteLoading(true);
    // await deleteAtterneyCode(id, response.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo)
    await deleteAtterneyCode(
      id,
      response.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
    )
      .then((res) => {
        const newData = res.data.data.result;
        const newTotal = res.data.data.total;
        setResponse(newData);
        setTotal(newTotal);
        setPageNo(response.length > 1 ? pageNo : pageNo - 1);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        setDeleteCoupon("");
        toast.success("Attorney deleted successfully");
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteCoupon("");
        toast.error(err.response.data.error);
        setDeleteLoading(false);
      });
  };
  const handleEdit = async (id) => {
    setDeleteLoading(true);
    await updateDiscount(id, discountValue)
      .then((res) => {

        response.map((value) => {
          if (value.id === id) {
            setResponse(...response, (value.discount = discountValue));
            setEditModal("");
            toast.success("Attorney Discount updated successfully");
            // window.location.reload();
            setDeleteLoading(false);
          }
        });
      })
      .catch((err) => {
        // setDeleteCoupon("");
        toast.error(err.response.data.error);
        setDeleteLoading(false);
      });
  };

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <DeleteModal
            loading={deleteLoading}
            showModal={deleteCoupon}
            setDelete={setDeleteCoupon}
            handleSubmit={() => {
              handleDeleteCoupon(deleteCoupon);
            }}
          />
          <EditModal
            loading={deleteLoading}
            showModal={editModal}
            setEdit={setEditModal}
            handleSubmit={() => {
              discountValue && handleEdit(editModal);
            }}
            setDiscountValue={setDiscountValue}
            discountValue={discountValue}
          />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">Manage Attorney Codes</h4>
            {/* <div className="head mt-60">
              <h1 className="f18">Add More</h1>
            </div>
            <form onSubmit={handleSubmit} className="row mt-60">
              <div className="col-lg-4">
                <div className=" input_wrap ">
                  <label className="label-title">Coupon Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter coupon code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="couponCode"
                    value={values.couponCode}
                  />
                  {errors.couponCode && touched.couponCode ? (
                    <p className="text-danger">{errors.couponCode}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" input_wrap ">
                  <label className="label-title">Amount Off</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter amount off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="amountOff"
                    value={values.amountOff}
                  />
                  {errors.amountOff && touched.amountOff ? (
                    <p className="text-danger">{errors.amountOff}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" input_wrap ">
                  <label className="label-title">Invoice Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter invoice Amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="invoiceAmount"
                    value={values.invoiceAmount}
                  />
                  {errors.invoiceAmount && touched.invoiceAmount ? (
                    <p className="text-danger">{errors.invoiceAmount}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" input_wrap ">
                  <label className="label-title">Telephone Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter telephone amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="telephoneAmount"
                    value={values.telephoneAmount}
                  />
                  {errors.telephoneAmount && touched.telephoneAmount ? (
                    <p className="text-danger">{errors.telephoneAmount}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="box_tick d-flex mt-3">
                  <div className="iagree_radio   mr-32">
                    <input
                      type="checkbox"
                      name="billed"
                      id="check1"
                      className=" "
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.billed}
                    />
                    <label for="check1">Billed </label>
                    {errors.billed && touched.billed ? (
                      <p className="text-danger">{errors.billed}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="text-center">
                  <button
                    className="btn_brnd my-3"
                    type="submit"
                    disabled={postLoading}
                  >
                    {postLoading ? (
                      <ThreeDots
                        height="20"
                        width="60"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </form> */}

            <div className="card">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    {/* <tr>
                      <th scope="col">Coupon Code</th>
                      <th scope="col">Amount Off</th>
                      <th scope="col">Invoice Amount</th>
                      <th scope="col">Telephone Amount</th>
                      <th scope="col">Billed</th>
                      <th scope="col"></th>
                    </tr> */}
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Attorney Code</th>
                      {/* <th scope="col">Telephone Amount</th> */}
                      <th scope="col">Discount</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={5}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      // response.map((coupon, index) => (
                      //   <tr key={index}>
                      //     <th scope="row">{coupon.couponcode}</th>
                      //     <td>${coupon.amount}</td>
                      //     <td>${coupon.invoiceamount || 0}</td>
                      //     <td>{coupon.telephoneamount || 0}</td>
                      //     <td>
                      //       <div className="iagree_radio">
                      //         <input
                      //           type="checkbox"
                      //           name="tickbox"
                      //           checked={coupon.billed === "yes" ? true : false}
                      //           id="check3"
                      //           className=" "
                      //         />
                      //         <label for="check3" className="text-white">
                      //           B
                      //         </label>
                      //       </div>
                      //     </td>
                      //     <td>
                      //       <div className="action-btns">
                      //         <button
                      //           className="btn-delete"
                      //           onClick={() => {
                      //             setDeleteCoupon(coupon.id);
                      //           }}
                      //         >
                      //           <i className="fa-solid fa-trash"></i>
                      //         </button>
                      //       </div>
                      //     </td>
                      //   </tr>
                      response.map((data, index) => (
                        <tr key={index}>
                          <th style={{ textTransform: "none" }} scope="row">
                            {data?.atty_name}
                          </th>
                          <td style={{ textTransform: "none" }}>
                            {data.atty_email}
                          </td>
                          <td style={{ textTransform: "none" }}>{data.code}</td>
                          <td>${data.discount || 0}</td>
                          <td style={{ textTransform: "none" }}>
                            {/* <div className="iagree_radio">
                              <input
                                type="checkbox"
                                name="tickbox"
                                checked={false}
                                id="check3"
                                className=" "
                              />
                              <label for="check3" className="text-white">
                                B
                              </label>
                            </div> */}
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                {
                                  setEditModal(data.id);
                                  setDiscountValue(data.discount);
                                }
                              }}
                            >
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            {/* <button
                            onClick={() => {
                              setEditModal(data.id);
                            }}
                            >
                              Edit
                            </button> */}
                          </td>
                          {/* <td>
                            <div className="action-btns">
                              <button
                                className="btn-delete"
                                onClick={() => {
                                  setDeleteCoupon(data.id);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!getloading && total !== 0 && total > 50 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageCouponCodes;

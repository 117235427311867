import React, { useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";

const TrainingAndHowTo = () => {
  const password = useSelector((state) => state.user.user.data.code);

  const [videos, setVideos] = useState({
    first: false,
    second: false,
    third: false,
  });
  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <section className="client-editing">
            <div className="text-box text_box_sec mt-60">
              <p>
                The password to view these videos is <b>{password}</b>. Feel
                free to click the pop-out icon to watch at full size.
              </p>
            </div>

            <Formik
              initialValues={{
                overviewPassword: "",
              }}
              validationSchema={Yup.object().shape({
                overviewPassword: Yup.string().required("Password is required"),
              })}
              onSubmit={(values, { setFieldError }) => {
                if (password === values.overviewPassword) {
                  setVideos({ ...videos, first: true });
                } else {
                  setFieldError(
                    "overviewPassword",
                    "Invalid password – contact the owner for the correct password."
                  );
                }
              }}
            >
              <Form>
                <div className="head mt-60">
                  <h1 className="f18">Overview</h1>
                </div>
                {!videos.first ? (
                  <div className="row mt-60">
                    <div className="col-lg-4 mx-auto">
                      <div className=" input_wrap ">
                        <label className="label-title">Enter Password</label>
                        <Field
                          type="password"
                          name="overviewPassword"
                          className="form-control"
                          placeholder="Please enter password"
                        />
                        <ErrorMessage
                          name="overviewPassword"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div className="text-center">
                        <button className="btn_brnd mb-3 mx-auto" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="vedio_wrap mt-60">
                    <iframe
                      width="100%"
                      height="450"
                      src="https://www.youtube.com/embed/9xwazD5SyVg"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                )}
              </Form>
            </Formik>

            <Formik
              initialValues={{
                paymentPassword: "",
              }}
              validationSchema={Yup.object().shape({
                paymentPassword: Yup.string().required("Password is required"),
              })}
              onSubmit={(values, { setFieldError }) => {
                if (password === values.paymentPassword) {
                  setVideos({ ...videos, second: true });
                } else {
                  setFieldError(
                    "paymentPassword",
                    "Invalid password – contact the owner for the correct password."
                  );
                }
              }}
            >
              <Form>
                <div className="head mt-60">
                  <h1 className="f18">Paying for a course over the phone</h1>
                </div>
                {!videos.second ? (
                  <div className="row mt-60">
                    <div className="col-lg-4 mx-auto">
                      <div className=" input_wrap ">
                        <label className="label-title">Enter Password</label>
                        <Field
                          type="password"
                          name="paymentPassword"
                          className="form-control"
                          placeholder="Please enter password"
                        />
                        <ErrorMessage
                          name="paymentPassword"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div className="text-center">
                        <button className="btn_brnd mb-3 mx-auto" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="vedio_wrap mt-60">
                    <iframe
                      width="100%"
                      height="450"
                      src="https://www.youtube.com/embed/9xwazD5SyVg"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                )}
              </Form>
            </Formik>

            <Formik
              initialValues={{
                authIssuePassword: "",
              }}
              validationSchema={Yup.object().shape({
                authIssuePassword: Yup.string().required(
                  "Password is required"
                ),
              })}
              onSubmit={(values, { setFieldError }) => {
                if (password === values.authIssuePassword) {
                  setVideos({ ...videos, third: true });
                } else {
                  setFieldError(
                    "authIssuePassword",
                    "Invalid password – contact the owner for the correct password."
                  );
                }
              }}
            >
              <Form>
                <div className="head mt-60">
                  <h1 className="f18">Username and Password Issues</h1>
                </div>
                {!videos.third ? (
                  <div className="row mt-60">
                    <div className="col-lg-4 mx-auto">
                      <div className=" input_wrap ">
                        <label className="label-title">Enter Password</label>
                        <Field
                          type="password"
                          name="authIssuePassword"
                          className="form-control"
                          placeholder="Please enter password"
                        />
                        <ErrorMessage
                          name="authIssuePassword"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div className="text-center">
                        <button className="btn_brnd mb-3 mx-auto" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="vedio_wrap mt-60">
                    <iframe
                      width="100%"
                      height="450"
                      src="https://www.youtube.com/embed/9xwazD5SyVg"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                )}
              </Form>
            </Formik>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TrainingAndHowTo;

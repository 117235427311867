import React from 'react';


const Attorneys2 = ({ page }) => {
	// const changePages=(pages)=>{
	// 	page(pages)
	// }
	return (
		<div>
			{/* <!-- Attorney Page 2 --> */}
			<section className=" ">
				<div className="theme_container">
					<div className="heading_Wrap w-100 w-877">
						<h1 className="f-52 text-center">More Services +<span> Better Support.</span></h1>
						<p className="f-18 fw-700 text-center mt-35">Simply brighter.</p>
						<p className="f-16  text-center">Clients report that their experience with Evergreen leaves them feeling more hopeful and optimistic about the future. By referring your clients to Evergreen, you ensure they will be cared for every step of the way.</p>
					</div>
					<div className="row mt-60">
						<div className="col-md-6">
							<h1 className="f-26">FOR CLIENTS</h1>
							<ul className="list_item" style={{textTransform:"none"}}>
								<li>100% non-profit organization</li>
								<li>Courses available 24/7</li>
								<li>100% accurate certificates instantly</li>
								<li>Flexible payment options</li>
								<li>Courses online, and booklet format</li>
								<li>Compatible on iPhone and iPad</li>
								<li>Caring, unbiased telephone counselors</li>
							</ul>
						</div>
						<div className="col-md-6">
							<h1 className="f-26">FOR ATTORNEYS</h1>
							<ul className="list_item">
								<li>Trust-based fee waiver code for clients in need</li>
								<li>Free renewal courses for expired certificates</li>
								<li>Full-featured attorney portal</li>
								<li>Track client progress, download certificates</li>
								<li>Comprehensive support for your clients</li>
								<li>Coupon codes and info cards supplied</li>
								<li>Program director available to you at any time</li>
							</ul>
						</div>
						{/* <div className="col-12">
							<div className="btn_wrap mt-60 text-center d-flex w-200">
								<button className="btn_brnd-outline w-148 mr-15" onClick={()=>changePages('page1')}>Previous</button>
								<button className="btn_brnd w-148" onClick={()=>changePages('page3')}>Next</button>
							</div>
						</div> */}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Attorneys2;
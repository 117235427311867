import React, { useState } from "react";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { sendIssueReport } from "../../api/server";
import * as Yup from "yup";
import AttorneyDashBoardSidebar from "../../layouts/attorneyDashBoardSidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const ReportAnIssue = () => {
  const attorney = useSelector((state) => state.user.user.data);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: attorney.firstname,
    email: attorney.email,
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    await sendIssueReport(values)
      .then((res) => {
        toast.success("Report submitted successfully");
        resetForm();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="wrapper">
      <AttorneyDashBoardSidebar />
      <div id="content">
        <DashboardNavbar />
        <div className="client-editing">
          <div className="">
            <div className="client-head">
              <h1 className="f18">Report an Issue</h1>
            </div>
          </div>

          <div className="row">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={ValidationSchema}
            >
              {({ errors, touched, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Name</label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Enter your name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Email</label>
                        <Field
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Enter your Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Subject</label>
                        <Field
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Subject"
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Message</label>
                        <Field
                          as="textarea"
                          name="message"
                          className="form-control"
                          placeholder="Enter your message"
                          style={{ width: "1095px" }}
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      disabled={loading}
                      className="btn_brnd"
                      type="submit"
                    >
                      {loading ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportAnIssue;

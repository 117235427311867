import React, { useState, useEffect } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import { getBillingSecond, updateBillingSecond } from "../../api/server";
import PaginatedItems from "./pagination";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const PendingBilling2ndcourse = () => {
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [toBeUpdatedIds, setToBeUpdatedIds] = useState([]);
  const [pageCache, setPageCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();

  const handleUpdateBilling = () => {
    setUpdateLoading(true);
    updateBillingSecond(
      { ids: toBeUpdatedIds },
      response.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
    )
      .then((res) => {
        const newData = res.data.pendingBilling.result;
        const newTotal = res.data.pendingBilling.total;
        console.log();
        setResponse(newData);
        setTotal(newTotal);
        setPageNo(response.length > 1 ? pageNo : pageNo - 1);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        toast.success("Billing updated successfully");
        setToBeUpdatedIds([]);
        setUpdateLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setUpdateLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);

        await getBillingSecond(pageNo)
          .then((res) => {
            const newData = res.data.pendingBilling.result;
            const newTotal = res.data.pendingBilling.total;

            // Update the cache with new data
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));

            // Set state with new data
            setResponse(newData);
            setTotal(newTotal);
            setGetLoading(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoading(false);
          });
      }
    };

    fetchData();
  }, [pageNo, pageCache]);

  const handleChange = (e, billing) => {
    e.target.checked
      ? setToBeUpdatedIds([billing.id, ...toBeUpdatedIds])
      : setToBeUpdatedIds(toBeUpdatedIds.filter((item) => item !== billing.id));
  };

  useEffect(() => {
    setToBeUpdatedIds([]);
  }, [pageNo]);
  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">2nd Course Coupons Pending Billing</h4>

            <div className="card  mt-60">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Student Name</th>
                      <th scope="col">Attorney Name</th>
                      <th scope="col">Coupon Code</th>
                      <th scope="col">Enrolled Date</th>
                      <th scope="col">Billed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={5}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      response.map((billing, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <Link
                              to={`/editstudent?id=${billing.id}`}
                              style={{
                                color: "#000",
                              }}
                            >
                              {billing.firstname} {billing.lastname}
                            </Link>
                          </th>
                          <td>{billing.attorneyname}</td>
                          <td>{billing.couponcode_second}</td>
                          <td>{billing.second_enrol_date.split("T")[0]}</td>
                          <td>
                            <div className="iagree_radio">
                              <input
                                type="checkbox"
                                name="tickbox"
                                id={`check${index}`}
                                className=" "
                                checked={
                                  toBeUpdatedIds.includes(billing.id)
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleChange(e, billing);
                                }}
                              />
                              <label
                                for={`check${index}`}
                                className="text-white"
                              >
                                B
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No pending Billing found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!getloading && response && response.length > 0 && (
                  <div className="text-center">
                    <button
                      className="btn_brnd mt-4 me-3"
                      disabled={updateLoading || toBeUpdatedIds.length === 0}
                      onClick={handleUpdateBilling}
                    >
                      {updateLoading ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                )}
                {!getloading && total !== 0 && total > 10 && (
                  <PaginatedItems
                    pageSize={10}
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PendingBilling2ndcourse;

import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";

const Faq = () => {
  return (
    <div>
      <Header />
      {/* <!-- FAQ --> */}
      <section className="FAQ mt-250">
        <div className="theme_container">
          <div className="heading_Wrap">
            <h1 className="f-52">
              Frequently Asked <span>Questions</span>
            </h1>
          </div>
          <div className="faq_Wrap mt-60">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is my username and password?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    If you have registered with Evergreen in the past, simply
                    enter the username and password you created the first time
                    you logged in. If you are a new Evergreen student, select
                    which class you would like to take from the home page, and
                    follow the prompts for registration.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What if I forget my password?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Click the “Forgot Password?” link below the login box on the
                    homepage. Enter your email and follow the prompts.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Will my spouse and I have different usernames and passwords
                    to log into the website?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes. You and your spouse can take the class together, but
                    you will have separate usernames and passwords.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingThreePointSeven"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThreePointSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseThreePointSeven"
                  >
                    In a joint bankruptcy case, does my spouse have to take the
                    credit-counseling and debtor-education courses separate from
                    me?
                  </button>
                </h2>
                <div
                  id="flush-collapseThreePointSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThreePointSeven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    No. The cost is the same for single or joint bankruptcy
                    cases, and you can register and take the class together.
                    That said, be sure you create two usernames and passwords
                    during registration.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingThreePointFive"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThreePointFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseThreePointFive"
                  >
                    How can I take my credit-counseling course?
                  </button>
                </h2>
                <div
                  id="flush-collapseThreePointFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThreePointFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    We offer the credit-counseling course online or over the
                    telephone. If you opt to take the course online, you will
                    need to follow up with a counselor via telephone or text.
                    <br />
                    <br />
                    At the end of the credit-counseling course, you will be
                    prompted to take the next appropriate steps (based on the
                    specifics of your case) to receive your credit-counseling
                    certificate.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id="flush-headingThreePointSix"
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThreePointSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseThreePointSix"
                  >
                    My spouse completed the credit-counseling course but is
                    unavailable for the counseling session call. Will Evergreen
                    issue a certificate for my spouse?
                  </button>
                </h2>
                <div
                  id="flush-collapseThreePointSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThreePointSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    No. Please call back when you and your spouse are available
                    for counseling (or call separately). We will have to speak
                    to both you and your spouse before completion certificates
                    will be issued.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Will my attorney receive a copy of my certificate?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    During registration, you'll have the option to enter your
                    attorney's email address. We will send your attorney a
                    certificate at the same time we send your certificate to
                    you. If you have received your certificate, we have emailed
                    it to your attorney as well.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    When will I get my certificate?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    After you have finished your course, a counselor will
                    generate your certificate of completion, upload it to your
                    account, and email it to you. You can also log into your
                    account to download your certificate at any time.
                    <br />
                    <br />
                    If you are in an emergency and need help finding your
                    certificate, feel free to call us at 1(800) 581-3513 and
                    we'll expedite your certificate.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse1"
                    aria-expanded="false"
                    aria-controls="flush-collapse1"
                  >
                    What if I want an additional copy of my certificate at a
                    later time?
                  </button>
                </h2>
                <div
                  id="flush-collapse1"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading1"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Log into your account with your username/password and
                    download a copy of your certificate. Or, call or email us
                    with your request.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2"
                    aria-expanded="false"
                    aria-controls="flush-collapse2"
                  >
                    What if I originally wanted the certificate mailed to me,
                    but now I changed my mind and want it emailed or faxed?
                  </button>
                </h2>
                <div
                  id="flush-collapse2"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading2"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Email your request to info@evergreenclass.com, and we will
                    email or fax your certificate to you/your attorney. You can
                    always log into your account to download a copy of your
                    certificate.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3"
                    aria-expanded="false"
                    aria-controls="flush-collapse3"
                  >
                    Why do I have to take a credit-counseling course (Course
                    #1)?
                  </button>
                </h2>
                <div
                  id="flush-collapse3"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading3"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    The Bankruptcy Act enacted by Congress requires individuals
                    filing Chapter 7 or 13 bankruptcy to complete a
                    credit-counseling course before filing bankruptcy and prior
                    to receiving their bankruptcy discharge. Our
                    credit-counseling class meets this requirement.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4"
                    aria-expanded="false"
                    aria-controls="flush-collapse4"
                  >
                    When do I take the credit counseling course?
                  </button>
                </h2>
                <div
                  id="flush-collapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading4"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    You need to take the credit-counseling course and receive a
                    certificate of completion to file for your bankruptcy. You
                    cannot file for bankruptcy without a certificate of
                    completion.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5"
                    aria-expanded="false"
                    aria-controls="flush-collapse5"
                  >
                    Why do I have to take a personal financial management or
                    debtor-education course (Course #2)?
                  </button>
                </h2>
                <div
                  id="flush-collapse5"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading5"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    The Bankruptcy Act enacted by Congress requires individuals
                    filing Chapter 7 or 13 bankruptcy to complete an
                    instructional course in personal financial management after
                    filing bankruptcy and prior to receiving their bankruptcy
                    discharge. Our financial management class meets this
                    requirement.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse6"
                    aria-expanded="false"
                    aria-controls="flush-collapse6"
                  >
                    When do I take the personal financial management or debtor
                    education course?
                  </button>
                </h2>
                <div
                  id="flush-collapse6"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading6"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    You should take this course after you file bankruptcy and
                    receive a case number. The law requires that you take this
                    course within 45 days after your 341 Meeting.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading7">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse7"
                    aria-expanded="false"
                    aria-controls="flush-collapse7"
                  >
                    How can I take my post-bankruptcy debtor-education (or
                    personal financial management) course?
                  </button>
                </h2>
                <div
                  id="flush-collapse7"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading7"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    We offer post-bankruptcy debtor-education (or personal
                    financial management) online or over the telephone.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading75">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse75"
                    aria-expanded="false"
                    aria-controls="flush-collapse75"
                  >
                    How can I take my personal financial management or
                    debtor-education course?
                  </button>
                </h2>
                <div
                  id="flush-collapse75"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading75"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    We offer the personal financial management or
                    debtor-education course online or over the telephone. If you
                    opt to take the course online, you will be prompted at the
                    end to call us if you need your certificate expedited.
                    Otherwise, you will get your certificate within 24 business
                    hours.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading76">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse76"
                    aria-expanded="false"
                    aria-controls="flush-collapse76"
                  >
                    Is Course #3, 7 Steps to a 720 Credit Score, required by
                    law?
                  </button>
                </h2>
                <div
                  id="flush-collapse76"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading76"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    No. This is a free credit-education course we offer to
                    anyone who has completed their credit-counseling and
                    debtor-education courses through Evergreen. We offer this
                    course because we want you to know how to rebuild your
                    credit score quickly so that you can start fresh and use
                    your bankruptcy as a springboard to a better future.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading8">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse8"
                    aria-expanded="false"
                    aria-controls="flush-collapse8"
                  >
                    Is Evergreen Financial Counseling approved to issue
                    certificates of completion?
                  </button>
                </h2>
                <div
                  id="flush-collapse8"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading8"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes. Evergreen Financial Counseling is approved to issue
                    certificates evidencing completion of credit counseling or
                    personal financial management instructional courses in
                    compliance with the bankruptcy code.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading9">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse9"
                    aria-expanded="false"
                    aria-controls="flush-collapse9"
                  >
                    What are the benefits of using Evergreen Financial
                    Counseling?
                  </button>
                </h2>
                <div
                  id="flush-collapse9"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading9"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Evergreen Financial Counseling offers a “no hassle” approach
                    to individuals wanting to meet their bankruptcy education
                    requirements to satisfy the new bankruptcy law. Beyond that,
                    we offer a free credit-improvement course to everyone who
                    completes their credit counseling and debtor education
                    courses through Evergreen. We are here to help you—help you
                    get it done, and help you move on.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading10">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse10"
                    aria-expanded="false"
                    aria-controls="flush-collapse10"
                  >
                    How much do your courses cost?
                  </button>
                </h2>
                <div
                  id="flush-collapse10"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading10"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    The credit-counseling and debtor-edcation courses are both
                    $19.99 per person/couple when taken over the Internet or via
                    telephone.
                    <br />
                    <br />
                    Course #3, 7 Steps to a 720 Credit Score, is free to anyone
                    who has completed both their credit-counseling and
                    debtor-education courses through Evergreen Financial
                    Counseling.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading11">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse11"
                    aria-expanded="false"
                    aria-controls="flush-collapse11"
                  >
                    How will I pay for my credit-counseling and debtor-education
                    courses?
                  </button>
                </h2>
                <div
                  id="flush-collapse11"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading11"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    You or your attorney may pay online by debit card, credit
                    card or PayPal.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading12">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse12"
                    aria-expanded="false"
                    aria-controls="flush-collapse12"
                  >
                    Can my attorney pay for my credit-counseling and
                    debtor-education courses?
                  </button>
                </h2>
                <div
                  id="flush-collapse12"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading12"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">Yes.</div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading13">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse13"
                    aria-expanded="false"
                    aria-controls="flush-collapse13"
                  >
                    What if I cannot afford to pay for my credit-counseling or
                    debtor-education course?
                  </button>
                </h2>
                <div
                  id="flush-collapse13"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading13"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul style={{ marginLeft: "30px" }}>
                      <li style={{ listStyleType: "disc" }}>
                        Individuals can demonstrate need for a fee waiver if
                        their current household income is less than 150% and
                        above 100% of the poverty guidelines, as updated
                        periodically in the Federal Register by the U.S.
                        Department of Health and Human Services under the
                        authority of 42 U.S.C. 9902(2), as adjusted from time to
                        time.
                      </li>
                      <br />
                      <li style={{ listStyleType: "disc" }}>
                        Notification: At the beginning of an individual's
                        course, Evergreen Financial Counseling will inform
                        individuals that they may qualify for a fee waiver in
                        part or in whole. In order to fully verify an
                        individual's financial situation, Evergreen Financial
                        Counseling will request a copy of the individual's most
                        recent pay stubs or W2s, as well as a copy of their most
                        recent tax return.
                      </li>
                      <br />
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading14">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse14"
                    aria-expanded="false"
                    aria-controls="flush-collapse14"
                  >
                    Where can I take the course?
                  </button>
                </h2>
                <div
                  id="flush-collapse14"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading14"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    You can take the credit-counseling and debtor-education
                    course from any location with an Internet connection or
                    telephone access, such as your home, your office, your
                    attorney's office, a public library, or an Internet cafe.
                    <br />
                    <br />
                    The third course, 7 Steps to a 720 Credit Score, is
                    available via the Internet, 24 hours a day.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading15">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse15"
                    aria-expanded="false"
                    aria-controls="flush-collapse15"
                  >
                    Once I've started the course, can I stop and start the
                    course again at a later time?
                  </button>
                </h2>
                <div
                  id="flush-collapse15"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading15"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Yes. You can start, stop, and save your course whenever you
                    like. Log in and out as many times as you need. You can go
                    at your own pace.
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading16">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse16"
                    aria-expanded="false"
                    aria-controls="flush-collapse16"
                  >
                    What information will I need to provide to register for a
                    course?
                  </button>
                </h2>
                <div
                  id="flush-collapse16"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading16"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul style={{ marginLeft: "30px" }}>
                      <li style={{ listStyleType: "disc" }}>Your full name</li>
                      <li style={{ listStyleType: "disc" }}>
                        The district you filed your bankruptcy case in
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Your bankruptcy case number (For the Personal Financial
                        Management Course)
                      </li>
                      <li style={{ listStyleType: "disc" }}>Your address and country</li>
                      <li style={{ listStyleType: "disc" }}>
                        Your phone number
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        Your attorney's name and email (if you are represented
                        by an attorney) Verification that you are actually the
                        person taking this course.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading17">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse17"
                    aria-expanded="false"
                    aria-controls="flush-collapse17"
                  >
                    When will I have access to the free third course, 7 Steps to
                    a 720 Credit Score?
                  </button>
                </h2>
                <div
                  id="flush-collapse17"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading17"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    For best results, you should start 7 Steps to a 720 Credit
                    Score as soon as your bankruptcy is discharged (Chapter 7)
                    or confirmed (Chapter 13). As such, we will enroll you 30
                    days after you have completed Course #2. This 30-day window
                    gives your attorney and the bankruptcy courts time to
                    process your bankruptcy.
                    <br />
                    <br />
                    You will receive an email with login instructions once your
                    enrollment begins.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Faq;

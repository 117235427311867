import React, { useEffect, useState } from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import Attorneys2 from "./attorneys2";
import Attorneys3 from "./attorneys3";
import Attorneys4 from "./attorneys4";
import { Link } from "react-router-dom";

const Attorneys = () => {
  const [pages, setPages] = useState({
    page1: true,
    page2: true,
    page3: true,
    page4: true,
  });

  // useEffect(() => {
  //   setPages({
  //     page1: true,
  //     page2: false,
  //     page3: false,
  //     page4: false,
  //   });
  // }, []);

  // const changePage = (page) => {
  //   if (page === "page1") {
  //     setPages({
  //       page1: true,
  //       page2: false,
  //       page3: false,
  //       page4: false,
  //     });
  //   }
  //   if (page === "page2") {
  //     setPages({
  //       page1: false,
  //       page2: true,
  //       page3: false,
  //       page4: false,
  //     });
  //   }
  //   if (page === "page3") {
  //     setPages({
  //       page1: false,
  //       page2: false,
  //       page3: true,
  //       page4: false,
  //     });
  //   }
  //   if (page === "page4") {
  //     setPages({
  //       page1: false,
  //       page2: false,
  //       page3: false,
  //       page4: true,
  //     });
  //   }
  // };

  // const handleReset = () => {
  //   setPages({
  //     page1: true,
  //     page2: false,
  //     page3: false,
  //     page4: false,
  //   });
  // };

  return (
    <div>
      {/* <!-- Attorney Page 1 --> */}
      <Header /> {/*handleAttorneyClick={handleReset} />*/}
      {pages.page1 && (
        <section className=" d-flex align-items-center" style={{ marginTop: '15rem', marginBottom: '7rem' }}>
          <div className="theme_container">
            <div className="heading_Wrap">
              <h1 className="f-52 text-center">
                {" "}
                Serving All <span>Your Clients.</span>
              </h1>
              <p className="f-18 fw-700 text-center mt-35" style={{ textTransform: "none" }}>
                Evergreen Has a Solution for All Your Clients.
              </p>
              <p className="f-16  text-center">
                We will guide them through the courses no matter how difficult
                their circumstances. We are a dedicated non-profit organization
                that offers a full-service program that can be easily shared
                with clients of all abilities and backgrounds.
              </p>
              <p className="f-16  text-center">
                Our Aim To assist your clients in solving their financial
                stresses as quickly as possible and plan for brighter days ahead
                through caring service, thoughtful principles and instant
                feedback.
              </p>
              {/* <div className="faq_btn mt-40 text-center ">
                <button
                  className="btn btn_brnd  w-148"
                  onClick={() => changePage("page2")}
                >
                  Next
                </button>
              </div> */}
            </div>
          </div>
        </section>
      )}
      {/* {pages.page2 && <Attorneys2 page={changePage} />}
      {pages.page3 && <Attorneys3 page={changePage} />}
      {pages.page4 && <Attorneys4 page={changePage} />} */}
      {pages.page2 && <Attorneys2 />}
      {pages.page3 && <Attorneys3 />}
      {pages.page4 && <Attorneys4 />}

      <div className="faq_btn text-center ">
        <Link
          className="btn btn_brnd "
          to={"/setup-attorney-account"}
        >
          Set-up Attorney Account
        </Link>
      </div>

      <Footer />
    </div>
  );
};

export default Attorneys;

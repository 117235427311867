import React from "react";
import facebook from "../assets/images/SiteImg/icon/facebook.svg";
import instagram from "../assets/images/SiteImg/icon/instagram.svg";
import twitter from "../assets/images/SiteImg/icon/twitter.svg";
import address from "../assets/images/SiteImg/icon/address.svg";
import phone from "../assets/images/SiteImg/icon/phon.svg";
import email from "../assets/images/SiteImg/icon/email.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      {/* <!-- footer_Wrap --> */}
      <section className="footer_top">
        <div className="theme_container">
          <div className="footer_top_inner">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <h1 className="f-52">
                  Talk <span>With Us!</span>
                </h1>
                <p
                  className="f-18 f-500 mb-0"
                  style={{ textTransform: "none" }}
                >
                  We're on your side for a better financial future.
                </p>
              </div>
              <div className="col-lg-3 text-center justify-content-end d-flex">
                <Link to={"/contactus"}>
                  {" "}
                  <button className="btn btn_brnd   ">Let's Talk</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer_Wrap">
        <div className="theme_container ">
          <div className="footer_inner">
            <div className="row">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <h1 className="f26 text-white link_after">Useful links</h1>
                  <ul className="footer-lit mt-20">
                    <li>
                      <Link
                        className="nav-link	 mr-80"
                        aria-current="page"
                        to={`/attorneys`}
                      >
                        Attorneys
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link className="nav-link mr-80" to={`/about`}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link className="nav-link mr-80 " to={`/faq`}>
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link mr-80" to={`/privacy-policy`}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link mr-80 "
                        to={`/terms-and-condition`}
                      >
                        Terms & Condition
                      </Link>
                    </li>
                  </ul>
                  <ul className="social_icon">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=100086498252029"
                        target="_blank"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/EvergreenFinCou"
                        target="_blank"
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 footer_text">
                  <h1 className="f26 text-white">Contact Us</h1>
                  <p className="f-16 text-white mt-20">
                    <img src={address} alt="address" />
                    Evergreen Financial Counseling 7137 E. Rancho Vista Drive,
                    Suite B21 Scottsdale, AZ 85251
                  </p>
                  <p className="text-white f-16">
                    <a href="tel:800-581-3513">
                      <img src={phone} alt="" />
                      800-581-3513*
                    </a>
                  </p>
                  <p
                    className="f-16 text-white"
                    style={{ textTransform: "lowercase" }}
                  >
                    <a
                      href="mailto:counselor@evergreenclass.com"
                      style={{ textTransform: "lowercase" }}
                    >
                      <img src={email} alt="" />
                      counselor@evergreenclass.com
                    </a>
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mx-auto">
                  <h1 className="f26 text-white">Evergreen's Mission</h1>
                  <p className="f-16 mt-20 text-white text-left">
                    Evergreen financial counseling seeks to empower and uplift
                    individuals through quality financial counseling and
                    education.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="divider-line mt-12"></div>
          <p className="f16 text_dark footer-bottom mb-0">
            Rights - ©2023{" "}
            <span className="text-white">Evergreen Financial Counseling</span>.
            All rights reserved.{" "}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

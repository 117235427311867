import React, { useEffect, useState } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { getUserData, uploadCertToCourt } from "../../api/server";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const CertificateToCourt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const court = queryParams.get("court");
  const [postLoading, setPostLoading] = useState();
  const [response, setResponse] = useState();
  const [courseData, setCourseData] = useState();
  const [getloading, setGetLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await getUserData(userId)
        .then((res) => {
          setCourseData(res.data.data.fetchStudent);
          setResponse(res.data.data.user);
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setGetLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          {getloading || !response ? (
            <p>
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
                wrapperClassName="spinner"
                visible={true}
              />
            </p>
          ) : (
            <section className="manage-students language_wrap">
              <h4 className="f26 ">
                UPLOAD CERT TO BANKRUPTCY COURT (EFINCERT)
              </h4>
              <section className="client-editing mt-40">
                <div className="client-head mb-0">
                  <h1>Step 1.</h1>
                </div>
                <div
                  style={{ marginTop: "20px", marginLeft: "20px" }}
                  className="court"
                >
                  <p>
                    {courseData.downloaded &&
                    courseData.downloaded !== "yes" ? (
                      <a
                        href={courseData.downloaded}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        Click here to save cert for{" "}
                        <b>
                          {response.firstname +
                            " " +
                            response.middleinitial +
                            " " +
                            response.lastname}
                        </b>
                      </a>
                    ) : (
                      <></>
                    )}
                  </p>
                  <p>
                    {courseData.jointCourseSecondDownloaded &&
                    courseData.jointCourseSecondDownloaded !== "yes" ? (
                      <a
                        href={courseData.jointCourseSecondDownloaded}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        Click here to save cert for{" "}
                        <b>
                          {response.joint_firstname +
                            " " +
                            response.joint_middleinitial +
                            " " +
                            response.joint_lastname}{" "}
                          {"(Joint)"}
                        </b>
                      </a>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
                <div className="client-head  mb-0 mt-60">
                  <h1>Step 2. Go to this link.</h1>
                </div>
                <div
                  style={{ marginTop: "20px", marginLeft: "20px" }}
                  className="court"
                >
                  <a href={court} target="_blank">
                    {court}
                  </a>
                  <p style={{ marginTop: "10px" }}>
                    user: <b>ef1215</b> pass: <b>pWE55N9J</b> (if needed)
                  </p>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_wrap mt-4">
                      <label className="label-title">Court Link</label>
                      <input
                        type="text"
                        className="form-control"
                        value={court}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="client-head  mb-0 mt-60">
                  <h1>Step 3. Enter the case number and click the check box</h1>
                </div>
                <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_wrap mt-4">
                      <label className="label-title">Case Number </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={response.bankruptcycasenum}
                      />
                    </div>
                  </div>
                </div>
                <div className="client-head  mb-0 mt-60">
                  <h1>Step 4. Fill out form and upload debtor certs.</h1>
                </div>
                <div style={{ marginTop: "20px", marginLeft: "20px" }}>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_wrap mt-4">
                      <label className="label-title">Organization Name </label>
                      <input
                        type="text"
                        className="form-control"
                        value="Evergreen Financial Counseling"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_wrap mt-4">
                      <label className="label-title">
                        Contact Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={"800-581-3513"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_wrap mt-4">
                      <label className="label-title">
                        Email Address to Notify{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={response.attyemail}
                      />
                    </div>
                  </div>
                </div>
                <div className="client-head  mb-0 mt-60">
                  <h1>Step 5. Click here when completed</h1>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn_brnd my-3"
                    type="submit"
                    onClick={() => {
                      setPostLoading(true);
                      uploadCertToCourt(userId, {
                        courseId: courseData.courseSecondId,
                        jointCourseId: courseData.jointCourseSecondId,
                        link: courseData.downloaded,
                        jointLink: courseData.jointCourseSecondDownloaded,
                      })
                        .then((res) => {
                          setPostLoading(false);
                          navigate("/certificates");
                        })
                        .catch((err) => {
                          setPostLoading(false);
                          toast.error(err.response.data.error);
                        });
                    }}
                  >
                    {postLoading ? (
                      <ThreeDots
                        height="20"
                        width="280"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "THESE CERTS HAVE BEEN UPLOADED"
                    )}
                  </button>
                </div>
              </section>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificateToCourt;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { useSelector } from "react-redux";

const BudgetAnalysisExpenses = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const report = useSelector((state) => state.user.report);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <h1 className="card-title mb-4">Budget Analysis</h1>
          <section className="breadcrumb-section">
            <nav
              style={{ "--bs-breadcrumb-divider": " '>';" }}
              aria-label="breadcrumb"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Clients Budget Analysis</a>
                </li>
                <li className="breadcrumb-item">Income</li>
                <li className="breadcrumb-item " aria-current="page">
                  Expenses
                </li>
              </ol>
            </nav>
          </section>
          <section className="budget-analysis">
            {report.Tab3.expenseSecFirst.expenseSecFirstParaFirstText && (
              <div className="card main-card">
                <div className="card-body ">
                  <div className="row align-items-center">
                    <div className="col-lg-9">
                      <h6
                        dangerouslySetInnerHTML={{
                          __html:
                            report.Tab3.expenseSecFirst
                              .expenseSecFirstParaFirstText,
                        }}
                      ></h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            report.Tab3.expenseSecFirst
                              .expenseSecFirstParaSecondText,
                        }}
                      ></p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            report.Tab3.expenseSecFirst
                              .expenseSecFirstParaThirdText,
                        }}
                      ></p>
                    </div>
                    <div className="col-lg-3 text-lg-end">
                      <button className="grade-btn btn_brnd mb-2">
                        <span>${report.Tab3.expenseSecFirst.leftover}</span>
                        The amount you are overspending each month.
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {report.Tab3.expenseSecSecond.debtToIncomeParaFirst && (
              <div className="card main-card">
                <div className="card-body ">
                  <div className="row align-items-center">
                    <div className="col-lg-9">
                      <h6
                        className="text_danger"
                        dangerouslySetInnerHTML={{
                          __html:
                            report.Tab3.expenseSecSecond.debtToIncomeParaFirst,
                        }}
                      ></h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            report.Tab3.expenseSecSecond.debtToIncomeParaSecond,
                        }}
                      ></p>
                    </div>
                    <div className="col-lg-3 text-lg-end">
                      <button className="grade-btn btn_brnd mb-2">
                        <span>
                          {report.Tab3.expenseSecSecond.debtToIncome.toFixed(2)}
                          %
                        </span>
                        Percent of your income going to housing and debt.
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {report.Tab3.housingSecThird.housingSecParaFirst && (
              <div className="card main-card">
                <div className="card-body p-0">
                  <div className="row ">
                    <div className="col-lg-6 bordr-right">
                      <div className="p-lg-4 p-2">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: report.Tab3.housingSecThird.housingLeftSide,
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="p-lg-4 p-2">
                        <h6
                          className="text_success"
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.housingSecThird.housingSecParaFirst,
                          }}
                        ></h6>
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.housingSecThird.housingSecParaSecond,
                          }}
                        ></p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.housingSecThird.housingSecParaThird,
                          }}
                        ></p>
                        <h6
                          className="text_success"
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.housingSecThird.housingSecParaFourth,
                          }}
                        ></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {report.Tab3.paymentSecFourth.paymentSecParaFirst && (
              <div className="card main-card">
                <div className="card-body p-0">
                  <div className="row ">
                    <div className="col-lg-6 bordr-right">
                      <div className="p-lg-4 p-2">
                        <h6
                          className="text_danger"
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.paymentSecFourth
                                .paymentSecLeftParaFirst,
                          }}
                        ></h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.paymentSecFourth
                                .paymentSecLeftParaSecond,
                          }}
                        ></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="p-lg-4 p-2">
                        <h6
                          className="text_success"
                          dangerouslySetInnerHTML={{
                            __html: report.Tab3.expenseSecFirst.leftover,
                          }}
                        ></h6>
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html:
                              report.Tab3.paymentSecFourth.paymentSecParaSecond,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {report.Tab3.expenseSecFirst.leftover && (
              <div className="card main-card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-lg-12 text-center pt-4">
                      <p>
                        Net income leftover after expenses are paid{" "}
                        <b className="text_success">
                          {report.Tab3.leftoverTab3}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="text-center my-4">
              <Link
                to={`/income-analysis?id=${userId}`}
                className="text_success mt-4 me-3"
              >
                <u>Previous</u>
              </Link>
              <Link
                to={`/debts-analysis?id=${userId}`}
                className="btn_brnd-outline mt-4 me-3"
              >
                Next
              </Link>
              <Link
                to={`/addinfomultistepuser?id=${userId}`}
                className="btn_brnd mt-4 me-3"
              >
                Add More Info to My Budget
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default BudgetAnalysisExpenses;

import React from 'react';

const Attorneys3 = ({ page }) => {
    // const changePages=(pages)=>{
    // 	page(pages)
    // }
    return (
        <div>
            {/* <!-- Attorney Page 3 --> */}
            <section className="  mt-250">
                <div className="theme_container">
                    <div className="heading_Wrap w-100  ">

                        <h1 className="f-52 text-center">Easier...beginning<span> today.</span></h1>
                        <p className="f-16  text-center mt-35">Law firms that select Evergreen as their preferred provider for counseling courses can save 45 minutes per filing for their clients and support staff. A single, trusted source for counseling courses can virtually eliminate common headaches.</p>
                    </div>
                    <div className="row mt-60">
                        <div className="col-md-6">
                            <h1 className="f-26">Quick Links</h1>
                            <p className="f-16 mt-20">Access to basic services such as requesting cards and locating certificates can be accessed any time with the tabs located in appbar as "Attorneys".</p>

                            <h1 className="f-26 mt-40">Account Setup</h1>
                            <p className="f-16 mt-20">Extended access and additional services are available when you set-up an account for your firm. Log in at any time to track your clients and take full advantage of all the services we offer.</p>
                        </div>
                        <div className="col-md-6">
                            <h1 className="f-26">Phone</h1>
                            <p className="f-16 mt-20">If you have questions about pricing, services, payment options, recurring billing, or anything else, please call 503-506-8813 to speak to our program director.</p>

                            <h1 className="f-26 mt-40">Email</h1>
                            <p className="f-16 mt-20">Questions can also be emailed to counselor@evergreenclass.com</p>

                        </div>
                        {/* <div className="col-12">
                            <div className="btn_wrap mt-60 text-center d-flex w-200">
                                <button className="btn_brnd-outline w-148 mr-15" onClick={()=>changePages('page2')}>Previous</button>
                                <button className="btn_brnd w-148"onClick={()=>changePages('page4')}>Next</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section >
        </div >
    );
};

export default Attorneys3;
import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { getUserData, enroll2ndCourse } from "../../api/server";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import cross from "../../assets/images/SiteImg/cross.png";
import { login } from "../../store/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import StripeComponent from "../../common/stripeComponent";

const EnrollmentValidationSchema = Yup.object().shape({
  bankruptcytype: Yup.string().required("Bankruptcy Type is required"),
  bankruptcycasenum: Yup.string().required(
    "Bankruptcy Case Number is required"
  ),
});

const EnrollmentIn2ndCourse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const [getloading, setGetLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModalSecond, setShowModalSecond] = useState(false);
  const [postLoadingSecond, setPostLoadingSecond] = useState(false);
  const [userData, setUserData] = useState();
  const [courseSecondInfo, setCourseSecondInfo] = useState({
    bankruptcytype: "",
    bankruptcycasenum: "",
  });

  const handleEnrollSecond = async (values) => {
    setPostLoadingSecond(true);

    await enroll2ndCourse(
      userId ? "admin" : "user",
      userId ? userId : userData.id,
      values
    )
      .then((res) => {
        if (!userId) {
          dispatch(login(res.data));
        }
        setPostLoadingSecond(false);
        navigate(
          userId
            ? `/user-dashboard-page?id=${userId}`
            : `/security-law-questions?verification=true`
        );
      })
      .catch((err) => {
        setPostLoadingSecond(false);
        if (err.response && err.response.data) {
          toast.error(err.response.data.error);
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  const handleSubmitSecond = async (values) => {
    handleEnrollSecond(values);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserData(userId);
        setUserData(response.data.data.user);
        setGetLoading(false);
      } catch (error) {
        setGetLoading(false);
        console.error("Error fetching data user:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div>
      <Header />

      {showModalSecond && (
        <StripeComponent
          showModal={showModalSecond}
          setShowModal={setShowModalSecond}
          isLoading={postLoadingSecond}
          setIsLoading={setPostLoadingSecond}
          values={courseSecondInfo}
          pay={handleEnrollSecond}
          currentCourse="second"
          amount={userData.amount}
        />
      )}
      {getloading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <ThreeDots
            height="40"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            wrapperClassName="spinner"
            visible={true}
          />
        </div>
      ) : (
        <div className="theme_container">
          <div className="my-profile">
            <h1 id="firstcourse">
              Enroll in <span className="text_success">Second Course</span>
            </h1>
          </div>

          <div className="row mt-40">
            <div className="row mt-40"></div>
            <div className="btn_wrap text-center">
              <button
                type="button"
                className=" btn_brnd "
                data-bs-toggle="modal"
                data-bs-target="#Serious1"
                disabled={postLoadingSecond}
              >
                {postLoadingSecond ? (
                  <ThreeDots
                    height="20"
                    width="150"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Enroll Now"
                )}
              </button>
            </div>
            {/*1st custom Modal */}
            <div
              className={`modal fade custom_modal `}
              id="Serious1"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="SeriousLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content pb-60">
                  <button
                    type="button"
                    className="btn_close"
                    data-bs-dismiss="modal"
                  >
                    <img src={cross} alt="cross" className="cross_btn" />
                  </button>

                  <div className="modal-body">
                    <div className="modal_content">
                      <h1 className="f-26 text-center">
                        <span className="text_success">Note</span>
                      </h1>
                      {userData.paid_second === "" ? (
                        <>
                          {" "}
                          {userData.attypayment == "no" && (
                            <p className="f-16 text-center mt-12 fw-700">
                              The price for the course is
                              <span className="text_success">
                                {" "}
                                $
                                {userData.attypayment === "no" &&
                                userData.couponcode_first !== ""
                                  ? 39.99
                                  : 19.99}{" "}
                              </span>
                            </p>
                          )}
                          {userData.attypayment == null && (
                            <p className="f-16 text-center mt-12 fw-700">
                              The price for the course is
                              <span className="text_success"> $ 19.99</span>
                            </p>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {userData.attypayment == "yes" && (
                        <p className="f-16 text-center mt-12 fw-700">
                          Amount will be paid by your attorney
                        </p>
                      )}
                      {userData.paid_second === "" ? (
                        <>
                          {" "}
                          {userData.totalAmount !== userData.amount &&
                            userData.attypayment == "no" && (
                              <p className="f-16 text-center fw-700">
                                Amount Payable
                                <span className="text_success">
                                  {" "}
                                  ${Number(userData.amount).toFixed(2)}{" "}
                                </span>
                              </p>
                            )}
                        </>
                      ) : (
                        userData.attypayment == "no" && (
                          <p className="f-16 text-center mt-12 fw-700">
                            The amount for the course is paid
                          </p>
                        )
                      )}

                      <div className="btn_wrap text-center mt-40">
                        <button
                          className="btn_brnd-outline mr-22 mb-2"
                          data-bs-dismiss="modal"
                          type="button"
                        >
                          Go Back
                        </button>

                        <button
                          className=" btn_brnd  mt-20"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            if (userData.paid_second === "") {
                              if (
                                userData.attypayment === "no" &&
                                userData.amount > 0
                              ) {
                                setShowModalSecond(true);
                              } else {
                                handleSubmitSecond({
                                  enrollInfo: courseSecondInfo,
                                });
                              }
                            } else {
                              handleSubmitSecond({
                                enrollInfo: courseSecondInfo,
                              });
                            }
                          }}
                          type={
                            (userData.attypayment === "yes" &&
                              userData.amount === 0) ||
                            userData.paid_second !== ""
                              ? "submit"
                              : "button"
                          }
                        >
                          Confirm & Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default EnrollmentIn2ndCourse;

import React from "react";
import AttorneyDashBoardSidebar from "../../layouts/attorneyDashBoardSidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";

const PrintableInfoSheets = () => {
  return (
    <div>
      <div className="wrapper">
        <AttorneyDashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <section className="client-editing">
            <div className="client-head">
              <h1 className="f18">Printable Info Sheets</h1>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrintableInfoSheets;

import React from "react";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ValidationSchema = Yup.object().shape({
  ssn: Yup.string().required("Last 4 Digits of SSN doesn't match"),
});

const SecurityQuestion = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course = queryParams.get("course");
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const handleSubmit = async (values) => {
    if (values.ssn.toLowerCase() === userData.user.data.ssn.toLowerCase()) {
      localStorage.setItem("verificationRequired", "false");
      if (course === "first") {
        navigate("/course");
      } else {
        navigate("/2nd-course");
      }
    } else {
      toast.error("Last 4 Digits of SSN doesn't match");
    }
  };

  return (
    <div>
      <Header />
      <div className="theme_container">
        <div className="my-profile">
          <h1 id="firstcourse">
            Account <span className="text_success">Identity</span>
          </h1>
        </div>

        <div className="row mt-40">
          <Formik
            initialValues={{
              ssn: "",
            }}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="row mt-4 justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="input_wrap">
                    <label className="label-title">Last 4 Digits of SSN</label>
                    <Field type="text" name="ssn" className="form-control" />
                    <ErrorMessage
                      name="ssn"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                </div>
              </div>
              <div className="btn_wrap text-center">
                <button type={"submit"} className=" btn_brnd">
                  Confirm & Continue
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SecurityQuestion;

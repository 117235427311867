import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addLanguage } from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import Header from "../../layouts/header";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  language: Yup.string().required("Language is required"),
  number: Yup.string().required("Phone is required"),
  date: Yup.date().required("Date is required"),
});

const LanguageRequestForm = () => {
  const [postLoading, setPostLoading] = useState(false);

  const { handleChange, touched, values, errors, handleSubmit, handleBlur } =
    useFormik({
      initialValues: { name: "", number: "", language: "", date: "" },
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setPostLoading(true);
        addLanguage(values)
          .then((res) => {
            toast.success("Language requested");
            setPostLoading(false);
            resetForm();
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setPostLoading(false);
          });
      },
    });

  return (
    <div>
      <Header />
      <div className="theme_container">
        <div className="head" style={{ marginTop: "150px" }}>
          <h1 className="f18">Language Request</h1>
        </div>
        <form onSubmit={handleSubmit} className="row mt-60">
          <div className="col-lg-6">
            <div className=" input_wrap ">
              <label className="label-title">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter name"
                onChange={handleChange}
                onBlur={handleBlur}
                name="name"
                value={values.name}
              />
              {errors.name && touched.name ? (
                <p className="text-danger">{errors.name}</p>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6">
            <div className=" input_wrap ">
              <label className="label-title">Phone</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Phone"
                onChange={handleChange}
                onBlur={handleBlur}
                name="number"
                value={values.number}
              />
              {errors.number && touched.number ? (
                <p className="text-danger">{errors.number}</p>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6">
            <div className=" input_wrap ">
              <label className="label-title">Language</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter language"
                onChange={handleChange}
                onBlur={handleBlur}
                name="language"
                value={values.language}
              />
              {errors.language && touched.language ? (
                <p className="text-danger">{errors.language}</p>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6">
            <div className=" input_wrap ">
              <label className="label-title">Date & time</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Date & time"
                onChange={handleChange}
                onBlur={handleBlur}
                name="date"
                value={values.date}
              />
              {errors.date && touched.date ? (
                <p className="text-danger">{errors.date}</p>
              ) : null}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="text-center">
              <button
                className="btn_brnd my-3"
                type="submit"
                disabled={postLoading}
              >
                {postLoading ? (
                  <ThreeDots
                    height="20"
                    width="70"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "block",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : (
                  "Request"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LanguageRequestForm;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  attorneyData: JSON.parse(localStorage.getItem("attorneyData")) || null,
  token: localStorage.getItem("token"),
  report: JSON.parse(localStorage.getItem("report")) || null,
}; 

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      if (action.payload.token) {
        state.token = action.payload.token;
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("verificationRequired", "true");
      }
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
      localStorage.removeItem("token");
      localStorage.removeItem("verificationRequired");
    },
    setAttorneyData: (state, action) => {
      state.attorneyData = action.payload;
      localStorage.setItem("attorneyData", JSON.stringify(action.payload));
    },
    clearAttroney: (state) => {
      state.attorneyData = null;
      localStorage.removeItem("attorneyData");
    },
    signup: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
      if (action.payload.token) {
        state.token = action.payload.token;
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("verificationRequired", "true");
      }
    },
    setBudgetAnalysis: (state, action) => {
      state.report = action.payload;
      localStorage.setItem("report", JSON.stringify(action.payload));
    },
    updateMotherLastName: (state, action) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const updated = {
        ...user,
        data: { ...user.data, mother_maiden: action.payload },
      };
      state.user = updated;
      localStorage.setItem("user", JSON.stringify(updated));
    },
  },
});

export const {
  login,
  logout,
  signup,
  setAttorneyData,
  clearAttroney,
  setBudgetAnalysis,
  updateMotherLastName,
} = userSlice.actions;
export default userSlice.reducer;

import React from "react";
import Header from "../../layouts/header";

const TermsAndCondition = () => {
  return (
    <div>
      <Header />
      <section className="about_us_page mt-250">
        <div className="theme_container">
          <h1 className="f-52 text-center">
            Terms and <span>Conditions</span>
          </h1>
          <div className="row   mt-40 mt_one align-items-center">
            <div className="col-lg-12">
              <div className="about_content mb-5">
                <h5 className="f-26">1. Acceptance of Terms</h5>
                <p className="mt-20 f-16">
                  Welcome! Evergreen Financial Counseling. (“evergreenclass.com”
                  or “us” or “we” or “our”) provides its services (described
                  below) to you through the website located at
                  www.evergreenclass.com and its related subdomains (the “Site”)
                  and through its mobile applications and related services
                  (collectively, such services, including any new features and
                  applications, and the Site, the “Service”), subject to the
                  following Terms of Service (as amended from time to time, the
                  “Terms of Service”). Through the Service, we provide
                  credit-related information, products, services, and other
                  features.
                </p>
                <p className="mt-20 f-16">
                  BY USING THE SITE, ENGAGING IN THE SERVICE, REQUESTING WE
                  CONTACT YOU ABOUT FINANCIAL SERVICES AND CREDIT-RELATED
                  OFFERS, AND/OR PARTICIPATING IN ANY OF OUR RELATED TEXT
                  MESSAGE PROGRAMS, YOU SIGNIFY YOUR AGREEMENT TO THE TERMS OF
                  SERVICE.
                </p>
                <p className="mt-20 f-16">
                  Evergreen Financial Counsel’s privacy policy applies to your
                  use of the Site, all Service provided by us, your request that
                  evergreenclass.com contact you about financial services and
                  credit related offers, and/or your participating in
                  evergreenclass.com’s Text Message Program, and its terms are
                  made part of these Terms of Service. By using the Site,
                  engaging in the Service, requesting that evergreenclass.com
                  contact you about financial services and credit related
                  offers, or participating in any related Text Message Program,
                  you acknowledge you have reviewed evergreenclass.com’s Privacy
                  Policy and agree to its terms. evergreenclass.com’s “Text
                  Message Program” includes sending text message(s) to
                  evergreenclass.com and/or receiving text message(s) from
                  evergreenclass.com.
                </p>
                <p className="mt-20 f-16">
                  BY USING THE SITE, ENGAGING IN THE SERVICE, REQUESTING THAT
                  evergreenclass.com CONTACT YOU ABOUT FINANCIAL SERVICES AND
                  CREDIT RELATED OFFERS, AND/OR PARTICIPATE IN
                  evergreenclass.com’S TEXT MESSAGE PROGRAM, YOU AGREE TO
                  ARBITRATE ALL CLAIMS BETWEEN YOU AND evergreenclass.com ON AN
                  INDIVIDUAL BASIS ONLY AND NOT AS PART OF ANY CLASS. A “CLAIM”
                  IS ANY CASE, CONTROVERSY, DISPUTE, TORT, DISAGREEMENT,
                  LAWSUIT, LEGAL ACTION, OR CLAIM NOW OR HEREAFTER PENDING
                  BETWEEN YOU AND evergreenclass.com, INCLUDING BUT NOT LIMITED
                  TO ANY ALLEGED STATE OR FEDERAL STATUTORY VIOLATION, OR ANY
                  DISPUTE OVER THE INTERPRETATION OF THE TERMS OF SERVICE OR THE
                  ARBITRATBILITY OF ANY CLAIM PURUSANT TO THE TERMS OF SERVICE.
                  THIS AGREEMENT TO ARBITRATE GOVERNS ALL PAST, CURRENT AND
                  PROSPECTIVE INTERACTIONS WITH evergreenclass.com. YOU AGREE
                  THAT YOU ARE WAIVING ALL RIGHTS TO (A) A TRIAL BY JURY; (B)
                  PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS ACTION
                  ARBITRATION; AND (C) BRING AN ACTION AGAINST
                  evergreenclass.com IN A COURT OF LAW. YOU MAY INDIVIDUALLY
                  ARBITRATE ANY CLAIM AGAINST evergreenclass.com IN ANY
                  JURISDICTION IN THE UNITED STATES. evergreenclass.com WILL
                  REIMBURSE YOU UP TO $300 OF YOUR ARBITRATION FILING FEE. THE
                  RULES OF THE AMERICAN ARBITRATION ASSOCIATION THEN IN FORCE
                  SHALL GOVERN THE ARBITRATION (provided however, that the terms
                  of these Terms of Service shall control over any inconsistency
                  between the Rules of the American Arbitration Association and
                  the Terms of Service). The arbitrator shall have authority to
                  interpret the Terms of Service, including but not limited to
                  the authority to decide whether any claim is arbitrable under
                  the Terms of Service and to decide issues related to the scope
                  of arbitration, the rules of arbitration, the arbitrator’s
                  jurisdiction, and the enforceability of the Terms of Service.
                  You agree that the Terms of Service involve commerce under 9
                  U.S.C., Section 1 et. seq., and that this Arbitration Clause
                  is governed by federal law, including the Federal Arbitration
                  Act. The remainder of the Terms of Service are governed by the
                  state of Arizona as provided in Section 34 below. If you don’t
                  agree to these Terms of Service, don’t use the Service. We can
                  change these Terms of Service at any time without any notice
                  to you. We will change the last revised date above when we
                  make any changes and post a new version of these Terms of
                  Service on our Site. It is your responsibility to review these
                  Terms of Service from time to time for any changes as it
                  creates a binding legal agreement between you and
                  evrgreenclass.com.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">2. Permission to Use the Service</h5>
                <p className="mt-20 f-16">
                  You have our permission to use the Service, but only if:
                </p>
                <p className="mt-20 f-16">You are over 18 years old;</p>
                <p className="mt-20 f-16">
                  You are using the Service for your own personal use and not
                  for commercial purposes;
                </p>
                <p className="mt-20 f-16">
                  You do not copy the Service or any part of the Service;
                </p>
                <p className="mt-20 f-16">
                  You do not modify the Service or any part of the Service;
                </p>
                <p className="mt-20 f-16">
                  You follow all the rules and restrictions in these Terms of
                  Service.
                </p>
                <p className="mt-20 f-16">
                  If you are applying through the Service for an extension of
                  credit with one of the third-party subscribers to the
                  evergreenclass.com network (“Network Subscribers”), you must
                  be an adult resident of the United States, including its
                  Commonwealths and Territories. By applying for an extension of
                  credit, you warrant and represent to evergreenclass.com and to
                  the relevant Network Subscriber(s) that you are an adult
                  resident of the US.
                </p>
                <p className="mt-20 f-16">
                  To ensure that evergreenclass.com provides a high-quality
                  experience for you and other users of the Service, you
                  acknowledge and agree that evergreenclass.com or its
                  representatives may investigate complaints, allegations of
                  abuse, infringement of third-party rights, or other
                  unauthorized uses of the Service. evergreenclass.com is not
                  required to disclose such an investigation unless required to
                  do so by law. evergreenclass.com will cooperate fully with any
                  law enforcement agencies in their investigation of potential
                  violation of any applicable laws.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">3. Accounts; Passwords; Security</h5>
                <p className="mt-20 f-16">
                  You may need to set up an account in order to use some of the
                  features of the Service. You should not use someone else’s
                  account. When you are setting up your account, you must give
                  us accurate and complete information. This means that you
                  cannot set up an account using someone else’s name or contact
                  information, or a phony name or phony contact information. You
                  have complete responsibility for your account and everything
                  that happens on your account. This means you need to be
                  careful with your password. If you find out that someone is
                  using your account without your permission, you must let us
                  know immediately. You may not transfer your account to someone
                  else. We are not liable for any damages or losses caused by
                  someone using your account without your permission. However,
                  if we (or anyone else) suffer any damage due to the
                  unauthorized use of your account, you may be liable.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">4. Personal Use Only</h5>
                <p className="mt-20 f-16">
                  We are making the Service available to you for your
                  information and personal use only. You may not (and you agree
                  not to) use, copy, distribute, transmit, broadcast, sell, or
                  do anything else with the Service for any other purpose.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">5. User Content</h5>
                <p className="mt-20 f-16">
                  You are solely responsible for any User Content you post to
                  the Service, and the consequences of posting or publishing it.
                  By “User Content”, we mean any Content you post to the
                  Service. “Content” means information, data, text, software,
                  music, sound, photos, graphics, videos, messages, tags,
                  interactive features, or any other materials. When we say
                  “post”, we include posting, uploading, sharing, submitting or
                  otherwise providing User Content in any manner in connection
                  with the Service.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  6. Restrictions on User Content and Your Conduct
                </h5>
                <p className="mt-20 f-16">
                  You may not: use the Service for any illegal purpose;
                </p>
                <p className="mt-20 f-16">
                  Submit User Content that you don’t have the right to submit,
                  unless you have the owner’s permission; this includes material
                  covered by someone else’s copyright, patent, trademark, trade
                  secret, privacy, publicity, or any other proprietary right;
                  forge headers or manipulate other identifiers in order to
                  disguise the origin of any User Content you submit; submit any
                  User Content that contains lies, falsehoods or
                  misrepresentations that could damage us or anyone else; submit
                  User Content that is illegal, obscene, defamatory, libelous,
                  threatening, pornographic, harassing, hateful, racially or
                  ethnically offensive, or encourages conduct that would be
                  considered a criminal offense, give rise to civil liability,
                  violate any law, or is otherwise inappropriate
                </p>
                <p className="mt-20 f-16">
                  Transmit any unsolicited or unauthorized advertising,
                  promotional materials, junk mail, spam, chain letters, pyramid
                  schemes, or any other form of solicitation; impersonate anyone
                  else or lie about your affiliation with another person or
                  entity; use meta tags or any other “hidden text” utilizing any
                  of our or our suppliers’ product names or trademarks;
                </p>
                <p className="mt-20 f-16">
                  Upload, launch, post, email or transmit any material
                  (including any bot, worm, scripting exploit or computer virus)
                  that is likely to harm or corrupt the Service, or harm or
                  corrupt our or anyone else’s computer systems, or data; use
                  the Service to harm minors in any way, including posting User
                  Content that violates child pornography laws, child sexual
                  exploitation laws, or any other laws protecting children;
                  collect or gather other people’s personal information
                  (including account information) from the Service;
                </p>
                <p className="mt-20 f-16">
                  Submit User Content which disparages us or our partners,
                  vendor or affiliates; or solicit, for commercial purposes, any
                  users of the Service.
                </p>
                <p className="mt-20 f-16">
                  We have the sole right, but not necessarily the obligation, to
                  delete at any time any User Content that violates these rules
                  or that we believe to be inappropriate for any reason.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  7. Guidelines on evergreenclass.com Forums
                </h5>
                <p className="mt-20 f-16">
                  You agree to the following terms regarding participation in
                  any evergreenclass.com online forums, including posting any
                  comments on evergreenclass.com blog posts or through any
                  social media outlet (collectively, “evergreenclass.com
                  Forums”):
                </p>
                <p className="mt-20 f-16">
                  Any User Content you provide to the evergreenclass.com Forums
                  must be relevant to the topic.
                </p>
                <p className="mt-20 f-16">
                  Do not respond to any questions on the evergreenclass.com
                  Forums that have previously been answered on the
                  evergreenclass.com Forum.
                </p>
                <p className="mt-20 f-16">
                  Any subject line you provide should accurately reflect the
                  subject of the thread. All User Content must be provided in
                  the relevant forum sections or blog post. Include as much
                  information as possible when posting on any evergreenclass.com
                  Forums.
                </p>
                <p className="mt-20 f-16">
                  Any moderating of the evergreenclass.com Forums and/or any
                  actions taken with respect to forum or blog content are at the
                  sole discretion of evergreenclass.com
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  8. Intellectual Property Rights in User Content
                </h5>
                <p className="mt-20 f-16">
                  If you post User Content, you represent and warrant to us that
                  you either own all the Content you are posting, or you have
                  the right to post the Content. Furthermore, you represent and
                  warrant that you have the right to allow us to make your User
                  Content available for others to view and use as part of the
                  Service without requiring that any such use be subject to
                  additional obligations or terms. If you do not have these
                  rights, do not post the Content. By posting your User Content,
                  you do not lose any ownership rights you may have to it.
                  However, subject to these Terms of Service and our Privacy
                  Policy, you do grant us a worldwide, non-exclusive,
                  royalty-free, sublicensable and transferable license to use,
                  reproduce, distribute, prepare derivative works of, display,
                  and perform your User Content in connection with the Service
                  and our business, in any media formats or in tangible form and
                  through any media channels now known or hereinafter developed.
                  Subject to these Terms of Service and our Privacy Policy, you
                  also grant each user of the Service a non-exclusive
                  royalty-free, sublicensable and transferable license to access
                  your User Content through the Service, and to use, reproduce,
                  distribute, prepare derivative works of, display and perform
                  your User Content as permitted through the functionality of
                  the Service and under these Terms of Service.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  9.User Content You Share Becomes Public
                </h5>
                <p className="mt-20 f-16">
                  Subject to our Privacy Policy, you understand that once you
                  post User Content, your content may become public. If you
                  don’t want the whole world to see some or all of your Content,
                  don’t post it on the Service.
                </p>
                <p className="mt-20 f-16">
                  In addition, we may be required to disclose your User Content
                  to third parties if we have a good faith belief that access,
                  use, preservation or disclosure of such User Content is
                  reasonably necessary to (a) satisfy any applicable law, rule,
                  regulation, legal process or enforceable governmental request,
                  (b) enforce these Terms of Service, including investigation of
                  potential violations thereof, (c) detect, prevent, or
                  otherwise address fraud, security or technical issues, or (d)
                  protect against imminent harm to the rights, property or
                  safety of evergreenclass.com, its users or the public as
                  required or permitted by law.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  10. We Are Not Responsible for User Content
                </h5>
                <p className="mt-20 f-16">
                  We do not endorse any User Content or support any views,
                  opinions, recommendations, or advice that may be in user
                  submissions. User Content comes from a variety of sources, and
                  we make no promises about the reliability of any source or the
                  accuracy, usefulness, safety, or intellectual property rights
                  of any user submission. You may be offended by User Content
                  that you see on the Service. You may find some of it to be
                  inaccurate, offensive, indecent, or objectionable. YOU USE ALL
                  USER CONTENT ENTIRELY AT YOUR OWN RISK. YOU AGREE NOT TO HOLD
                  US RESPONSIBLE IN ANY WAY FOR YOUR USE OF THE SERVICE,
                  INCLUDING YOUR EXPOSURE TO USER CONTENT
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">11. Proprietary Rights</h5>
                <p className="mt-20 f-16">
                  evergreenclass.com and its suppliers retain all right, title
                  and interest (including all trademark, copyright, trade
                  secret, patent and other rights) in and to the Service and
                  Content which is included in the Service (other than User
                  Content). If you give feedback on the Service, for example
                  recommendations for improvements or features, implementation
                  of that feedback is owned by us and may become part of the
                  Service without compensation to you. We reserve all rights in
                  and to the Service unless we expressly state otherwise. The
                  Service contains proprietary and confidential information that
                  is protected by applicable intellectual property and other
                  laws.
                </p>
                <p className="mt-20 f-16">
                  You may not decompile, reverse engineer, disassemble, or
                  otherwise reduce the code embodied in, or otherwise applicable
                  to, the Service to a human-perceivable form, except and only
                  to the extent that such activity is expressly permitted by
                  applicable law, and in that case, only if you notify us in
                  writing in advance. You may not copy, frameset, enclose or
                  otherwise distribute any part of the Service.
                </p>
                <p className="mt-20 f-16">
                  All brand, product and service names used in the Service which
                  identify evergreenclass.com or our suppliers and our or their
                  proprietary products and services are the trademarks or
                  service marks of evergreenclass.com or our suppliers. Nothing
                  in this Service shall be deemed to confer on any person any
                  license or right on the part of evergreenclass.com or such
                  supplier with respect to any such image, logo or name.
                </p>
                <p className="mt-20 f-16">
                  You agree not to disable, interfere, or try to get around any
                  of the features of the Service related to security, preventing
                  or restricting use or copying of any Content, or enforcing the
                  limits on the use of the Service or the Content on the
                  Service.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">12. Enforcement of Copyrights</h5>
                <p className="mt-20 f-16">
                  We respect the intellectual property rights of others. You may
                  not use the Service to infringe anyone else’s copyright or
                  other intellectual property right. If we find out that you are
                  infringing, we may remove your User Content. We do not have to
                  give you notice that we are removing your User Content. We may
                  also terminate your account if we decide that you are a repeat
                  infringer. We consider a repeat infringer to be a user who has
                  been notified of infringing activity more than twice or who
                  has had User Content removed from the Service more than twice.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">13. Notify Us of Infringers</h5>
                <p className="mt-20 f-16">
                  In order for us to take action, you must do the following in
                  your notice:
                </p>
                <p className="mt-20 f-16">
                  Provide your physical or electronic signature; identify the
                  copyrighted work that you believe is being infringed; identify
                  the item on the Service that you think is infringing your work
                  and include sufficient information about where the material is
                  located on the Service (including which website) so that we
                  can find it; provide us with a way to contact you, such as
                  your address, telephone number, or e-mail; provide a statement
                  that you believe in good faith that the item you have
                  identified as infringing is not authorized by the copyright
                  owner, its agent, or the law to be used on the Service; and
                  provide a statement that the information you provide in your
                  notice is accurate, and that (under penalty of perjury), you
                  are authorized to act on behalf of the copyright owner whose
                  work is being infringed.
                </p>
                <p className="mt-20 f-16">
                  Here is the contact information for our copyright agent:
                </p>
                <p className="mt-20 f-16">Copyright Enforcement</p>
                <p className="mt-20 f-16">Evergreen Financial Counseling</p>
                <p className="mt-20 f-16">
                  7137 E. Rancho Vista Drive, Suite B-21
                </p>
                <p className="mt-20 f-16">Scottsdale, Arizona 85252</p>
                <p className="mt-20 f-16">Phone: (310-779-3898)</p>
                <p className="mt-20 f-16">
                  Again, we cannot take action unless you give us all the
                  required information.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">14. Credit Report Card</h5>
                <p className="mt-20 f-16">
                  You may obtain a Credit Report Card from evergreenclass.com
                  through the Service. The Credit Report Card is made available
                  to you at no charge, and you are not required to apply for any
                  product or service offered through the Service in order to
                  obtain the Credit Report Card. The Credit Report Card will
                  briefly summarize the content of your consumer report and will
                  explain the parameters (e.g., payment history, debt usage,
                  credit age, account mix, and the number of inquiries)
                  typically used to arrive at your credit score, and includes
                  other related features, including an Action Plan that will
                  recommend certain actions for you to take to help you manage
                  your credit. The Credit Report Card also includes any future
                  enhancements we may make to that service (whether or not then
                  called the “Credit Report Card”). In addition, the Credit
                  Report Card may provide helpful information about how consumer
                  behavior affects each of these parameters.
                </p>
                <p className="mt-20 f-16">
                  To the extent the Credit Report Card includes an Action Plan,
                  or any recommendations or predictions related thereto, they
                  are provided merely as simulated estimates for educational
                  purposes, in an attempt to help you to determine how future
                  financial decisions (e.g., reducing debt or obtaining a new
                  credit card) might affect your credit score. While we endeavor
                  to demonstrate how changing one or more variables might impact
                  your credit score, in reality, your actual credit score is
                  determined by factoring in multiple variables simultaneously
                  and may be affected by such factors and other events
                  (including, without limitation, the recalibration of scoring
                  systems by the entities that create or use the scoring
                  systems, data discrepancies or changes that may occur to the
                  data used to determine your actual credit score, circumstances
                  beyond your control) in an unpredictable way. The credit score
                  you receive may differ from the scores used by creditors when
                  you apply for credit, as creditors also create and use their
                  own credit scores.
                </p>

                <p className="mt-20 f-16">
                  THE CREDIT REPORT CARD AND ANY OTHER CREDIT ESTIMATES, OR
                  RECOMMENDATIONS OR PREDICTIONS PROVIDED BY THE SERVICE, ARE
                  NOT INTENDED TO AND DO NOT PROVIDE OR CONSTITUTE LEGAL OR
                  OTHER PROFESSIONAL ADVICE. BECAUSE EVERGREENCLASS.COM IS NOT A
                  CONSUMER REPORTING AGENCY OR FINANCIAL ADVISOR, IT IS NOT, AND
                  CANNOT BE, RESPONSIBLE FOR EITHER THE CONTENT OF YOUR CREDIT
                  REPORT CARD, ITS ACCURACY, OR THE CREDIT SCORE RANGE DERIVED
                  FROM SUCH REPORT, OR ITS APPLICABILITY TO YOUR CIRCUMSTANCES.
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com MAKES NO WARRANTY, REPRESENTATION OR
                  GUARANTEE THAT THE CREDIT REPORT CARD OR ANY OTHER CREDIT
                  ESTIMATE CONTAINS ACCURATE OR COMPLETE INFORMATION, OR THAT
                  ANY RELATED RECOMMENDATIONS WILL RAISE OR OTHERWISE IMPACT
                  YOUR CREDIT SCORE, ESTABLISH OR REBUILD GOOD CREDIT, OR
                  IMPROVE YOUR CREDIT RECORD, CREDIT HISTORY OR CREDIT RATING.
                  FURTHER, THE CREDIT REPORT CARD IS NOT INTENDED TO REPRESENT
                  ALL INFORMATION IN YOUR CREDIT FILE. TO GET YOUR COMPLETE
                  CREDIT FILE, CLICK ON THE LINK TO A FULL REPORT WITHIN THE
                  CREDIT REPORT CARD (A FEE MAY APPLY).
                </p>
                <p className="mt-20 f-16">
                  IN ORDER TO PROVIDE YOU WITH THE CREDIT REPORT CARD OR ANY
                  OTHER CREDIT ESTIMATE, Evergreenclass.com MUST OBTAIN YOUR
                  CONSUMER REPORT FROM A CONSUMER REPORTING AGENCY. YOU
                  UNDERSTAND AND AGREE THAT Evergreenclass.com IS NOT
                  RESPONSIBLE FOR A DELAY IN DELIVERING THE CREDIT REPORT CARD,
                  ANY OTHER CREDIT ESTIMATE OR ANY PART OF THE SERVICE OR AN
                  INABILITY TO DELIVER THE CREDIT REPORT CARD, ANY OTHER CREDIT
                  ESTIMATE OR ANY PART OF THE SERVICE THAT IS DUE TO A CONSUMER
                  REPORTING AGENCY’S DELAY IN PROVIDING OR FAILURE TO PROVIDE
                  YOUR CONSUMER REPORT.
                </p>
                <p className="mt-20 f-16">
                  You may not request a Credit Report Card for anyone other than
                  yourself. You understand that accessing or attempting to
                  access another person’s consumer report without their express
                  authorization is a violation of federal law and may violate
                  the law of the state in which you reside. By requesting a
                  Credit Report Card, you represent that you are requesting it
                  on your own behalf and not for another.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">15. Credit Report Card Consent</h5>
                <p className="mt-20 f-16">
                  When you request the Credit Report Card, you will be asked to
                  authorize us to obtain your consumer report from a consumer
                  reporting agency on your behalf for the purpose of providing
                  you with the Credit Report Card. This consent, if you give it,
                  constitutes “written instructions” to Evergreenclass.com under
                  the Fair Credit Reporting Act authorizing Evergreenclass.com
                  to obtain your personal credit profile or other information
                  from a consumer reporting agency, for the purpose of your use
                  of the Credit Report Card. You grant this authorization on an
                  ongoing basis and agree that we may access your personal
                  credit profile or obtain other information from a consumer
                  reporting agency on a regular basis. You may revoke your
                  ongoing authorization at any time by terminating the Service.
                  The terms of use for the Credit Report Card service are
                  described in this document, which you may print for your
                  records. If you require confirmation from us of your consent
                  (including a paper copy of such consent), you may contact us
                  as described in Section 17 below. You will not be charged a
                  fee for such confirmation request.
                </p>
                <p className="mt-20 f-16">
                  You should also consider the availability of information,
                  tools, and resources that may be available to you without
                  charge or for a nominal fee in the event that you suspect that
                  your identity has been stolen or compromised, or may be
                  subject to possible theft or misuse. You can learn more
                  regarding the availability of such resources by visiting the
                  website maintained by the Federal Trade Commission at
                  http://www.ftc.gov
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  16. Our Rights to Communicate and Use Information
                </h5>
                <p className="mt-20 f-16">
                  We may retain information that you provide, and we obtain,
                  during your use of the Service. We may, from time to time, use
                  this information to match you with offers for products and
                  services made available through various Network Subscribers,
                  subject to our Privacy Policy. We will make these offers
                  available to you at your convenience and there will be no
                  obligation to accept any offer.
                </p>
                <p className="mt-20 f-16">
                  When you apply for a product or service through the Service,
                  you are establishing a business relationship with
                  evergreenclass.com and, in some cases, with companies that
                  also provide products or services through us. If you submit a
                  request or application for a product or service that is made
                  available through the Service by a Network Subscriber,
                  evergreenclass.com may share your personal information with
                  Network Subscriber(s) with your permission or as permitted by
                  law and described in evergreenclass.com’s Privacy Policy. This
                  information may be used by evergreenclass.com or provided by
                  evergreenclass.com to third parties that may contact you, with
                  respect to (A) your application or request, (B) the status of
                  your application, (C) alternatives available to you in the
                  event your application is rejected, (D) other products or
                  services offered by or through evergreenclass.com and its
                  Network Subscribers or (E) as relevant and as permitted by
                  law, obtaining consumer report and public records information
                  to verify your identity and process your request or
                  application.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">17. How to Communicate with Us</h5>
                <p className="mt-20 f-16">
                  Notices about copyright infringement should go to our
                  copyright enforcement department as provided in Section 13.
                </p>
                <p className="mt-20 f-16">
                  For any questions exclusively about your evergreenclass.com
                  Credit Detect service, agents are available 24 hours a day,
                  seven days a week at 1-855-387-4541, but are only able to
                  assist you with evergreenclass.com Credit Detect-specific
                  questions. For any other communications with us (e.g., to
                  terminate the Credit Report Card, feedback, comments, or
                  requests for technical support), you should contact us through
                  our customer service department at support.evergreenclass.com.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">18. Storage and Availability</h5>
                <p className="mt-20 f-16">
                  We are not a content-archiving service. We do not promise to
                  store or make available on the Service any User Content that
                  you submit, or any other Content, for any length of time. This
                  includes information previously provided to you in the Credit
                  Report Card or Identity Risk Score, which we may cease
                  providing at any time. You are solely responsible for keeping
                  back-ups of everything you post on the Service.
                </p>
                <p className="mt-20 f-16">
                  You acknowledge that temporary interruptions in the
                  availability of the Service may occur from time to time as
                  normal events. Also, we may decide to cease making available
                  the Service or any portion of the Service at any time and for
                  any reason. Under no circumstances will evergreenclass.com or
                  its suppliers be held liable for any damages due to such
                  interruptions or lack of availability.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">19. Sale of Products</h5>
                <p className="mt-20 f-16">
                  The Service may also include the offer for sale of certain
                  products (whether software, services, or other merchandise)
                  (each, a “Product”) for an amount in addition to that paid for
                  use of the Service. Any offer for sale or purchase of such
                  Products is subject to the terms of sale and warranty (if any)
                  provisions of the vendor offering the particular Product
                  through the Service (including through any shopping cart for
                  the particular Product). We are not responsible for any such
                  transactions or Products unless we are the vendor, in which
                  case the terms of purchase we have posted for that Product
                  shall apply. Purchase of such Products is optional and
                  voluntary, and you must affirmatively request or consent to
                  purchase any such Product.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">20. Links to Other Sites</h5>
                <p className="mt-20 f-16">
                  evergreenclass.com may provide you with access to Network
                  Subscribers solely for your convenience. Some of these Network
                  Subscriber sites may be co-branded (i.e., bear the name and/or
                  logo of both the Network Subscriber and evergreenclass.com) or
                  private labeled (i.e., bear the evergreenclass.com name, but
                  be fulfilled by a Network Subscriber). The provision of access
                  to a Network Subscriber does not signify an endorsement by
                  evergreenclass.com of the Network Subscriber’s website or the
                  services offered by that Network Subscriber.
                  evergreenclass.com has no control over, does not review, and
                  cannot be responsible for Network Subscriber websites or the
                  content on those sites. Your use of a Network Subscriber
                  website, or services will be subject to the Network
                  Subscriber’s terms of use and Network Subscriber’s privacy
                  policy. If you decide to use the services of a Network
                  Subscriber, or if you decide to purchase a product from a
                  Network Subscriber, any transaction between you and that
                  Network Subscriber (including any agreement governing such a
                  transaction) will be strictly between you and the Network
                  Subscriber. Evergreenclass.com has no control over the Network
                  Subscriber’s obligations to you, and makes no representations
                  or warranties with respect to the Network Subscriber’s
                  products, services or performance. It is your responsibility
                  to take precautions to ensure that Network Subscriber websites
                  are free of viruses or other items of a destructive nature.
                  YOUR USE OF ANY THIRD PARTY SITE, EVEN IF ACCESSED THROUGH
                  Evergreenclass.com, WILL BE GOVERNED SOLELY BY THE TERMS OF
                  USE AND PRIVACY POLICY OF THAT THIRD PARTY SITE. YOU AGREE
                  THAT evergreenclass.com WILL NOT BE RESPONSIBLE OR LIABLE FOR
                  LOSS OR DAMAGE YOU MAY INCUR AS THE RESULT OF A TRANSACTION
                  YOU ENTER INTO WITH A NETWORK SUBSCRIBER, ANY INFORMATION YOU
                  PROVIDE TO A NETWORK SUBSCRIBER, A NETWORK SUBSCRIBER’S
                  COMPLIANCE WITH LAW, OR A NETWORK SUBSCRIBER’S MISUSE OF YOUR
                  INFORMATION.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  21. Policy on Linking to the evergreenclass.com Service
                </h5>
                <p className="mt-20 f-16">
                  You are granted a limited, non-exclusive right, which we may
                  revoke at any time, to create a hypertext link to the website
                  found at www.evergreenclass.com. If you exercise this limited,
                  non-exclusive right, however, you must agree to the following:
                </p>
                <p className="mt-20 f-16">
                  You will not portray evergreenclass.com, its affiliates or
                  Network Subscribers or their respective products and services
                  in a false, misleading, offensive, derogatory, or otherwise
                  defamatory manner.
                </p>
                <p className="mt-20 f-16">
                  You will not misrepresent your relationship with
                  evergreenclass.com nor imply that evergreenclass.com sponsors,
                  endorses, or is affiliated with any website, products or
                  services.
                </p>
                <p className="mt-20 f-16">
                  You will not replicate, modify, or alter the appearance or
                  content of the Service.
                </p>
                <p className="mt-20 f-16">
                  You will not use, frame, or utilize framing techniques to
                  enclose any evergreenclass.com trademark, logo, or other
                  proprietary information, the images found in the Service, the
                  content of any text, or the layout and design of any page or
                  form contained on a page on the evergreenclass.com website
                  without the express prior written consent of
                  evergreenclass.com. Furthermore, a site that links to the
                  Service:
                </p>
                <p className="mt-20 f-16">
                  Must not contain content that could be construed as obscene,
                  libelous, defamatory, pornographic, or inappropriate for all
                  ages;
                </p>
                <p className="mt-20 f-16">
                  Must not contain materials that would violate any laws.
                </p>
                <p className="mt-20 f-16">
                  If you exercise this limited, non-exclusive right to create a
                  hypertext link to the Service, you agree that the link must be
                  removed at any time, immediately upon evergreenclass.com’s
                  request.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">22. Warranty Disclaimer</h5>
                <p className="mt-20 f-16">
                  USE OF THE SERVICE IS AT YOUR OWN RISK. THE SERVICE IS
                  PROVIDED ON A “AS IS” AND “AS AVAILABLE” BASIS.
                  evergreenclass.com AND ITS AFFILIATES, SUPPLIERS, NETWORK
                  SUBSCRIBERS AND PARTNERS EXPRESSLY DISCLAIM ALL WARRANTIES OF
                  ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                  LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                </p>
                <p className="mt-20 f-16">
                  evergreenclass.com AND ITS AFFILIATES, SUPPLIERS, NETWORK
                  SUBSCRIBERS AND PARTNERS MAKE NO WARRANTY THAT (A) THE SERVICE
                  OR ANY PRODUCTS OR SERVICES PURCHASED OR OBTAINED THROUGH THE
                  SERVICE WILL MEET YOUR REQUIREMENTS; (B) THE SERVICE WILL BE
                  UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; OR (C) THAT THERE
                  WILL BE NO ERRORS IN THE SERVICE OR THAT Evergreenclass.com
                  WILL FIX ANY ERRORS. ANY MATERIALS OBTAINED THROUGH USE OF THE
                  SERVICE ARE OBTAINED AT YOUR OWN DISCRETION AND RISK AND
                  evergreenclass.com SHALL NOT BE RESPONSIBLE FOR ANY DAMAGE
                  CAUSED TO YOUR COMPUTER OR DATA OR FOR ANY BUGS, VIRUSES,
                  TROJAN HORSES OR OTHER DESTRUCTIVE CODE RESULTING FROM USE OF
                  THE SERVICE OR ANY CONTENT OBTAINED FROM THE SERVICE.
                </p>
                <p className="mt-20 f-16">
                  ALTHOUGH evergreenclass.com STRIVES TO PROVIDE INFORMATION
                  THAT CONSUMERS MAY FIND HELPFUL, THIS INFORMATION SHOULD NOT
                  BE CONSIDERED CREDIT OR LEGAL ADVICE. YOU SHOULD NOT USE THE
                  GENERAL INFORMATION PROVIDED BY THE SERVICE AS A SUBSTITUTE
                  FOR THE ADVICE OF A COMPETENT PROFESSIONAL.
                </p>
                <p className="mt-20 f-16">
                  SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
                  SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO
                  HAVE OTHER LEGAL RIGHTS, WHICH VARY FROM STATE TO STATE
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">23. Limitation of Liability</h5>
                <p className="mt-20 f-16">
                  TO THE FULLEST EXTENT PERMITTED UNDER LAW, evergreenclass.com
                  AND ITS AFFILIATES, SUPPLIERS, NETWORK SUBSCRIBERS AND
                  PARTNERS HAVE NO OBLIGATION OR LIABILITY (WHETHER ARISING IN
                  CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT
                  LIABILITY OR OTHERWISE) FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                  PUNITIVE, OR CONSEQUENTIAL DAMAGES OR LIABILITIES (INCLUDING,
                  BUT NOT LIMITED TO, ANY LOSS OF DATA, REVENUE OR PROFIT)
                  ARISING FROM OR RELATED TO YOUR USE OF THE SERVICE OR ANY
                  PRODUCT, SERVICE OR CONTENT PROVIDED BY OR THROUGH THE
                  SERVICE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                  SUCH DAMAGES IN ADVANCE. THE FOREGOING LIMITATION APPLIES TO
                  DAMAGES ARISING FROM (A) YOUR USE OR INABILITY TO USE THE
                  SERVICE; (B) COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
                  SERVICES RESULTING FROM ANY GOODS OR SERVICES PURCHASED
                  THROUGH OR FROM THE SERVICE; (C) UNAUTHORIZED ACCESS TO OR
                  ALTERATION OF YOUR USER CONTENT; (D) THIRD PARTY CONTENT MADE
                  AVAILABLE TO YOU THROUGH THE SERVICE OR (E) ANY OTHER MATTER
                  RELATING TO THE SERVICE. SOME STATES DO NOT ALLOW THE
                  LIMITATION OR EXCLUSION OF INCIDENTAL, CONSEQUENTIAL OR OTHER
                  TYPES OF DAMAGES, SO SOME OF THE ABOVE LIMITATIONS MAY NOT
                  APPLY TO YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                  CONTAINED HEREIN, evergreenclass.com’S LIABILITY AND THE
                  LIABILITY OF EACH OF ITS OFFICERS, DIRECTORS, INVESTORS,
                  EMPLOYEES, AGENTS, ADVERTISERS, LICENSORS, SUPPLIERS, NETWORK
                  SUBSCRIBERS, SERVICE PROVIDERS AND OTHER CONTRACTORS TO YOU OR
                  ANY THIRD PARTIES UNDER ANY CIRCUMSTANCE IS LIMITED TO A
                  MAXIMUM AMOUNT OF THE GREATER OF (A) $100 OR (B) THE AGGREGATE
                  FEES YOU HAVE PAID TO evergreenclass.com FOR SERVICES IN THE
                  SIX (6) MONTHS PRECEDING THE DATE THE CLAIM AROSE.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">24. Indemnity</h5>
                <p className="mt-20 f-16">
                  You agree to indemnify, defend (if we so request) and hold
                  harmless evergreenclass.com and its affiliates, Network
                  Subscribers, suppliers, partners, officers, agents, and
                  employees from and against any claim, demand, losses, damages
                  or expenses (including reasonable attorney’s fees) arising
                  from any User Content, your use of the Service, your
                  connection to the Service, your violation of these Terms of
                  Service or your violation of any rights of any third party.
                  Your indemnification obligation will survive the termination
                  of these Terms of Service and your use of the Service.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">25. Termination and Suspension</h5>
                <p className="mt-20 f-16">
                  We may terminate or suspend your permission to use the Service
                  immediately and without notice upon any violation of these
                  Terms of Service, your failure to pay any fees when due, upon
                  the request of law enforcement or government agencies, for
                  extended periods of inactivity, for unexpected technical
                  issues or problems or for engagement by you in fraudulent or
                  illegal activities. If we terminate your use of the Service
                  for any of these reasons or otherwise for cause, we will not
                  refund any fees you may have paid, whether for access to the
                  Service or for Products (if applicable)
                </p>
                <p className="mt-20 f-16">
                  You may terminate your use of the Service by closing your
                  evergreenclass.com account and/or ceasing to access the
                  Service. If you need help closing your account, please contact
                  us as described in Section 17 above.
                </p>
                <p className="mt-20 f-16">
                  Upon any termination we may delete your account, data,
                  passwords and User Content and we may bar you from further use
                  of the Service. You understand that we may also continue to
                  make your User Content available on the Service even if your
                  use of the Service is terminated or suspended. You agree that
                  we will have no liability to you or any third party for
                  termination of your account, User Content or access to the
                  Service.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">26. Export Control</h5>
                <p className="mt-20 f-16">
                  You may not use, export or re-export any Content or any copy
                  or adaptation of such Content, or any product or service
                  offered on the Service, in violation of any applicable laws,
                  rules or regulations, including, without limitation, United
                  States export laws and regulations.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  27. Apple-Enabled Software Applications
                </h5>
                <p className="mt-20 f-16">
                  evergreenclass.com may offer software applications that are
                  intended to be operated in connection with products made
                  commercially available by Apple Inc. (“Apple”), among other
                  platforms. With respect to software that is made available for
                  your use in connection with an Apple-branded product (such
                  software, “Apple-Enabled Software”), in addition to the other
                  terms and conditions set forth in these Terms of Service, the
                  following terms and conditions apply:
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com and you acknowledge that these Terms of
                  Service are concluded between{" "}
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com and you only, and not with Apple, and that
                  as between{" "}
                </p>

                <p className="mt-20 f-16">
                  Evergreenclass.com and Apple, Evergreenclass.com, not Apple,
                  is solely responsible for the Apple-Enabled Software and the
                  content thereof.
                </p>
                <p className="mt-20 f-16">
                  You may not use the Apple-Enabled Software in any manner that
                  is in violation of or inconsistent with the Usage Rules set
                  forth for Apple-Enabled Software in, or otherwise be in
                  conflict with, the App Store Terms of Service.
                </p>
                <p className="mt-20 f-16">
                  Your license to use the Apple-Enabled Software is limited to a
                  non-transferable license to use the Apple-Enabled Software on
                  an iOS Product that you own or control, as permitted by the
                  Usage Rules set forth in the App Store Terms of Service.
                </p>
                <p className="mt-20 f-16">
                  Apple has no obligation whatsoever to provide any maintenance
                  or support services with respect to the Apple-Enabled
                  Software.
                </p>
                <p className="mt-20 f-16">
                  Apple is not responsible for any product warranties, whether
                  express or implied by law. In the event of any failure of the
                  Apple-Enabled Software to conform to any applicable warranty,
                  you may notify Apple, and Apple will refund the purchase price
                  for the Apple-Enabled Software to you, if any; and, to the
                  maximum extent permitted by applicable law, Apple will have no
                  other warranty obligation whatsoever with respect to the
                  Apple-Enabled Software, or any other claims, losses,
                  liabilities, damages, costs or expenses attributable to any
                  failure to conform to any warranty, which will be
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com’s sole responsibility, to the extent it
                  cannot be disclaimed under applicable law.
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com and you acknowledge that
                  evergreenclass.com, not Apple, is responsible for addressing
                  any claims of you or any third party relating to the
                  Apple-Enabled Software or your possession and/or use of that
                  Apple-Enabled Software, including, but not limited to: (i)
                  product liability claims; (ii) any claim that the
                  Apple-Enabled Software fails to conform to any applicable
                  legal or regulatory requirement; and (iii) claims arising
                  under consumer protection or similar legislation.
                </p>
                <p className="mt-20 f-16">
                  In the event of any third party claim that the Apple-Enabled
                  Software or the end-user’s possession and use of that
                  Apple-Enabled Software infringes that third party’s
                  intellectual property rights, as between evergreenclass.com
                  and Apple, evergreenclass.com, not Apple, will be solely
                  responsible for the investigation, defense, settlement and
                  discharge of any such intellectual property infringement
                  claim.
                </p>
                <p className="mt-20 f-16">
                  You represent and warrant that (i) you are not located in a
                  country that is subject to a U.S. Government embargo, or that
                  has been designated by the U.S. Government as a “terrorist
                  supporting” country; and (ii) you are not listed on any U.S.
                  Government list of prohibited or restricted parties.
                </p>
                <p className="mt-20 f-16">
                  If you have any questions, complaints or claims with respect
                  to the Apple-Enabled Software, they should be directed to
                  evergreenclass.com as follows:
                </p>
                <p className="mt-20 f-16">Online Support</p>
                <p className="mt-20 f-16">160 Spear Street, Suite 1020</p>
                <p className="mt-20 f-16">San Francisco, CA 94105</p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">28. Right to Dispute</h5>
                <p className="mt-20 f-16">
                  If you have questions regarding your credit report or would
                  like to dispute information, you may request an investigation
                  by contacting the consumer reporting agency that supplied the
                  information, as indicated in your credit report. You do not
                  have to purchase your credit report or other information to
                  dispute inaccurate or incomplete information in your credit
                  report.
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com is not responsible for the accuracy or
                  contents of your credit file, including, but not limited to,
                  information delivered in the credit report and/or score
                  products.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">29. FCRA Disclosures</h5>
                <p className="mt-20 f-16">
                  The federal Fair Credit Reporting Act (“FCRA”) allows you to
                  obtain from each consumer reporting agency a disclosure of all
                  the information in your credit file at the time of the
                  request. Full disclosure of information in your file
                  maintained by a consumer reporting agency must be obtained
                  directly from such consumer reporting agency. The credit
                  reports provided or requested through our Site are not
                  intended to constitute the disclosure of information by a
                  consumer reporting agency as required by FCRA or similar laws.
                </p>
                <p className="mt-20 f-16">
                  Under FCRA, you are entitled to receive an annual free
                  disclosure of your credit report from each of the national
                  consumer reporting agencies. To request your free annual
                  report under FCRA, you must go to www.annualcreditreport.com.
                  You can also request this free annual disclosure by calling
                  toll-free (877) 322-8228 or by using the mail request form
                  available at the website set forth above. The Credit Report
                  Card is not related to this free disclosure, and none of our
                  products are intended to substitute for any free credit report
                  or disclosure that any consumer reporting agency is required
                  by law to provide to you.
                </p>
                <p className="mt-20 f-16">
                  You are entitled to receive a free copy of your credit report
                  from a consumer reporting agency if:
                </p>
                <p className="mt-20 f-16">
                  You have been denied or were otherwise notified of adverse
                  action related to credit, insurance, employment, or a
                  government granted license or other government granted benefit
                  within the past sixty (60) days based on information in a
                  credit report provided by such agency. Your application for
                  rental housing has been denied or you were required to pay a
                  higher deposit than usually required within the past sixty
                  (60) days based on information in a credit report provided by
                  such agency.
                </p>
                <p className="mt-20 f-16">
                  You certify in writing that you are unemployed and intend to
                  apply for employment during the sixty (60) day period
                  beginning on the date on which you made such certification.
                </p>
                <p className="mt-20 f-16">
                  You certify in writing that you are a recipient of public
                  welfare assistance.
                </p>
                <p className="mt-20 f-16">
                  You certify in writing that you have reason to believe that
                  your file at such consumer reporting agency contains
                  inaccurate information due to fraud.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  30. Not a Credit Repair Organization or Contract
                </h5>
                <p className="mt-20 f-16">
                  Evergreenclass.com is not a credit repair organization or
                  similarly regulated organization under other applicable laws,
                  and is not offering to provide or perform any service to you
                  for the express or implied purpose of either improving your
                  credit record, credit history or credit rating or providing
                  advice or assistance to you with regard to improving your
                  credit record, credit history or credit rating.
                  Evergreenclass.com does not claim it is able to “clean up” or
                  “improve” your credit record, credit history, or credit
                  rating. You acknowledge and agree that you are not seeking to
                  use or access the Service in order to do so.
                </p>

                <p className="mt-20 f-16">
                  Accurate adverse information on your credit report cannot be
                  changed. If you believe that your credit report contains
                  inaccurate, non-fraudulent information, it is your
                  responsibility to contact the relevant consumer reporting
                  agency and follow the appropriate procedures for notifying the
                  consumer reporting agency that you believe that your credit
                  report contains an inaccuracy. Any information provided to you
                  regarding the procedures followed by the various consumer
                  reporting companies related to the removal of inaccurate,
                  non-fraudulent information is provided without charge to you
                  and is available for free. Any such information is not
                  included as part of Evergreenclass.com, but is provided free
                  of charge to all consumers, regardless of whether you have
                  subscribed to evergreenclass.com.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">
                  31. Enrollment in Credit Monitoring Programs
                </h5>
                <p className="mt-20 f-16">
                  There are different processing times across the various
                  consumer reporting companies. Therefore, you may not be
                  enrolled in all of their credit monitoring programs at the
                  same time. Monitoring takes approximately 4 days to begin.
                  Evergreenclass.com does not control and is not responsible for
                  the enrollment process.
                </p>
                <p className="mt-20 f-16">
                  When you order a single bureau credit monitoring product from
                  evergreenclass.com, evergreenclass.com requests that
                  TransUnion enroll you in its credit monitoring program. When
                  you order a credit monitoring product from evergreenclass.com
                  that monitors credit files at three credit bureaus,
                  evergreenclass.com requests that Experian, Equifax and
                  TransUnion enroll you in each of their respective credit
                  monitoring programs.
                </p>
                <p className="mt-20 f-16">
                  Your order of any single bureau credit monitoring product is
                  conditioned upon successful enrollment by TransUnion in its
                  credit monitoring program. If TransUnion is unable to enroll
                  you in its credit monitoring program, your order will be
                  cancelled, you will receive a refund of the amount you have
                  paid for the product, and you will not receive alerts or
                  monitoring of changes to your TransUnion credit file.
                </p>
                <p className="mt-20 f-16">
                  Your order of any credit monitoring product that monitors
                  credit files at three credit bureaus is conditioned upon
                  successful enrollment by all three credit bureaus in their
                  respective credit monitoring programs. If any credit bureau is
                  unable to enroll you in its credit monitoring program, your
                  order will be cancelled, you will receive a refund of the
                  amount you have paid for the product, and you will not receive
                  alerts or monitoring of changes to any of your credit files.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">32. International Use</h5>
                <p className="mt-20 f-16">
                  Because you can access this Site and use the Services
                  internationally, you agree to follow all local rules about the
                  Internet, data, e-mail, and privacy. Specifically, you agree
                  to follow all laws that apply to transmitting technical data
                  exported from the US or the country of your residence.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">33. Additional Terms</h5>
                <p className="mt-20 f-16">
                  Portions of the Service may be accompanied by additional terms
                  which apply to specific features or areas of the Service.
                  Those additional terms supplement these terms with respect to
                  your use of those features or areas.
                </p>
              </div>
              <div className="about_content mb-5">
                <h5 className="f-26">34. Miscellaneous</h5>
                <p className="mt-20 f-16">
                  Evergreenclass.com reserves the right to investigate
                  complaints or reported violations of the Website Terms and to
                  take any action 7evergreenclass.com deems appropriate
                  including but not limited to reporting any suspected unlawful
                  activity to law enforcement officials, regulators, or other
                  third parties and disclosing any information necessary or
                  appropriate to such persons or entities relating to user
                  profiles, e- mail addresses, usage history, posted materials,
                  IP addresses and traffic information. Evergreenclass.com
                  reserves the right to seek all remedies available at law and
                  in equity for violations of the Website Terms, including but
                  not limited to the right to block access from a particular
                  Internet address to any evergreenclass.com website. These
                  incorporate by reference any notices contained on the Website
                  and constitute the entire agreement with respect to access to
                  and use of the Website, your request that evergreenclass.com
                  contact you about its credit repair services, and/or your
                  participation in evergreenclass.com’s Text Message Program.
                  You agree that the provisions and covenants set forth herein
                  are reasonable. In the event that any provision or covenant of
                  the Website Terms shall be held invalid, illegal or
                  unenforceable by a court or arbitrator of competent
                  jurisdiction for any reason, including but not limited to the
                  scope thereof, then such provision will be severed and
                  replaced with a new provision that most closely reflects the
                  original intention thereof, and the remaining provisions of
                  the Website Terms will remain in full force and effect for the
                  greatest time period and for the broadest scope permitted by
                  applicable law. Without limiting the foregoing, you agree and
                  request that if any court or arbitrator of competent
                  jurisdiction considers any provision or covenant of the
                  Website Terms to be overly broad based on the circumstances at
                  the time enforcement is requested, that such court or
                  arbitrator construe and enforce the provision or covenant to
                  the fullest extent that such court or arbitrator deems
                  reasonable.
                </p>
                <p className="mt-20 f-16">
                  THE WEBSITE TERMS SHALL BE GOVERNED BY AND CONSTRUED IN
                  ACCORDANCE WITH THE LAWS OF THE STATE OF ARIZONA, USA, AS THEY
                  APPLY TO AGREEMENTS MADE SOLELY THEREIN, PROVIDED, HOWEVER,
                  THAT THE ARBITRATION CLAUSE IS GOVERNED BY FEDERAL LAW. The
                  Arbitration Clause, the obligations in Sections 1 through 16,
                  20 through 29 and 32 through 34 shall survive termination of
                  the Website Terms.
                </p>
                <p className="mt-20 f-16">
                  Evergreenclass.com shall not be deemed to have waived any
                  rights or remedies in the Website Terms unless such waiver is
                  in writing and signed by evergreenclass.com. No delay or
                  omission on the part of evergreenclass.com in exercising any
                  rights or remedies shall operate as a waiver of such rights or
                  remedies or any other rights or remedies. A waiver on any one
                  occasion shall not be construed as a waiver of any rights or
                  remedies on future occasions.
                </p>
                <p className="mt-20 f-16">
                  The Website Terms constitute the entire agreement and
                  understanding between you and evergreenclass.com, except as
                  you and evergreenclass.com may later agree in writing to
                  modify the Website Terms. The Website Terms supersedes any and
                  all prior agreements and understandings, oral or written,
                  relating to the subject matter contained herein.
                </p>
                <p className="mt-20 f-16">
                  YOU AGREE THAT IF YOU WANT TO SUE US, YOU MUST FILE YOUR
                  LAWSUIT WITHIN ONE YEAR AFTER THE EVENT THAT GAVE RISE TO YOUR
                  LAWSUIT. OTHERWISE, YOUR LAWSUIT WILL BE PERMANENTLY BARRED.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndCondition;

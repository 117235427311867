import React from 'react';
import Header from '../../layouts/header';
import { useSelector} from 'react-redux';
import { Link } from 'react-router-dom';


const UserProfile = () => {

    const user = useSelector((state) => state.user);
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

    return (
        <div>
            <Header />
            {isLoggedIn && user ? (
                <>
                    <div className="theme_container">

                        <h1 className="my-profile">My Profile</h1>
                        
                        <div className="head mt-60">
                            <h1 className="f18">Main Filer Info</h1>
                        </div>
                        <div className="row mt-40">
                        <div className='user-data'>
                        <b>Name</b>
                        <p>{user.user.data.firstname} {user.user.data.middleinitial} {user.user.data.lastname}</p>
                        </div>
                        <div className='user-data'>
                        <b>Last four digit of SSN</b>
                        <p>{user.user.data.ssn} </p>
                        </div>
                        <div className='user-data'>
                        <b>Phone Number</b>
                        <p> {user.user.data.phone}</p>
                        </div>
                        </div>

                        <div className="head mt-60">
                            <h1 className="f18">Contact Info</h1>
                        </div>
                        <div className="row mt-40">
                        <div className='user-data'>
                        <b>Address</b>
                        <p>{user.user.data.addr1}</p>
                        </div>
                        <div className='user-data'>
                        <b>City</b>
                        <p> {user.user.data.city} </p>
                        </div>
                        <div className='user-data'>
                        <b>State</b>
                        <p> {user.user.data.state}</p>
                        </div>
                        <div className='user-data'>
                        <b>Zip Code</b>
                        <p>{user.user.data.zip}</p>
                        </div>
                        </div>

                        <div className="head mt-60">
                            <h1 className="f18">Account Info...</h1>
                        </div>
                        <div className="row mt-40">
                        <div className='user-data'>
                        <b>Email</b>
                        <p>{user.user.data.email}</p>
                        </div>
                        <div className='user-data'>
                        <b>Username</b>
                        <p>{user.user.data.username}</p>
                        </div>
                        </div>
                        
                        <div className="btn_wrap text-center mt-40">
                            <Link className="btn_brnd-outline mr-22 mb-2" to="/dashboard">Go Back</Link>
                            <Link type='button' className="btn_brnd" data-bs-target="#Serious" to={`/userprofileupdate?id=${user.user.data.id}`}  >Edit Profile</Link>
                        </div>


                    </div>

                </>
            ) : (

                <div>
                    <h1 style={{ marginTop: "200px" }}>Please Login First</h1>
                </div>
            )}

        </div>
    );
};

export default UserProfile;
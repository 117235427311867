import React, { useEffect, useState } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import { getEvaluationFirst, searchEvaluationFirst } from "../../api/server";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = Yup.object({
  start: Yup.date().required("Start date is required"),
  end: Yup.date()
    .required("End date is required")
    .min(Yup.ref("start"), "End date must be greater than start"),
});

const EvaluationStats1stCourse = () => {
  const [response, setResponse] = useState();
  const [evaluations, setEvaluations] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [activeSearch, setActiveSearch] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const {
    handleChange,
    touched,
    values,
    errors,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: { start: "", end: "" },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFilterLoading(true);
      searchEvaluationFirst(values)
        .then((res) => {
          setActiveSearch(true);
          setEvaluations(res.data.data);
          toast.success("Evaluation search successful");
          setFilterLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setFilterLoading(false);
        });
    },
  });

  const handleClearFilters = () => {
    setActiveSearch(false);
    resetForm();
    setEvaluations(response);
    toast.success("Evaluation search cleared");
  };

  useEffect(() => {
    const fetchData = async () => {
      await getEvaluationFirst()
        .then((res) => {
          setResponse(res.data.data);
          setEvaluations(res.data.data);
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setGetLoading(false);
        });
    };
    fetchData();
  }, []);

  const questions = [
    "The goals of our course were explained clearly",
    "The course topics were relevant to my life",
    "The learning materials were helpful",
    "The course content was easy to understand",
    "The teacher was well prepared",
    "The website was easy to use",
    "The teacher was helpful",
    "I learned something I can use",
    "I will use a budget at home",
  ];

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">Evaluation Statistics for First Course</h4>
            <div className=" head  mt-60">
              <h1 className="f18">Date Range Search:</h1>
            </div>
            <div>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-5">
                  <div className="input_wrap  mt-40">
                    <label className="label-title">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter start date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="start"
                      value={values.start}
                    />
                    {errors.start && touched.start ? (
                      <p className="text-danger">{errors.start}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                  <div className="form-group    ">
                    <label className="label-title  ">To</label>
                  </div>
                </div>
                <div className="col-5">
                  <div className="input_wrap   mt-40">
                    <label className="label-title"> End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter end date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="end"
                      value={values.end}
                    />
                    {errors.end && touched.end ? (
                      <p className="text-danger">{errors.end}</p>
                    ) : null}
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn_brnd mt-4 me-3"
                    disabled={filterLoading}
                    type="submit"
                  >
                    {filterLoading ? (
                      <ThreeDots
                        height="20"
                        width="140"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      " Display Statistics"
                    )}
                  </button>
                  {activeSearch && (
                    <button
                      className="btn_brnd mt-4 me-3"
                      onClick={handleClearFilters}
                    >
                      Clear Search
                    </button>
                  )}
                </div>
              </form>
            </div>

            <div className="card  mt-60">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Question</th>
                      <th scope="col"># Yes</th>
                      <th scope="col"># No</th>
                      <th scope="col"># Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : evaluations ? (
                      questions.map((question, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{question}</th>
                            <td>
                              {evaluations[`q${index + 1}Y`]} (
                              {evaluations[`q${index + 1}YP`] || 0}%)
                            </td>
                            <td>
                              {evaluations[`q${index + 1}N`]} (
                              {evaluations[`q${index + 1}NP`] || 0}%)
                            </td>
                            <td>{evaluations[`q${index + 1}total`]}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>No evaluation statistics found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EvaluationStats1stCourse;

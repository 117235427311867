import React, { useEffect } from "react";
import Header from "../../layouts/header";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from "../../layouts/footer";
import cross from "../../assets/images/SiteImg/cross.png";
import { getStates, userSignup } from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  address1: Yup.string().required("Address Line 1 is required"),
  address2: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.number().required("Zip Code is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Your Email is required"),
  username: Yup.string()
    .matches(/^(\S+)$/, "Username should not contain spaces")
    .required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  billing: Yup.string().required("Billing is required"),
  phone: Yup.string().required("Phone Number is required"),
});

const SetUpAttorneyAccount = () => {
  const [postLoading, setPostLoading] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [attorneyName, setAttorneyName] = useState([]);
  const [forLogin, setForLogin] = useState(false);

  const [showPass, setShowPass] = useState(false);

  //////// function for handle submit the page///////
  const handleSubmit = async (values, { resetForm }) => {
    setPostLoading(true);
    await userSignup(values)
      .then((res) => {
        setAttorneyName(res.data.data.firstname);
        setPostLoading(false);
        resetForm();
        // const authToken = res?.data?.token;
        // localStorage.setItem("authToken", authToken);
        setForLogin(true);
        // dispatch(signup(res.data))
        // navigate("/dashboard");
      })
      .catch((err) => {
        setPostLoading(false);
        if (err.response && err.response.data) {
          toast.error(err.response.data.error);
          setShowModal(false);
        } else {
          toast.error("An unexpected error occurred.");
          setShowModal(false);
        }
      });
  };

  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await getStates();
        const data = response.data;
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states data:", error);
      }
    };
    fetchStatesData();
  }, []);

  /////////////////// fo handle login/////////////////
  //   const handleLogin = () => {
  //     if (forLogin) {
  //       dispatch(signup());
  //       navigate("/dashboard");
  //     }
  //   };

  return (
    <div>
      <Header />
      <div className="theme_container">
        <div className="my-profile">
          <h1 id="firstcourse">
            Attorney Account<span className="text_success"> Set-up</span>
          </h1>
          <div className="head mt-60">
            <h1 className="f18">Attorney Account Info</h1>
          </div>
        </div>

        <div className="row mt-40">
          <Formik
            initialValues={{
              type: "Atty",
              firstName: "",
              phone: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
              email: "",
              username: "",
              password: "",
              billing: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setValues, errors }) => (
              <Form>
                <div className="row mt-40">
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">
                        Attorney or Firm Name
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="Enter attorney name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">
                        Certificate Email Address
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Enter certificate email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Username</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder="Enter Username"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Choose a Password</label>
                      <Field
                        type={showPass ? "text" : "password"}
                        className="form-control"
                        name="password"
                        placeholder="Enter your Password"
                      />
                      {showPass ? (
                        <i
                          className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                          style={{ top: "20px", right: "10px" }}
                          onClick={() => {
                            setShowPass(false);
                          }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                          style={{ top: "20px", right: "10px" }}
                          onClick={() => {
                            setShowPass(true);
                          }}
                          aria-hidden="true"
                        ></i>
                      )}
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                </div>
                <div className="head mt-40">
                  <h1 className="f18">Contact Info</h1>
                </div>
                <div className="row mt-40">
                  <div className="col-lg-6 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Address Line 1</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="address1"
                        placeholder="Enter your address"
                      />
                      <ErrorMessage
                        name="address1"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Address Line 2</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="address2"
                        placeholder="Enter your address"
                      />
                      <ErrorMessage
                        name="address2"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">City</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="Enter your city"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">State</label>
                      <Field name="state">
                        {({ field }) => (
                          <select
                            {...field}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                state: e.target.value,
                              });
                            }}
                            className="form-control"
                          >
                            {statesData.length > 0 && (
                              <option value="">Select a State</option>
                            )}

                            {statesData.length > 0 ? (
                              statesData.map((state, index) => (
                                <option key={index} value={state.state}>
                                  {state.state}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                        )}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Zip Code</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="zip"
                        placeholder="Enter zip code"
                      />
                      <ErrorMessage
                        name="zip"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Phone Number</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Enter your phone no."
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title" htmlFor="securedLoanType">
                        Billing Options
                      </label>
                      <Field
                        as="select"
                        id="securedLoanType"
                        name="billing"
                        className="form-control custom_select"
                      >
                        <option value="">Select</option>
                        <option value="client">Client Pay</option>
                        <option value="bill">Attorney Pay</option>
                      </Field>
                      <ErrorMessage
                        name="billing"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                </div>
                <div className="btn_wrap text-center mt-40">
                  <button
                    type="submit"
                    className="btn_brnd"
                    disabled={postLoading}
                    onClick={() => {
                      if (Object.keys(errors).length > 0) {
                        toast.error("Form validation failed");
                      }
                    }}
                  >
                    {postLoading ? (
                      <ThreeDots
                        height="20"
                        width="125"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Create Account"
                    )}
                  </button>
                </div>

                {/*1st custom Modal */}
                <div>
                  <div
                    className={`modal ${
                      forLogin ? "show" : ""
                    } fade custom_modal `}
                    aria-modal="true"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: forLogin ? "block" : "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content pb-60">
                        <button
                          type="button"
                          className="btn_close"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setForLogin(false);
                          }}
                        >
                          <img src={cross} alt="cross" className="cross_btn" />
                        </button>
                        <div className="modal-body">
                          <div className="modal_content">
                            <h1 className="f-26 text-center">
                              Attorney Account
                              <span className="text_success"> Set-up</span>{" "}
                            </h1>
                            <p className="f-16 text-center mt-12 fw-700">
                              An account has been set up for {attorneyName}.
                            </p>

                            <div className="btn_wrap text-center mt-40">
                              <button
                                className="btn_brnd-outline mr-22 mb-2"
                                type="button"
                                onClick={() => {
                                  setForLogin(false);
                                }}
                              >
                                Go Back
                              </button>
                              {/* <Link className="btn btn_brnd" onClick={() => handleSubmit(values)}><button data-bs-dismiss="modal"></button>Confirm & Continue</Link>  */}
                              {/* <Link
                                onClick={() => {
                                  handleLogin();
                                }}
                              >
                                <button
                                  data-bs-dismiss="modal"
                                  className="btn_brnd"
                                >
                                  Login to My Account
                                </button>
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SetUpAttorneyAccount;

import React, { useState, useEffect } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import { toast } from "react-toastify";
import {
  getAllCCBooklet,
  getAllDEBooklet,
  updateBooklet,
} from "../../api/server";
import PaginatedItems from "./pagination";
import { ThreeDots } from "react-loader-spinner";

const Booklets = () => {
  const [getloadingCC, setGetLoadingCC] = useState(true);
  const [updateLoadingCC, setUpdateLoadingCC] = useState(false);
  const [toBeUpdatedIdsCC, setToBeUpdatedIdsCC] = useState([]);
  const [responseCC, setResponseCC] = useState([]);
  const [pageCacheCC, setPageCacheCC] = useState({});
  const [pageNoCC, setPageNoCC] = useState(1);
  const [totalCC, setTotalCC] = useState();

  const [getloadingDE, setGetLoadingDE] = useState(true);
  const [updateLoadingDE, setUpdateLoadingDE] = useState(false);
  const [toBeUpdatedIdsDE, setToBeUpdatedIdsDE] = useState([]);
  const [responseDE, setResponseDE] = useState([]);
  const [pageCacheDE, setPageCacheDE] = useState({});
  const [pageNoDE, setPageNoDE] = useState(1);
  const [totalDE, setTotalDE] = useState();

  const handleUpdateCC = () => {
    setUpdateLoadingCC(true);
    updateBooklet(
      { id: toBeUpdatedIdsCC },
      responseCC.length > 1
        ? pageNoCC
        : pageNoCC - 1 > 0
        ? pageNoCC - 1
        : pageNoCC
    )
      .then((res) => {
        const newData = res.data.pendingBilling;
        const newTotal = res.data.total;
        setResponseCC(newData);
        setTotalCC(newTotal);
        setPageNoCC(responseCC.length > 1 ? pageNoCC : pageNoCC - 1);
        setPageCacheCC({
          [pageNoCC]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        toast.success(res.data.message);
        setToBeUpdatedIdsCC([]);
        setUpdateLoadingCC(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setUpdateLoadingCC(false);
      });
  };
  const handleUpdateDE = () => {
    setUpdateLoadingDE(true);
    updateBooklet(
      { id: toBeUpdatedIdsDE },
      responseDE.length > 1
        ? pageNoDE
        : pageNoDE - 1 > 0
        ? pageNoDE - 1
        : pageNoDE
    )
      .then((res) => {
        const newData = res.data.pendingBilling;
        const newTotal = res.data.total;
        setResponseDE(newData);
        setTotalDE(newTotal);
        setPageNoDE(responseDE.length > 1 ? pageNoDE : pageNoDE - 1);
        setPageCacheDE({
          [pageNoDE]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        toast.success(res.data.message);
        setToBeUpdatedIdsDE([]);
        setUpdateLoadingDE(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setUpdateLoadingDE(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (pageCacheCC[pageNoCC]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCacheCC[pageNoCC];
        setResponseCC(cachedResponse);
        setTotalCC(cachedTotal);
      } else {
        setGetLoadingCC(true);
        await getAllCCBooklet(pageNoCC)
          .then((res) => {
            const newData = res.data.data.result;
            const newTotal = res.data.data.total;

            // Update the cache with new data
            setPageCacheCC((prevCache) => ({
              ...prevCache,
              [pageNoCC]: {
                cachedResponse: newData,
                cachedTotal: newTotal,
              },
            }));

            // Set state with new data
            setResponseCC(newData);
            setTotalCC(newTotal);
            setGetLoadingCC(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoadingCC(false);
          });
      }
    };

    fetchData();
  }, [pageNoCC, pageCacheCC]);

  useEffect(() => {
    const fetchData = async () => {
      if (pageCacheDE[pageNoDE]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCacheDE[pageNoDE];
        setResponseDE(cachedResponse);
        setTotalDE(cachedTotal);
      } else {
        setGetLoadingDE(true);
        await getAllDEBooklet(pageNoDE)
          .then((res) => {
            const newData = res.data.data.result;
            const newTotal = res.data.data.total;

            // Update the cache with new data
            setPageCacheDE((prevCache) => ({
              ...prevCache,
              [pageNoDE]: { cachedResponse: newData, cachedTotal: newTotal },
            }));

            // Set state with new data
            setResponseDE(newData);
            setTotalDE(newTotal);
            setGetLoadingDE(false);
          })
          .catch((err) => {
            toast.error(err.response?.data.error);
            setGetLoadingDE(false);
          });
      }
    };

    fetchData();
  }, [pageNoDE, pageCacheDE]);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">CC Certificates To Mail</h4>

            <div className="card  mt-20">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Address</th>
                      <th scope="col">Shipped</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloadingCC ? (
                      <tr>
                        <td colSpan={3}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : responseCC && responseCC.length > 0 ? (
                      responseCC.map((follow, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {follow.firstname} {follow.lastname}
                          </th>
                          <td>{follow.addr1}</td>
                          <td>
                            <div className="iagree_radio">
                              <input
                                type="checkbox"
                                name="tickbox"
                                id={`check${index}`}
                                className=" "
                                checked={
                                  toBeUpdatedIdsCC.includes(follow.id)
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  e.target.checked
                                    ? setToBeUpdatedIdsCC([
                                        follow.id,
                                        ...toBeUpdatedIdsCC,
                                      ])
                                    : setToBeUpdatedIdsCC(
                                        toBeUpdatedIdsCC.filter(
                                          (item) => item !== follow.id
                                        )
                                      );
                                }}
                              />
                              <label
                                for={`check${index}`}
                                className="text-white"
                              >
                                B
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>No certificate found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!getloadingCC && responseCC && responseCC.length > 0 && (
                  <div className="text-center">
                    <button
                      className="btn_brnd mt-4 me-3"
                      disabled={
                        updateLoadingCC || toBeUpdatedIdsCC.length === 0
                      }
                      onClick={handleUpdateCC}
                    >
                      {updateLoadingCC ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                )}
                {!getloadingCC && totalCC !== 0 && totalCC > 10 && (
                  <PaginatedItems
                    total={totalCC}
                    pageNo={pageNoCC}
                    setPageNo={setPageNoCC}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="manage-students language_wrap">
            <h4 className="f26 ">DE Certificates To Mail</h4>

            <div className="card  mt-20">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Address</th>
                      <th scope="col">Shipped</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloadingDE ? (
                      <tr>
                        <td colSpan={3}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : responseDE && responseDE.length > 0 ? (
                      responseDE.map((follow, index) => (
                        <tr key={index}>
                          <th scope="row">
                            {follow.firstname} {follow.lastname}
                          </th>
                          <td>{follow.addr1}</td>
                          <td>
                            <div className="iagree_radio">
                              <input
                                type="checkbox"
                                name="tickbox"
                                id={`check${index}`}
                                className=" "
                                checked={
                                  toBeUpdatedIdsDE.includes(follow.id)
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  e.target.checked
                                    ? setToBeUpdatedIdsDE([
                                        follow.id,
                                        ...toBeUpdatedIdsDE,
                                      ])
                                    : setToBeUpdatedIdsDE(
                                        toBeUpdatedIdsDE.filter(
                                          (item) => item !== follow.id
                                        )
                                      );
                                }}
                              />
                              <label
                                for={`check${index}`}
                                className="text-white"
                              >
                                B
                              </label>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>No certificate found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!getloadingDE && responseDE && responseDE.length > 0 && (
                  <div className="text-center">
                    <button
                      className="btn_brnd mt-4 me-3"
                      disabled={
                        updateLoadingDE || toBeUpdatedIdsDE.length === 0
                      }
                      onClick={handleUpdateDE}
                    >
                      {updateLoadingDE ? (
                        <ThreeDots
                          height="20"
                          width="60"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                )}
                {!getloadingDE && totalDE !== 0 && totalDE > 10 && (
                  <PaginatedItems
                    total={totalDE}
                    pageNo={pageNoDE}
                    setPageNo={setPageNoDE}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Booklets;

import React from 'react';
import Header from '../../layouts/header';
import error from '../../assets/images/Error.png'
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div>
            <Header/>
            <div className="theme_container">
            <div className="my-profile">
            <div className='error-wrap'>
            <img src={error} alt="404 Error" />
            </div>
            <div style={{textAlign:"center"}}>
            <h1 className="f-52" >Page Not <span>Found</span></h1>
           <Link  to={'/'}> <button className="btn_brnd mt-4 me-3  login_btn" >Go Back to Homepage</button></Link>
           </div>
           </div>
        </div>
        </div>
    );
};

export default PageNotFound;
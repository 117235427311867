import React from "react";
import img_1 from "../../assets/images/SiteImg/img_1.png";
import img_2 from "../../assets/images/SiteImg/img_2.png";

import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
const About = () => {
  return (
    <div>
      <Header />
      <section className="about_us_page mt-250">
        <div className="theme_container">
          <h1 className="f-52 text-center">
            Who is <span>Evergreen?</span>
          </h1>
          <div className="row   mt-40 mt_one align-items-center">
            <div className="col-lg-12">
              <div className="about_content">
                <h5 className="f-26">EVERGREEN'S MISSION</h5>
                <p className="mt-20 f-16">
                  <b>Evergreen Financial Counseling</b> seeks to empower and
                  uplift individuals through quality financial counseling and
                  education.
                </p>
              </div>
            </div>
          </div>
          <div className="row   mt-40  align-items-center row_rev">
            <div className="col-lg-12">
              <div className="about_content">
                <h5 className="f-26">OUR VISION</h5>
                <p className="mt-20 f-16">
                  <b>At times it appears as if everything has a price tag.</b>
                  <br />
                  However, we believe each person is priceless, regardless of
                  how much money they have or owe. By teaching true worth, in
                  addition to providing non-judgemental counseling and financial
                  education, we can support individuals in their journey toward
                  personal growth and financial empowerment.
                </p>
              </div>
            </div>
          </div>
          <div className="row   mt-40  align-items-center">
            <div className="col-lg-12">
              <div className="about_content">
                <h5 className="f-26">U.S. TRUSTEE APPROVED*</h5>
                <p className="mt-20 f-16">
                  Approved by the U.S. Trustees Office in all jurisdictions
                  except for North Carolina and Alabama. Approved to issue
                  certificates evidencing completion of credit counseling and
                  personal financial management courses in compliance with the
                  Bankruptcy Code. Approval does not endorse or assure the
                  quality of a Provider's services.
                </p>
              </div>
            </div>
          </div>
          <div className="row  align-items-center row_rev">
            <div className="col-lg-12">
              <div className="about_content">
                <p className="mt-20 f-16">
                  Evergreen Financial Counseling
                  <br />
                  7137 E. Rancho Vista Drive, Suite B21
                  <br />
                  Scottsdale, AZ 85251
                  <br />
                  800-581-3513*
                  <br />
                  info@evergreenclass.com
                  <br />* HI and AK please email a Counselor
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;

import React from "react";
import logo from "../../assets/images/logo.png";
import Footer from "../../layouts/footer";
const PaymentPage = () => {
  return (
    <div>
      <header className="header header_wrap header_wrap_two">
        <div className="theme_container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="#">
              {" "}
              <img src={logo} alt="logo" />{" "}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="assets/images/SiteImg/navbar.svg" alt="navbar" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto justify-content-center mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link active mr-80"
                    aria-current="page"
                    href="Attorneys.html"
                  >
                    Attorneys
                  </a>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link mr-80" href="About.html">
                    About Us
                  </a>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="Faq.html">
                    FAQ
                  </a>{" "}
                </li>
              </ul>
              <form className="d-flex">
                <button className="btn btn-outline-light mr-22" type="submit">
                Sign Up
                </button>
                <button className="btn btn-light" type="submit">
                  Login
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

      <section className="breadcrumb-section mt-250">
        <div className="theme_container">
          <h1 className="f-52 text-center">
            fill out all the <span className="">information below</span>
          </h1>

          <div className=" head  mt-40">
            <h1 className="f18">Payment Method</h1>
          </div>
          <div className="row mt-40">
            <div className="col-lg-6 col-md-6">
              <div className="input_wrap ">
                <label className="label-title">Payment Method</label>
                <select className="form-control custom_select">
                  <option>Select</option>
                  <option>1</option>
                  <option>1</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Credit Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter credit card number"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Expiration Date</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter expiration date"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Cardholder First Name</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Cardholder first name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Cardholder Last Name</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Cardholder Last Name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">
                  Cardholder Billing address
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter billing adderss"
                />{" "}
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className=" input_wrap ">
                <label className="label-title">CVV Code</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter billing adderss"
                />{" "}
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className=" input_wrap mt-3 ">
                <a href="" className="text_success ">
                  <u>What is This?</u>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Cardholder City</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="City"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Cardholder State</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="State"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Cardholder Zip Code</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Zip code"
                />{" "}
              </div>
            </div>
            <div className=" head  mt-40">
              <h1 className="f18">Verify the following Info...</h1>
            </div>
            <div className="col-lg-6 col-md-6 mt-40">
              <p className="f-16">
                <b>Note:</b> Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 mt-40">
              <div className="price_total">
                <h1 className="f-18 text-center">Summary</h1>

                <p className="f-16 fw-700">
                  Course Fee <span className="fw-500">$20.00</span>
                </p>
                <p className="f-16 fw-700">
                  Tax <span className="fw-500">$00.00</span>
                </p>
                <p className="f-16 fw-700 total_box">
                  Total <span className="fw-500">$20.00</span>
                </p>
              </div>
            </div>

            <div className="col-12">
              <div className="btn_wrap mt-60 text-center">
                <button className="btn_brnd-outline" type="button">Go Back</button>
                <button className="btn_brnd">Confirm & Continue</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PaymentPage;

import React, { useState, useEffect } from "react";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import PaginatedItems from "./pagination";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addAttorneyContact,
  deleteAttorneyContact,
  getAttorneyContact,
} from "../../api/server";
import { ThreeDots } from "react-loader-spinner";
import DeleteModal from "../../common/deleteModal";

const validationSchema = Yup.object({
  atty_name: Yup.string().required("Name is required"),
  attyemail: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
const AttorneyEmailAddress = () => {
  const [deleteAttorney, setDeleteAttorney] = useState("");
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pageCache, setPageCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();

  const { handleChange, touched, values, errors, handleSubmit, handleBlur } =
    useFormik({
      initialValues: { atty_name: "", attyemail: "" },
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setPostLoading(true);
        addAttorneyContact(values)
          .then((res) => {
            let updatedResponse = response;
            if (response.length > 9) {
              updatedResponse = response.slice(0, response.length - 1);
            }
            const newData = [res.data.data, ...updatedResponse];
            const newTotal = total + 1;
            setResponse(newData);
            setTotal(newTotal);
            setPageCache({
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            });
            toast.success("Attorney contact added successfully");
            setPostLoading(false);
            resetForm();
          })
          .catch((err) => {
            toast.error(err.response.data.error);
            setPostLoading(false);
          });
      },
    });

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);
        try {
          const res = await getAttorneyContact(pageNo);
          const newData = res.data.data.result;
          const newTotal = res.data.data.total;

          // Update the cache with new data
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          // Set state with new data
          setResponse(newData);
          setTotal(newTotal);
        } catch (err) {
          toast.error(err.response.data.error);
        } finally {
          setGetLoading(false);
        }
      }
    };

    fetchData();
  }, [pageNo, pageCache]);

  const handleDeleteContact = async (id) => {
    setDeleteLoading(true);
    await deleteAttorneyContact(id, response.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo)
      .then((res) => {
        const newData = res.data.data.result;
        const newTotal = res.data.data.total;
        setResponse(newData);
        setTotal(newTotal);
        setPageNo(response.length > 1 ? pageNo : pageNo - 1);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        setDeleteAttorney("");
        toast.success("Attorney contact deleted successfully");
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteAttorney("");
        toast.error(err.response.data.error);
        setDeleteLoading(false);
      });
  };
  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <DeleteModal
            loading={deleteLoading}
            showModal={deleteAttorney}
            setDelete={setDeleteAttorney}
            handleSubmit={() => {
              handleDeleteContact(deleteAttorney);
            }}
          />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">Attorney Email Adresses</h4>
            <div className="head mt-60">
              <h1 className="f18">Add More</h1>
            </div>
            <form className="row mt-60" onSubmit={handleSubmit}>
              <div className="col-lg-4">
                <div className=" input_wrap ">
                  <label className="label-title">Attorney Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="atty_name"
                    value={values.atty_name}
                  />
                  {errors.atty_name && touched.atty_name ? (
                    <p className="text-danger">{errors.atty_name}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4">
                <div className=" input_wrap ">
                  <label className="label-title">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="attyemail"
                    value={values.attyemail}
                  />
                  {errors.attyemail && touched.attyemail ? (
                    <p className="text-danger">{errors.attyemail}</p>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-4">
                <div className="text-center">
                  <button
                    className="btn_brnd my-3"
                    type="submit"
                    disabled={postLoading}
                  >
                    {postLoading ? (
                      <ThreeDots
                        height="20"
                        width="60"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </form>

            <div className="card">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      {/* <th scope="col">Code</th> */}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={4}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      response.map((attorney, index) => (
                        <tr>
                          <th scope="row" style={{ textTransform: "none" }}>
                            {attorney.atty_name}
                          </th>
                          <td style={{ textTransform: "none" }}>
                            {attorney.attyemail}
                          </td>
                          {/* <td>{attorney.code}</td> */}
                          <td>
                            <div className="action-btns">
                              <button
                                className="btn-delete"
                                onClick={() => {
                                  setDeleteAttorney(attorney.id);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No attorney contact found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!getloading && total !== 0 && total > 50 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AttorneyEmailAddress;

import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import {
  BudgetAnalysisSecond,
  getBudgetAnalysisSecond,
} from "../../api/server";
import Header from "../../layouts/header";

const validationSchema = Yup.object().shape({
  home: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  other_home: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  car: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  jewelry: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  household_items: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  retirement_accounts: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  stocks: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  bonds: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  insurance: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  mutual_funds: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  checking_savings: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  cash: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  other: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
});

const validationSchema2 = Yup.object().shape({
  mortgage: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  auto_loans: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  student_loans: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
  other_loans: Yup.number()
    .required("Field is required")
    .moreThan(-1, "Can't be negative"),
});

const NetWorthStepper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const from = queryParams.get("from");
  const user = useSelector((state) => state.user);
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({
    home: 0,
    other_home: 0,
    car: 0,
    jewelry: 0,
    household_items: 0,
    retirement_accounts: 0,
    stocks: 0,
    bonds: 0,
    insurance: 0,
    mutual_funds: 0,
    checking_savings: 0,
    cash: 0,
    other: 0,
    mortgage: 0,
    auto_loans: 0,
    student_loans: 0,
    other_loans: 0,
  });
  const [stats, setStats] = useState();
  const [apiDataAvailable, setApiDataAvailable] = useState(true);
  const [getloading, setGetLoading] = useState(true);

  const handleNextStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (currentStep !== 2) {
      setCurrentStep((prev) => prev + 1);
    } else {
    }
  };

  const handleprevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  function parseObjectValuesToNumber(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "string") {
          if (obj[key] === "") {
            obj[key] = 0;
          } else if (!isNaN(parseFloat(obj[key]))) {
            obj[key] = parseFloat(obj[key]);
          }
        }
      }
    }
  }

  useEffect(() => {
    getBudgetAnalysisSecond(
      userId ? userId : user.user.data.id,
      userId ? "admin" : "user"
    )
      .then((res) => {
        const responseData = res.data.data.netWorth;
        setGetLoading(false);
        setApiDataAvailable(true);
        if (Object.keys(responseData).length > 0) {
          parseObjectValuesToNumber(responseData);
          setData(responseData);
        }
      })
      .catch((error) => {
        setGetLoading(false);
      });
  }, []);

  const Assets = ({ next, data }) => {
    return (
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          next(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Home
                </label>
                <Field type="number" name="home" className="form-control" />
                <ErrorMessage
                  name="home"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Other Homes/Real Estate
                </label>
                <Field
                  type="number"
                  name="other_home"
                  className="form-control"
                />
                <ErrorMessage
                  name="other_home"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Cars and Vehicles
                </label>
                <Field type="number" name="car" className="form-control" />
                <ErrorMessage
                  name="car"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Jewelry
                </label>
                <Field type="number" name="jewelry" className="form-control" />
                <ErrorMessage
                  name="jewelry"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Household Items
                </label>
                <Field
                  type="number"
                  name="household_items"
                  className="form-control"
                />
                <ErrorMessage
                  name="household_items"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Retirement Accounts
                </label>
                <Field
                  type="number"
                  name="retirement_accounts"
                  className="form-control"
                />
                <ErrorMessage
                  name="retirement_accounts"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Stocks
                </label>
                <Field type="number" name="stocks" className="form-control" />
                <ErrorMessage
                  name="stocks"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Bonds
                </label>
                <Field type="number" name="bonds" className="form-control" />
                <ErrorMessage
                  name="bonds"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Face Value of Insurance
                </label>
                <Field
                  type="number"
                  name="insurance"
                  className="form-control"
                />
                <ErrorMessage
                  name="insurance"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Mutual Funds
                </label>
                <Field
                  type="number"
                  name="mutual_funds"
                  className="form-control"
                />
                <ErrorMessage
                  name="mutual_funds"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Checking and Savings
                </label>
                <Field
                  type="number"
                  name="checking_savings"
                  className="form-control"
                />
                <ErrorMessage
                  name="checking_savings"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Cash
                </label>
                <Field type="number" name="cash" className="form-control" />
                <ErrorMessage
                  name="cash"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Others
                </label>
                <Field type="number" name="other" className="form-control" />
                <ErrorMessage
                  name="other"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button type="submit" className="btn_brnd">
              Continue
            </button>
          </div>
        </Form>
      </Formik>
    );
  };
  const Liabilities = ({ next, data, prev }) => {
    const [postLoading, setPostLoading] = useState(false);
    const handleSubmit = async (values) => {
      setPostLoading(true);
      await BudgetAnalysisSecond(
        { netWorth: values },
        userId ? "admin" : "user",
        userId ? userId : user.user.data.id
      )
        .then((res) => {
          setPostLoading(false);
          setStats(res.data.networthSummaryRecord);
          next(values);
        })
        .catch((err) => {
          setPostLoading(false);
        });
    };
    return (
      <Formik
        initialValues={data}
        validationSchema={validationSchema2}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Mortgages
                </label>
                <Field type="number" name="mortgage" className="form-control" />
                <ErrorMessage
                  name="mortgage"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Car and Vehicle Loans
                </label>
                <Field
                  type="number"
                  name="auto_loans"
                  className="form-control"
                />
                <ErrorMessage
                  name="auto_loans"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Student Loans
                </label>
                <Field
                  type="number"
                  name="student_loans"
                  className="form-control"
                />
                <ErrorMessage
                  name="student_loans"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Other Loans
                </label>
                <Field
                  type="number"
                  name="other_loans"
                  className="form-control"
                />
                <ErrorMessage
                  name="other_loans"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button
              className="btn_brnd-outline mr-22"
              onClick={() => {
                prev(data);
              }}
              type="button"
              disabled={postLoading}
            >
              Go Back
            </button>

            <button type="submit" disabled={postLoading} className="btn_brnd">
              {postLoading ? (
                <ThreeDots
                  height="20"
                  width="75"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    display: "block",
                  }}
                  wrapperClassName="spinner"
                  visible={true}
                />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </Form>
      </Formik>
    );
  };

  const NetWorth = ({ next, data, prev }) => {
    return (
      <div className="netWorth">
        <div className="card-body have-border">
          <h6 style={{ fontWeight: "700" }}>Assess Your Assets</h6>
          <div className="row">
            <div className="col-lg-9">
              <p className="mb-0">
                The value of your assets is always changing. Some things
                appreciate or go up in value and some things depreciate - even
                cash goes down in value due to inflation. It's a good idea to
                think about the value of your assets periodically to see plan
                how they will help you down the road. Remember that an asset is
                something that can provide value now and in the future.
              </p>
            </div>
            <div className="col-lg-3 text-lg-end" style={{ minWidth: "150px" }}>
              <button className="grade-btn btn_brnd ">
                <span>${stats.assets}</span>
                <p>Your total assets</p>
              </button>
            </div>
          </div>
        </div>
        <div className="card-body have-border">
          <h6 style={{ fontWeight: "700" }}>Limit Your Liabilities</h6>
          <div className="row">
            <div className="col-lg-9">
              <p className="mb-0">
                Pay off your liabilities in the shortest time that is humanly
                possible. Be agressive and creative! Liabilities that are too
                high will make you feel trapped and reduce your choices for the
                future. Pay off the ones that are the easiest first and work
                from there.
              </p>
            </div>
            <div className="col-lg-3 text-lg-end">
              <button className="grade-btn btn_brnd ">
                <span>${stats.liabilities}</span>
                <p>Your total liabilities</p>
              </button>
            </div>
          </div>
        </div>
        <div className="card-body have-border">
          <h6 style={{ fontWeight: "700" }}>Nurture Your Net Worth</h6>
          <div className="row">
            <div className="col-lg-9">
              <p className="mb-0">
                Your net worth does not define your value as a person, but a
                little planning now can give you more freedom in the future.
                Nothing will increase your net worth more than paying down your
                liabilities and then putting additional funds in intrest earning
                investments. By doing this, compound intrest will go from being
                your worst enemy to your best friend.
              </p>
            </div>
            <div className="col-lg-3 text-lg-end">
              <button className="grade-btn btn_brnd ">
                <span>${stats.netWorth}</span>
                <p>Your total net worth</p>
              </button>
            </div>
          </div>
        </div>
        <div className="btn_wrap text-center">
          <button
            className="btn_brnd-outline mr-22"
            type="button"
            onClick={() => {
              prev(data);
            }}
          >
            Go Back
          </button>

          <button
            onClick={() => {
              if (from) {
                navigate("/dashboard");
              } else {
                if (userId) {
                  navigate(`/user-second-course?id=${userId}`);
                } else {
                  navigate("/2nd-course");
                }
              }
            }}
            className="btn_brnd"
          >
            Continue
          </button>
        </div>
      </div>
    );
  };

  const steps = [
    <Assets next={handleNextStep} data={data} />,
    <Liabilities next={handleNextStep} data={data} prev={handleprevStep} />,
    <NetWorth next={handleNextStep} data={data} prev={handleprevStep} />,
  ];
  return (
    <div className="step_form w-75 mx-auto">
      <Header />
      <div className="pt-4 mt-60">
        <h4 className="f-26 mb-4">Pre-Filing Credit Counseling </h4>
        <div class="box_text ">
          <p class="f-16">
            Take a moment to think about the value of things you have. An asset
            is anything that value now and in the future. The value of an item
            is how much it would sell for in the current market, how much does
            it appraises for, or what it's face value is. Please enter your best
            estimates for the value of your assets below.
          </p>
        </div>
        <div className="d-md-flex mt-2 mb-4">
          <div className="head d-flex align-content-center ps-4 ms-md-2 mt-2 mt-ms-0">
            <span
              className="mb-0"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                backgroundColor: currentStep !== 0 ? "#E0E0E04D" : "",
                color: currentStep !== 0 ? "#0C19224D" : "",
              }}
            >
              Enter your Assets
            </span>
          </div>
          <div className="head d-flex align-content-center ps-4 ms-md-2 mt-2 mt-ms-0">
            <span
              className="mb-0"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                backgroundColor: currentStep !== 1 ? "#E0E0E04D" : "",
                color: currentStep !== 1 ? "#0C19224D" : "",
              }}
            >
              Enter your Liabilities
            </span>
          </div>
          <div className="head d-flex align-content-center ps-4 ms-md-2 mt-2 mt-ms-0">
            <span
              className="mb-0"
              style={{
                fontSize: "15px",
                fontWeight: "700",
                backgroundColor: currentStep !== 2 ? "#E0E0E04D" : "",
                color: currentStep !== 2 ? "#0C19224D" : "",
              }}
            >
              Your Net Worth is?
            </span>
          </div>
        </div>
        {getloading ? (
          <div style={{ marginTop: "50px" }}>
            <ThreeDots
              height="40"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              wrapperClassName="spinner"
              visible={true}
            />
          </div>
        ) : (
          apiDataAvailable && steps[currentStep]
        )}
      </div>
    </div>
  );
};

export default NetWorthStepper;

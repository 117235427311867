import React, { useState } from "react";

import logo from "../../assets/images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword } from "../../api/server";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [postLoading, setPostLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Your Email is required"),
  });
  const {
    handleChange,
    touched,
    values,
    resetForm,
    errors,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setPostLoading(true);
      forgotPassword(values)
        .then((res) => {
          setPostLoading(false);
          if (res.status === 200) {
            resetForm();
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          setPostLoading(false);
          if (err.response && err.response.data && err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    },
  });
  return (
    <div>
      <section className="login_wrap">
        <div className="info_login_content">
          <div className="content_login">
            <h1>Your path <span style={{textTransform:'lowercase'}}>to</span> financial recovery!</h1>
          </div>
        </div>
        <div className="login_form w-100">
          <div
            className="content_login_form w-100"
            style={{ maxWidth: "500px" }}
          >
            <div className="login_logo">
              <Link to={`/`}>
                <img src={logo} alt="logo" />
              </Link>
              <p className="f18">Please enter your Email</p>
            </div>

            <div className="mt-60">
              <form onSubmit={handleSubmit}>
                <div className=" input_wrap ">
                  <label className="label-title">Email</label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    value={values.email}
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                  {errors.email && touched.email ? (
                    <p className="text-danger">{errors.email}</p>
                  ) : null}
                </div>

                <button
                  className="btn_brnd mt-4 me-3  login_btn"
                  type="submit"
                  disabled={postLoading}
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="60"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import Header from "../../layouts/header";
import address from "../../assets/images/SiteImg/icon/address.svg";
import phone from "../../assets/images/SiteImg/icon/phon.svg";
import email from "../../assets/images/SiteImg/icon/email.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { sendContactQuery } from "../../api/server";
import { useState } from "react";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    await sendContactQuery(values)
      .then((res) => {
        toast.success("Form submitted successfully");
        resetForm();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="main-wrap">
        <div className="contact-wrap">
          <div className="sub-wrap">
            <h1>Talk With Us!</h1>
            <div className="para-wrap">
              <p>
                <img src={address} />
                Evergreen Financial Counseling
                <br />
                7137 E. Rancho Vista Drive, Suite B21
                <br />
                Scottsdale, AZ 85251
              </p>
              <p>
                <img src={phone} />
                800-581-3513
              </p>
              <p>
                <img src={email} />
                info@evergreenclass.com
              </p>
            </div>
          </div>
        </div>
        <div className="contact-wrap2">
          <div className="align-content">
            <div className="header-wrap">
              <h1>Contact US</h1>
            </div>
            <div className="field-wrap mt-60">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="input_wrap">
                      <label className="label-title">Name</label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your username or email"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                    <div className="input_wrap mt-40">
                      <label className="label-title">Email</label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                    <div className="input_wrap mt-40">
                      <label className="label-title">Subject</label>
                      <Field
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Enter subject"
                      />
                      <ErrorMessage
                        name="subject"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                    <div className="input_wrap mt-40">
                      <label className="label-title">Message</label>
                      <Field
                        as="textarea"
                        name="message"
                        className="form-control"
                        placeholder="Enter your message"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                    <div className="submit1">
                      <button
                        disabled={loading}
                        className="btn_brnd"
                        type="submit"
                      >
                        {loading ? (
                          <ThreeDots
                            height="20"
                            width="148"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "block",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <p className="mt-4">
                For information on how to use the Telecommunication Relay
                Service if you are hearing impaired{" "}
                <a
                  href="https://www.fcc.gov/consumers/guides/711-telecommunications-relay-service"
                  target="_blank"
                  style={{ color: "blue" }}
                >
                  click here
                </a>
                .{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

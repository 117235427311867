import React from "react";
import star from "../../assets/images/SiteImg/star.png";
import img1 from "../../assets/images/SiteImg/img1.png";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import { useSelector } from "react-redux";
const DashboardSuccess = () => {
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  return (
    <div>
      <Header />
      return (
      <section className=" ">
        <div className="theme_container">
          <div className="success_box">
            {isLoggedIn && user && (
              <p className="f-16">
                Success! Thank{" "}
                <span className="text_success">
                  “{" "}
                  {localStorage.getItem("isJoint") === "true"
                    ? user?.user?.data?.joint_firstname === "" &&
                      user?.user?.data?.joint_lastname === ""
                      ? user?.user?.data?.firstname +
                        " " +
                        user?.user?.data?.lastname +
                        "'s Joint"
                      : user?.user?.data?.joint_firstname +
                        " " +
                        user?.user?.data?.joint_lastname +
                        " (Joint)"
                    : user?.user?.data?.firstname +
                      " " +
                      user?.user?.data?.lastname}
                  ”
                </span>{" "}
                you for verifying your information
              </p>
            )}
          </div>
          <h1 className="f-26">My Courses</h1>
          <div className="media_object">
            <div className="row">
              <div className="col-md-3">
                <div className="media_object_img">
                  <img src={img1} alt="img1" />
                </div>
              </div>
              <div className="col-md-9">
                <h1 className="f-26">Pre-Filing Credit Counseling</h1>
                <h5 className="f-18 text_success">Evergreenclass</h5>
                <p className="f-16">
                  Take our fast and informative US Trustee approved* Credit
                  Counseling class and we'll send your required certificate of
                  completion straight to both you and your attorney!
                </p>

                <div className="row  mt-40">
                  <div className="col-md-9">
                    <div className="progress_wrap">
                      <label className="f-18 f-700 mb-2">Progress: 60%</label>
                      <div className="progress">
                        <div
                          className="progress-bar w-75"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <p className="f-16 mt-12">8 Classes are left</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="progress_btn">
                      <p className="f-16 mb-0">Class 10 of 18</p>
                      <button className="btn btn_brnd" type="submit">
                        Resume
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="media_object">
            <div className="row">
              <div className="col-md-3">
                <div className="media_object_img">
                  <img src={img1} alt="img1" />
                </div>
              </div>
              <div className="col-md-9">
                <h1 className="f-26">Post Bankruptcy debtor education</h1>
                <h5 className="f-18 text_success">Evergreenclass</h5>
                <p className="f-16">
                  <img src={star} alt="star" className="img-fluid w-120" />{" "}
                  <span className="text_lit">(20222 ratings)</span> 121554
                  students
                </p>
                <p className="f-16">
                  It's time to take your required post-bankruptcy debtor
                  education class! As usual, your certificate of completion is
                  sent to both you and your attorney.
                </p>
                <button className="btn btn_brnd mt-20" type="submit">
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      );
      <Footer />
    </div>
  );
};

export default DashboardSuccess;

import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import tick_white from "../../assets/images/tick_white.png";
import {
  BudgetAnalysisSecond,
  getBudgetAnalysisSecond,
  postBudgetAnalysisSecond,
  quizSecond,
} from "../../api/server";
import Header from "../../layouts/header";

const SpendingPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const from = queryParams.get("from");
  const user = useSelector((state) => state.user);
  const [currentStep, setCurrentStep] = useState(0);
  const [apiDataAvailable, setApiDataAvailable] = useState(true);
  const [getloading, setGetLoading] = useState(true);
  const [stats, setStats] = useState();
  const [data, setData] = useState({
    household_size: 0,
    sp1_grossincome: 0,
    sp1_netincome: 0,
    sp2_grossincome: 0,
    sp2_netincome: 0,
    childsupport: 0,
    miscincome: 0,
    housing_rent: 0,
    housing_taxes: 0,
    utilities_phone: 0,
    misc_phone: 0,
    utilities_garbage: 0,
    health_ins: 0,
    trans_insurance: 0,
    misc_lifeins: 0,
    misc_childsupport: 0,
    misc_alimony: 0,
    food_groceries: 0,
    food_fastfood: 0,
    food_restaurant: 0,
    housing_maintenance: 0,
    utilities_electricity: 0,
    utilities_gas: 0,
    trans_gas: 0,
    trans_parking: 0,
    trans_public: 0,
    misc_hair: 0,
    misc_laundry: 0,
    misc_clothes: 0,
    utilities_other: 0,
    health_doctor: 0,
    health_dentist: 0,
    food_other: 0,
    rec_other: 0,
    misc_other: 0,
    health_gym: 0,
    misc_electronics: 0,
    misc_hobbies: 0,
    food_coffee: 0,
    food_alcohol: 0,
    utilities_internet: 0,
    utilities_television: 0,
    rec_dvdrental: 0,
    rec_newspaper: 0,
    gifts_gifts: 0,
    gifts_donations: 0,
    gifts_tithes: 0,
    trans_other: 0,
  });
  const [data1, setData1] = useState([]);

  const handleNextStep = (newData) => {
    setData((prev) => ({
      ...prev,
      ...newData,
    }));
    if (currentStep !== 6) {
      setCurrentStep((prev) => prev + 1);
    } else {
    }
  };

  const handleprevStep = (newData) => {
    setData((prev) => ({
      ...prev,
      ...newData,
    }));
    setCurrentStep((prev) => prev - 1);
  };

  const handleNextDebtStep = (newData) => {
    setData1((prev) => [...prev, newData]);
  };

  const handleprevDebtStep = (newData) => {
    setData1((prev) => [...prev, newData]);
    setCurrentStep((prev) => prev - 1);
  };

  useEffect(() => {
    getBudgetAnalysisSecond(
      userId ? userId : user.user.data.id,
      userId ? "admin" : "user"
    )
      .then((res) => {
        const responseData = res.data.data;
        setGetLoading(false);
        setApiDataAvailable(true);
        if (Object.keys(responseData.budgetAnalysis).length > 0) {
          setData(responseData.budgetAnalysis);
        }
        if (Object.keys(responseData.unsecureDebt).length > 0) {
          setData1(responseData.unsecureDebt);
        }
      })
      .catch((error) => {
        setGetLoading(false);
      });
  }, []);

  const Step1 = ({ next, data }) => {
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          next(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  How many people do you have in your household?
                </label>
                <Field
                  type="number"
                  name="household_size"
                  className="form-control"
                />
                <ErrorMessage
                  name="household_size"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-12">
              <h6 className="mb-4 fw-700">Main Filer</h6>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Monthly Gross Income (before taxes)
                </label>
                <Field
                  type="number"
                  name="sp1_grossincome"
                  className="form-control"
                />
                <ErrorMessage
                  name="sp1_grossincome"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Monthly Net Income (take home pay)
                </label>
                <Field
                  type="number"
                  name="sp1_netincome"
                  className="form-control"
                />
                <ErrorMessage
                  name="sp1_netincome"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-12">
              <h6 className="mb-4 fw-700">Joint Filer</h6>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Monthly Gross Income (before taxes)
                </label>
                <Field
                  type="number"
                  name="sp2_grossincome"
                  className="form-control"
                />
                <ErrorMessage
                  name="sp2_grossincome"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Monthly Net Income (take home pay)
                </label>
                <Field
                  type="number"
                  name="sp2_netincome"
                  className="form-control"
                />
                <ErrorMessage
                  name="sp2_netincome"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Alimony
                </label>
                <Field type="number" name="alimony" className="form-control" />
                <ErrorMessage
                  name="alimony"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Child Support
                </label>
                <Field
                  type="number"
                  name="childsupport"
                  className="form-control"
                />
                <ErrorMessage
                  name="childsupport"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Misc. Income
                </label>
                <Field
                  type="number"
                  name="miscincome"
                  className="form-control"
                />
                <ErrorMessage
                  name="miscincome"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button type="submit" className="btn_brnd">
              Continue
            </button>
          </div>
        </Form>
      </Formik>
    );
  };
  const Step2 = ({ prev, next, data }) => {
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          next(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Rent or Home Mortgage
                </label>
                <Field
                  type="number"
                  name="housing_rent"
                  className="form-control"
                />
                <ErrorMessage
                  name="housing_rent"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Taxes or other Housing Expenses
                </label>
                <Field
                  type="number"
                  name="housing_taxes"
                  className="form-control"
                />
                <ErrorMessage
                  name="housing_taxes"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Home Phone
                </label>
                <Field
                  type="number"
                  name="utilities_phone"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_phone"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Cell Phone
                </label>
                <Field
                  type="number"
                  name="misc_phone"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_phone"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Garbage
                </label>
                <Field
                  type="number"
                  name="utilities_garbage"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_garbage"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Health Insurance
                </label>
                <Field
                  type="number"
                  name="health_ins"
                  className="form-control"
                />
                <ErrorMessage
                  name="health_ins"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Car Insurance
                </label>
                <Field
                  type="number"
                  name="trans_insurance"
                  className="form-control"
                />
                <ErrorMessage
                  name="trans_insurance"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Life Insurance
                </label>
                <Field
                  type="number"
                  name="misc_lifeins"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_lifeins"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Monthly Child Support Payment
                </label>
                <Field
                  type="number"
                  name="misc_childsupport"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_childsupport"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Monthly Alimony Payment
                </label>
                <Field
                  type="number"
                  name="misc_alimony"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_alimony"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button
              className="btn_brnd-outline mr-22"
              onClick={() => {
                prev(data);
              }}
            >
              Go Back
            </button>
            <button type="submit" className="btn_brnd">
              Continue
            </button>
          </div>
        </Form>
      </Formik>
    );
  };
  const Step3 = ({ prev, next, data }) => {
    return (
      <>
        <section className="manage-students box_s mt-60">
          <div className="card  pe-0">
            <div className="card-body pe-0">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Creditor</th>
                    <th scope="col">Payment</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data1.map((debts, index) => (
                    <tr key={index}>
                      <td>{debts.creditor}</td>
                      <td>{debts.payment}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="action-btns">
                          <button
                            className="btn-delete"
                            onClick={() => {
                              setData1(
                                data1.filter((item, ind) => ind !== index)
                              );
                            }}
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <Formik
          initialValues={{
            creditor: "",
            payment: 0,
            type: "",
            balance: 0,
            interest: 0,
          }}
          onSubmit={(values) => {
            next(values);
          }}
        >
          <Form>
            <div className="row mt-60">
              <div className="col-lg-6">
                <div className="input_wrap">
                  <label className="label-title position-static fa-14">
                    Creditor or Name of Monthly Payment
                  </label>
                  <Field
                    type="text"
                    name="creditor"
                    className="form-control"
                    required
                  />
                  <ErrorMessage
                    name="creditor"
                    component="div"
                    className="error text-danger"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input_wrap">
                  <label className="label-title position-static fa-14">
                    Payment Amount
                  </label>
                  <Field
                    type="number"
                    name="payment"
                    className="form-control"
                    required
                  />
                  <ErrorMessage
                    name="payment"
                    component="div"
                    className="error text-danger"
                  />
                </div>
              </div>
            </div>
            <div className="btn_wrap text-center mb-5">
              <button type="submit" className="btn_brnd">
                Add this payment to my Budget
              </button>
            </div>
            <div className="btn_wrap text-center">
              <button
                className="btn_brnd-outline mr-22"
                onClick={() => {
                  prev(data);
                }}
              >
                Go Back
              </button>
              <button
                onClick={() => {
                  setCurrentStep((prev) => prev + 1);
                }}
                className="btn_brnd"
              >
                Continue
              </button>
            </div>
          </Form>
        </Formik>
      </>
    );
  };
  const Step4 = ({ prev, next, data }) => {
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          next(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Groceries
                </label>
                <Field
                  type="number"
                  name="food_groceries"
                  className="form-control"
                />
                <ErrorMessage
                  name="food_groceries"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Fast Food
                </label>
                <Field
                  type="number"
                  name="food_fastfood"
                  className="form-control"
                />
                <ErrorMessage
                  name="food_fastfood"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Restaurant
                </label>
                <Field
                  type="number"
                  name="food_restaurant"
                  className="form-control"
                />
                <ErrorMessage
                  name="food_restaurant"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Pet Food and Supplies
                </label>
                <Field type="number" name="food_pet" className="form-control" />
                <ErrorMessage
                  name="food_pet"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Home Maintenance
                </label>
                <Field
                  type="number"
                  name="housing_maintenance"
                  className="form-control"
                />
                <ErrorMessage
                  name="housing_maintenance"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Electricity
                </label>
                <Field
                  type="number"
                  name="utilities_electricity"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_electricity"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Natural Gas
                </label>
                <Field
                  type="number"
                  name="utilities_gas"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_gas"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Gas & Oil
                </label>
                <Field
                  type="number"
                  name="trans_gas"
                  className="form-control"
                />
                <ErrorMessage
                  name="trans_gas"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Parking
                </label>
                <Field
                  type="number"
                  name="trans_parking"
                  className="form-control"
                />
                <ErrorMessage
                  name="trans_parking"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Public Transportation
                </label>
                <Field
                  type="number"
                  name="trans_public"
                  className="form-control"
                />
                <ErrorMessage
                  name="trans_public"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Hair and Grooming
                </label>
                <Field
                  type="number"
                  name="misc_hair"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_hair"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Laundry
                </label>
                <Field
                  type="number"
                  name="misc_laundry"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_laundry"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Clothing
                </label>
                <Field
                  type="number"
                  name="misc_clothes"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_clothes"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Other
                </label>
                <Field
                  type="number"
                  name="utilities_other"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_other"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button
              className="btn_brnd-outline mr-22"
              onClick={() => {
                prev(data);
              }}
            >
              Go Back
            </button>
            <button type="submit" className="btn_brnd">
              Continue
            </button>
          </div>
        </Form>
      </Formik>
    );
  };
  const Step5 = ({ prev, next, data }) => {
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          next(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Medical Expenses
                </label>
                <Field
                  type="number"
                  name="health_doctor"
                  className="form-control"
                />
                <ErrorMessage
                  name="health_doctor"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Dental Expenses
                </label>
                <Field
                  type="number"
                  name="health_dentist"
                  className="form-control"
                />
                <ErrorMessage
                  name="health_dentist"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Household Repairs\Upgrades
                </label>
                <Field
                  type="number"
                  name="food_other"
                  className="form-control"
                />
                <ErrorMessage
                  name="food_other"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Holiday/Travel Expenses
                </label>
                <Field
                  type="number"
                  name="rec_other"
                  className="form-control"
                />
                <ErrorMessage
                  name="rec_other"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Other
                </label>
                <Field
                  type="number"
                  name="misc_other"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_other"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button
              className="btn_brnd-outline mr-22"
              onClick={() => {
                prev(data);
              }}
            >
              Go Back
            </button>
            <button type="submit" className="btn_brnd">
              Continue
            </button>
          </div>
        </Form>
      </Formik>
    );
  };
  const Step6 = ({ prev, next, data }) => {
    const [postLoading, setPostLoading] = useState(false);
    const handleSubmit = async (values) => {
      setPostLoading(true);
      await postBudgetAnalysisSecond(
        userId ? userId : user.user.data.id,
        userId ? "admin" : "user",
        { budgetAnalysis: values, unsecureDebt: data1 }
      )
        .then((res) => {
          setPostLoading(false);
          setStats(res.data.data);
          next(values);
        })
        .catch((err) => {
          setPostLoading(false);
        });
    };
    return (
      <Formik
        initialValues={data}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form>
          <div className="row mt-60">
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">Gym</label>
                <Field
                  type="number"
                  name="health_gym"
                  className="form-control"
                />
                <ErrorMessage
                  name="health_gym"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Electronics/Software
                </label>
                <Field
                  type="number"
                  name="misc_electronics"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_electronics"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Hobbies
                </label>
                <Field
                  type="number"
                  name="misc_hobbies"
                  className="form-control"
                />
                <ErrorMessage
                  name="misc_hobbies"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Coffee Shop
                </label>
                <Field
                  type="number"
                  name="food_coffee"
                  className="form-control"
                />
                <ErrorMessage
                  name="food_coffee"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Alcohol/Bars
                </label>
                <Field
                  type="number"
                  name="food_alcohol"
                  className="form-control"
                />
                <ErrorMessage
                  name="food_alcohol"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Internet
                </label>
                <Field
                  type="number"
                  name="utilities_internet"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_internet"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Television
                </label>
                <Field
                  type="number"
                  name="utilities_television"
                  className="form-control"
                />
                <ErrorMessage
                  name="utilities_television"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Media rental/Netflix/Hulu
                </label>
                <Field
                  type="number"
                  name="rec_dvdrental"
                  className="form-control"
                />
                <ErrorMessage
                  name="rec_dvdrental"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Newspaper and Magazine Subscriptions
                </label>
                <Field
                  type="number"
                  name="rec_newspaper"
                  className="form-control"
                />
                <ErrorMessage
                  name="rec_newspaper"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Spa/Nails
                </label>
                <Field type="number" name="misc_spa" className="form-control" />
                <ErrorMessage
                  name="misc_spa"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Gifts
                </label>
                <Field
                  type="number"
                  name="gifts_gifts"
                  className="form-control"
                />
                <ErrorMessage
                  name="gifts_gifts"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Donations
                </label>
                <Field
                  type="number"
                  name="gifts_donations"
                  className="form-control"
                />
                <ErrorMessage
                  name="gifts_donations"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Tithes
                </label>
                <Field
                  type="number"
                  name="gifts_tithes"
                  className="form-control"
                />
                <ErrorMessage
                  name="gifts_tithes"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input_wrap">
                <label className="label-title position-static fa-14">
                  Other
                </label>
                <Field
                  type="number"
                  name="trans_other"
                  className="form-control"
                />
                <ErrorMessage
                  name="trans_other"
                  component="div"
                  className="error text-danger"
                />
              </div>
            </div>
          </div>
          <div className="btn_wrap text-center">
            <button
              className="btn_brnd-outline mr-22"
              disabled={postLoading}
              onClick={() => {
                prev(data);
              }}
            >
              Go Back
            </button>
            <button type="submit" disabled={postLoading} className="btn_brnd">
              {postLoading ? (
                <ThreeDots
                  height="20"
                  width="75"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    display: "block",
                  }}
                  wrapperClassName="spinner"
                  visible={true}
                />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </Form>
      </Formik>
    );
  };
  const Step7 = ({ prev, data }) => {
    return (
      <div>
        <div
          style={{
            borderRadius: "15px",
            backgroundColor: "#E0E0E04D",
            padding: "30px",
            margin: "40px 0px",
          }}
        >
          <div className="pb-3" style={{ borderBottom: "1px solid #0C192233" }}>
            <h6 className="fw-700 f-16">Income</h6>
            <p className="m-0 mb-1">
              Monthly Income{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.incomeObj.monthlyIncome}
              </span>
            </p>
            <p className="m-0 mb-1">
              Amount withheld{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.incomeObj.amountWithheld}
              </span>
            </p>
            <p className="m-0 mb-1">
              Net income{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.incomeObj.netIncome}
              </span>
            </p>
            <p className="m-0 mb-1">
              Total Expenses{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.incomeObj.totalExpenses}
              </span>
            </p>
            <p className="m-0 mb-1">
              Balance{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.incomeObj.balance}
              </span>
            </p>
          </div>
          <div className="py-3" style={{ borderBottom: "1px solid #0C192233" }}>
            <h6 className="fw-700 f-16">Fixed Expenses</h6>
            <p className="m-0 mb-1">
              Rent or Home Mortgage{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.mortgage}
              </span>
            </p>
            <p className="m-0 mb-1">
              Taxes or other Housing Expenses{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.taxes}
              </span>
            </p>
            <p className="m-0 mb-1">
              Home Phone{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.homePhone}
              </span>
            </p>
            <p className="m-0 mb-1">
              Cell Phone{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.cellPhone}
              </span>
            </p>
            <p className="m-0 mb-1">
              Garbage{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.garbage}
              </span>
            </p>
            <p className="m-0 mb-1">
              Health Insurance{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.healthInsurance}
              </span>
            </p>
            <p className="m-0 mb-1">
              Car Insurance{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.carInsurance}
              </span>
            </p>
            <p className="m-0 mb-1">
              Life Insurance{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.lifeInsurance}
              </span>
            </p>
            <p className="m-0 mb-1">
              Monthly Child Support Payment{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.childSupPay}
              </span>
            </p>
            <p className="m-0 mb-1">
              Monthly Alimony Payment{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.alimonyPayment}
              </span>
            </p>
            <p className="m-0 mb-1">
              Subtotal{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.fixedExpenses.subtotal}
              </span>
            </p>
          </div>

          <div className="py-3" style={{ borderBottom: "1px solid #0C192233" }}>
            <h6 className="fw-700 f-16">Fixed Payments</h6>
            {stats.fixedPayments.fixPayment &&
              stats.fixedPayments.fixPayment.length > 0 &&
              stats.fixedPayments.fixPayment.map((item, index) => (
                <p className="m-0 mb-1">
                  {item[0]}{" "}
                  <span className="fw-700" style={{ color: "#4E9C4B" }}>
                    {item[1]}
                  </span>
                </p>
              ))}
            <p className="m-0 mb-1">
              Subtotal{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.fixedPayments.fixTotal}
              </span>
            </p>
          </div>
          <div className="py-3" style={{ borderBottom: "1px solid #0C192233" }}>
            <h6 className="fw-700 f-16">Periodic Expenses </h6>
            <p className="m-0 mb-1">
              Medical Expenses{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.periodicExpenses.medicalExpenses}
              </span>
            </p>
            <p className="m-0 mb-1">
              Dental Expenses{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.periodicExpenses.dentalExpenses}
              </span>
            </p>
            <p className="m-0 mb-1">
              Household Repairs\Upgrade{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.periodicExpenses.householdRepairs}
              </span>
            </p>
            <p className="m-0 mb-1">
              Holiday/Travel Expenses{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.periodicExpenses.travelExpenses}
              </span>
            </p>
            <p className="m-0 mb-1">
              Other{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.periodicExpenses.other}
              </span>
            </p>
            <p className="m-0 mb-1">
              Subtotal{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                {stats.periodicExpenses.subtotal}
              </span>
            </p>
          </div>
          <div className="py-3" style={{ borderBottom: "1px solid #0C192233" }}>
            <h6 className="fw-700 f-16">Variable Expenses</h6>
            <p className="m-0 mb-1">
              Groceries{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.Groceries}
              </span>
            </p>
            <p className="m-0 mb-1">
              Fast Food{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.fastFood}
              </span>
            </p>
            <p className="m-0 mb-1">
              Restaurant{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.Restaurant}
              </span>
            </p>
            <p className="m-0 mb-1">
              Pet Food and Supplies{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.petFood}
              </span>
            </p>
            <p className="m-0 mb-1">
              Home Maintenance{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.homeMaintenance}
              </span>
            </p>
            <p className="m-0 mb-1">
              Electricity{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.electricity}
              </span>
            </p>
            <p className="m-0 mb-1">
              Natural Gas{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.naturalGas}
              </span>
            </p>
            <p className="m-0 mb-1">
              Gas & Oil{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.gas}
              </span>
            </p>
            <p className="m-0 mb-1">
              Parking{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.parking}
              </span>
            </p>
            <p className="m-0 mb-1">
              Public Transportation{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.public}
              </span>
            </p>
            <p className="m-0 mb-1">
              Hair and Grooming{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.hair}
              </span>
            </p>
            <p className="m-0 mb-1">
              Laundry{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.laundry}
              </span>
            </p>
            <p className="m-0 mb-1">
              Clothing{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.clothing}
              </span>
            </p>
            <p className="m-0 mb-1">
              Other{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.other}
              </span>
            </p>
            <p className="m-0 mb-1">
              Subtotal{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.variableExpenses.subtotal}
              </span>
            </p>
          </div>
          <div className="pt-3">
            <h6 className="fw-700 f-16">Discretionary Expenses</h6>
            <p className="m-0 mb-1">
              Gym{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.gym}
              </span>
            </p>
            <p className="m-0 mb-1">
              Electronics/Software{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.electronics}
              </span>
            </p>
            <p className="m-0 mb-1">
              Hobbies{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.hobbies}
              </span>
            </p>
            <p className="m-0 mb-1">
              Coffee Shop{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.coffee}
              </span>
            </p>
            <p className="m-0 mb-1">
              Alcohol/Bars{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.alcohol}
              </span>
            </p>
            <p className="m-0 mb-1">
              Internet{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.internet}
              </span>
            </p>
            <p className="m-0 mb-1">
              Television{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.television}
              </span>
            </p>
            <p className="m-0 mb-1">
              Media rental/Netflix/Hulu{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.mediaRental}
              </span>
            </p>
            <p className="m-0 mb-1">
              News and Magazine Subscriptions{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.news}
              </span>
            </p>
            <p className="m-0 mb-1">
              Spa/Nails{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.spa}
              </span>
            </p>
            <p className="m-0 mb-1">
              Gifts{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.gifts}
              </span>
            </p>
            <p className="m-0 mb-1">
              Donations{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.donations}
              </span>
            </p>
            <p className="m-0 mb-1">
              Tithes{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.tithes}
              </span>
            </p>
            <p className="m-0 mb-1">
              Other{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.other}
              </span>
            </p>
            <p className="m-0 mb-1">
              Subtotal{" "}
              <span className="fw-700" style={{ color: "#4E9C4B" }}>
                ${stats.discretionaryExpenses.subtotal}
              </span>
            </p>
          </div>
        </div>
        <div className="btn_wrap text-center">
          <button
            className="btn_brnd-outline mr-22"
            onClick={() => {
              prev(data);
            }}
          >
            Go Back
          </button>
          <button
            onClick={() => {
              if (from) {
                navigate("/dashboard");
              } else {
                if (userId) {
                  navigate(`/user-second-course?id=${userId}`);
                } else {
                  navigate("/2nd-course");
                }
              }
            }}
            className="btn_brnd"
          >
            Continue
          </button>
        </div>
      </div>
    );
  };

  const steps = [
    <Step1 next={handleNextStep} data={data} />,
    <Step2 prev={handleprevStep} next={handleNextStep} data={data} />,
    <Step3 prev={handleprevDebtStep} next={handleNextDebtStep} data={data1} />,
    <Step4 prev={handleprevStep} next={handleNextStep} data={data} />,
    <Step5 prev={handleprevStep} next={handleNextStep} data={data} />,
    <Step6 prev={handleprevStep} next={handleNextStep} data={data} />,
    <Step7
      prev={handleprevStep}
      next={() => {
        navigate("/course-2");
      }}
      data={data}
    />,
  ];
  return (
    <div className="step_form w-75 mx-auto">
      <Header />
      <div className="pt-4 mt-60">
        <h4 className="f-26 mb-5">Pre-Filing Credit Counseling </h4>
        <div className="step_progress_bar">
          <ul>
            {["1", "2", "3", "4", "5", "6", "7"].map((item, index) => (
              <li
                className={`${currentStep >= index ? "active-step" : ""}`}
                key={index}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        {currentStep < 3 && (
          <div class="box_text mt-4">
            {currentStep === 0 && (
              <p class="f-16">
                Please enter your income below. If you aren't taking this class
                with a spouse or live-in partner, leave that section blank.
              </p>
            )}
            {currentStep === 1 && (
              <p class="f-16">
                It is now time for us to take a look at your monthly expenses.
                Please complete this exercise by estimating the actual or
                average amounts of each category. If payments are made biweekly,
                quarterly, or annually, please adjust the amount to reflect
                monthly payment. In order for this Budget Analysis to work, it
                is important you accurately record each amount. We value your
                privacy and will never share any of the financial information
                you enter with anyone, so please provide the most honest answers
                possible. If a category does not apply to you, it is okay to
                leave it blank.
              </p>
            )}
            {currentStep === 2 && (
              <p class="f-16">
                Please enter any additional payments that you pay every month
                such as a car loan or other loan payments. These will be added
                to your monthly fixed expenses. You can also delete any payments
                in this list that are no longer relavant after you filed for
                bankruptcy.
              </p>
            )}
          </div>
        )}
        <div className="head d-flex align-content-center ps-4 mt-5">
          <span
            className="mb-0"
            style={{
              fontSize: "15px",
              fontWeight: "700",
            }}
          >
            Spending Plan - Monthly Fixed Payments
          </span>
        </div>
        {getloading ? (
          <div style={{ marginTop: "50px" }}>
            <ThreeDots
              height="40"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                display: "flex",
                justifyContent: "center",
              }}
              wrapperClassName="spinner"
              visible={true}
            />
          </div>
        ) : (
          apiDataAvailable && steps[currentStep]
        )}
      </div>
    </div>
  );
};

export default SpendingPlan;

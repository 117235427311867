import React from 'react';
import DashBoardSidebar from '../../layouts/dashBoard-Sidebar';
import DashboardNavbar from '../../layouts/dashboardNavbar';
const EnrollmentStats1stCourse = () => {
    return (
        <div>
               <div className="wrapper">
            <DashBoardSidebar/>
            {/* <!-- Page Content Holder --> */}
		<div id="content"> 
			<DashboardNavbar/>
            <section className="manage-students language_wrap">
				<h4 className="f26 ">Enrollment Statistics for First Course</h4> 
				<div className=" head  mt-60">
					<h1 className="f18">Date Range Search:</h1> 
				</div>
				<div className="row"> 
					<div className="col-5">
						<div className="input_wrap  mt-40"> 
							<label className="label-title">Start Date</label>
							<input type="text" className="form-control" placeholder="Enter start date"/> 
						</div>
					</div>
					<div className="col-2 d-flex align-items-center justify-content-center">
						<div className="form-group    "> 
							<label className="label-title  ">To</label> 
						</div>    
					</div>
					<div className="col-5">
						<div className="input_wrap   mt-40"> 
							<label className="label-title">End Date</label>
							<input type="text" className="form-control" placeholder="Enter end date"/> 
						</div>
					</div> 
					<div className="text-center  "> 
						<button className="btn_brnd mt-4 me-3">Display Statistics</button> 
								
								
					</div>
				</div>



				<div className="card  mt-60">
					
					<div className="card-body">
						<table className="table  table-responsive">
						  <thead>
						    <tr>
						      <th scope="col">Question</th>
						      <th scope="col"># Yes</th> 
							  <th scope="col"># No</th> 
							  <th scope="col"># Total</th> 
						    </tr>
						  </thead>
						  <tbody>
						    <tr>
						      <th scope="row">The goals of our course were explained clearly</th> 
							  <td>0 (0%)</td> 
							  <td>0 (0%) </td>   
							  <td>0 </td>   
						    </tr>
							<tr>
								<th scope="row">The course topics were relevant to my life</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
							  <tr>
								<th scope="row">The learning materials were helpful</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
							  <tr>
								<th scope="row">The course content was easy to understand</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
							  <tr>
								<th scope="row">The teacher was well prepared</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
							  <tr>
								<th scope="row">The website was easy to use</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
							  <tr>
								<th scope="row">I learned something I can use</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
							  <tr>
								<th scope="row">I will use a budget at home</th> 
								<td>0 (0%)</td> 
								<td>0 (0%) </td>   
								<td>0 </td>   
							  </tr>
					 
						   
						   
						  </tbody>
						</table> 
					</div>
				</div>
			</section>
		</div>
	</div>
        </div>
    );
};

export default EnrollmentStats1stCourse;
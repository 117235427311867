import React, { useState, useEffect } from "react";
import { deleteLanguage, getLanguages } from "../../api/server";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import PaginatedItems from "./pagination";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import DeleteModal from "../../common/deleteModal";

const LanguageRequests = () => {
  const [deleteLanguageId, setDeleteLanguage] = useState("");
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [pageCache, setPageCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);
        try {
          const res = await getLanguages(pageNo);
          const newData = res.data.data.result;
          const newTotal = res.data.data.total;

          // Update the cache with new data
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          // Set state with new data
          setResponse(newData);
          setTotal(newTotal);
        } catch (err) {
          toast.error(err.response.data.error);
        } finally {
          setGetLoading(false);
        }
      }
    };

    fetchData();
  }, [pageNo, pageCache]);

  const handleDeleteLanguage = async (id) => {
    setDeleteLoading(true);
    await deleteLanguage(
      id,
      response.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
    )
      .then((res) => {
        const newData = res.data.data.result;
        const newTotal = res.data.data.total;
        setResponse(newData);
        setTotal(newTotal);
        setPageNo(response.length > 1 ? pageNo : pageNo - 1);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        setDeleteLanguage("");
        toast.success("Language deleted successfully");
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteLanguage("");
        toast.error(err.response.data.error);
        setDeleteLoading(false);
      });
  };

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        {/* <!-- Page Content Holder --> */}
        <div id="content">
          <DashboardNavbar />
          <DeleteModal
            loading={deleteLoading}
            showModal={deleteLanguageId}
            setDelete={setDeleteLanguage}
            handleSubmit={() => {
              handleDeleteLanguage(deleteLanguageId);
            }}
          />
          <section className="manage-students language_wrap">
            <h4 className="f26 ">Language Request</h4>
            <div className="card mt-60">
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Language requested</th>
                      <th scope="col">Date/time</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={5}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      response.map((language, index) => (
                        <tr key={index}>
                          <th scope="row">{language.name}</th>
                          <td>{language.phone}</td>
                          <td>{language.language}</td>
                          <td>{language.date}</td>
                          <td>
                            <div className="action-btns">
                              <button
                                className="btn-delete"
                                onClick={() => {
                                  setDeleteLanguage(language.id);
                                }}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No language request found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!getloading && total !== 0 && total > 50 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LanguageRequests;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  let Component = props.component;
  useEffect(() => {
    if (!token || userState?.data?.type !== props.role) {
      navigate("/");
    }
  }, []);
  return <>{token && userState?.data?.type === props.role && Component}</>;
};
export default ProtectedRoutes;

import React, { useEffect, useState } from "react";
import navbarIcon from "../assets/images/navbar-icon.svg";
import arrow from "../assets/images/dashicons_arrow-up-alt2.png";
import editmyacount from "../assets/images/editmyacount.svg";
import logouticon from "../assets/images/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../store/userSlice";

const DashboardNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  return (
    <div>
      <div className="user-nav">
        <div className="user-nav-box w-100">
          <div className="navbar_header mb_show mr">
            <button
              type="button"
              id="sidebarCollapse"
              className=" "
              onClick={() => {
                document.getElementById("sidebar").style.marginLeft = "0px";
              }}
            >
              <img src={navbarIcon} className="menu" />
            </button>
          </div>

          <div className="user_wrap justify-content-end">
            <div>
              <div class="dropdown">
                <div
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  class=" dropdown-toggle"
                  style={{ display: "flex" }}
                >
                  <div className="me-1">
                    <p className="adminAvatar">
                      {userData.user.data.firstname}
                    </p>
                    <p>
                      {userData.user.data.type === "Atty"
                        ? "Attorney"
                        : "Admin"}
                    </p>
                  </div>
                  <div className="pt-1">
                    <img
                      style={{ width: "15px", height: "15px" }}
                      src={arrow}
                      alt=""
                    />
                  </div>
                </div>
                <ul class="dropdown-menu">
                  <li>
                    <span
                      class="dropdown-item"
                      onClick={() => {
                        userData.user.data.type === "Atty"
                          ? navigate("/edit-attorney-account")
                          : navigate("/editmyaccount");
                      }}
                    >
                      <img src={editmyacount} />
                      Edit My Account
                    </span>
                  </li>
                  <li>
                    <span
                      href="#"
                      class="dropdown-item"
                      onClick={() => {
                        dispatch(logout());
                        navigate("/login");
                      }}
                    >
                      <img src={logouticon} />
                      Logout
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbar;

import React from "react";
import logo from "../../assets/images/logo.png";
import navbar from "../../assets/images/SiteImg/navbar.svg";

import Footer from "../../layouts/footer";
const style = Object.assign(
  {},
  {
    "--bs-breadcrumb-divider": ">;",
  }
);
const JointFilerInfo = () => {
  return (
    <div>
      <header className="header header_wrap header_wrap_two">
        <div className="theme_container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="#">
              {" "}
              <img src={logo} alt="logo" />{" "}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src={navbar} alt="navbar" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto justify-content-center mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link active mr-80"
                    aria-current="page"
                    href="Attorneys.html"
                  >
                    Attorneys
                  </a>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link mr-80" href="About.html">
                    About Us
                  </a>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="Faq.html">
                    FAQ
                  </a>{" "}
                </li>
              </ul>
              <form className="d-flex">
                <button className="btn btn-outline-light mr-22" type="submit">
                Sign Up
                </button>
                <button className="btn btn-light" type="submit">
                  Login
                </button>
              </form>
            </div>
          </nav>
        </div>
      </header>

      <section className="breadcrumb-section mt-250">
        <div className="theme_container">
          <nav style={style} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">Disclosures</li>
              <li className="breadcrumb-item">Profile</li>
            </ol>
          </nav>
          <div className=" head  mt-60">
            <h1 className="f18">Attorney Info...</h1>
          </div>
          <div className="row Attorney_info mt-40">
            <div className="col-3 pl-45">
              <h5 className="f-16">
                <b>Code</b>
              </h5>
              <h5 className="f-16">
                <b>Name</b>
              </h5>
              <h5 className="f-16">
                <b>Email</b>
              </h5>
              <h5 className="f-16">
                <b>Address</b>
              </h5>
              <h5 className="f-16">
                <b>Course fee</b>
              </h5>
            </div>
            <div className="col-9">
              <p className="f-16 ">000000</p>
              <p className="f-16">Philip Tirone</p>
              <p className="f-16">philiptirone@evergreenclassName.com</p>
              <p className="f-16">asasasas, asasasas, asasasasa</p>
              <p className="f-16">
                Pre-Filing credit counselling:{" "}
                <span className="text_success f-26">
                  <b>$20</b>
                </span>{" "}
                ( for individual or joint course)
              </p>
            </div>
          </div>
          <div className=" head  mt-60">
            <h1 className="f18">Main Filer Info</h1>
          </div>
          <div className="row mt-40">
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Legal Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your first name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Middle Initial</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your middle name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Legal Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your last name"
                />{" "}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Last Four Digits of SSN</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter digit of SSN"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your phone no."
                />{" "}
              </div>
            </div>
          </div>

          <div className=" head  mt-40">
            <h1 className="f18">Contact Info</h1>
          </div>
          <div className="row mt-40">
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your address"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your address"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your city"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">State</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your state"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Zip Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter zip code"
                />{" "}
              </div>
            </div>
          </div>

          <div className=" head  mt-40">
            <h1 className="f18">Account Info...</h1>
          </div>
          <div className="row mt-40">
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Your Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Confirm Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Choose a Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter a username"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Choose a Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter your Password"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter your Password"
                />{" "}
              </div>
            </div>
            <div className="col-12">
              <div className="iagree_radio">
                <input
                  type="checkbox"
                  name="tickbox"
                  defaultChecked
                  id="joint"
                  className=" "
                />
                <label htmlFor="joint">Add a joint filer </label>
              </div>
            </div>
          </div>

          <div className=" head  mt-40">
            <h1 className="f18">Joint Filer Info</h1>
          </div>
          <div className="row mt-40">
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your first name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Middle Initial</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your middle name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your last name"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Last Four Digits of SSN</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter digit of SSN"
                />{" "}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your phone no."
                />{" "}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Joint Filer's Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your username"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className=" input_wrap ">
                <label className="label-title">Joint Filer's Password</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your password"
                />{" "}
              </div>
            </div>
            <div className="col-12">
              <div className="iagree_radio">
                <input
                  type="checkbox"
                  name="tickbox"
                  defaultChecked
                  id="joint"
                  className=" "
                />
                <label htmlFor="joint">Add a joint filer </label>
              </div>
            </div>
          </div>

          <div className=" head  mt-40">
            <h1 className="f18">Course Registration</h1>
          </div>
          <div className="row mt-40">
            <div className="iagree_radio mt-20">
              <input
                type="checkbox"
                name="tickbox"
                defaultChecked
                id="Pre-Filing"
                className=" "
              />
              <label htmlFor="Pre-Filing">Pre-Filing credit counselling</label>
            </div>
            <div className="iagree_radio mt-20">
              <input
                type="checkbox"
                name="tickbox"
                id="Post-Bankruptcy"
                className=" "
              />
              <label htmlFor="Post-Bankruptcy">
                Post Bankruptcy debtor education
              </label>
            </div>
            <div className="col-12">
              <div className="btn_wrap mt-60 text-center">
                <button className="btn_brnd-outline" data-bs-dismiss="modal" type="button">
                  Go Back
                </button>
                <button className="btn_brnd">Create an Account</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default JointFilerInfo;

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteStudent,
  getAllStudents,
  searchStudents,
} from "../../api/server";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import PaginatedItems from "./pagination";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import DeleteModal from "../../common/deleteModal";

const validationSchema = Yup.object({
  search: Yup.string().required("Name/Email is required"),
});

const ManageStudents = () => {
  const navigate = useNavigate();
  const [deleteUser, setDeleteUser] = useState("");
  const [response, setResponse] = useState();
  const [getloading, setGetLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const checked = ["checked", "Checked", "complete", "Complete"];

  const { handleChange, touched, values, errors, handleSubmit, resetForm } =
    useFormik({
      initialValues: { search: "" },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (searchCache[pageNo]) {
          // Data already in cache, no need for API call
          const { cachedResponse, cachedTotal } = searchCache[pageNo];
          setResponse(cachedResponse);
          setTotal(cachedTotal);
        } else {
          setFilterLoading(true);
          setGetLoading(true);
          searchStudents(pageNo, values)
            .then((res) => {
              setActiveSearch(true);
              const ungroupData = res.data.result;

              const groupedById = {};

              ungroupData.forEach((obj) => {
                if (!groupedById[obj.userID]) {
                  groupedById[obj.userID] = [];
                }
                groupedById[obj.userID].push(obj);
              });

              const newData = Object.values(groupedById);
              const newTotal = res.data.total;
              setSearchCache((prevCache) => ({
                ...prevCache,
                [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
              }));
              setResponse(newData);
              setTotal(newTotal);
              if (pageNo === 1) toast.success("Student search successful");
              setFilterLoading(false);
              setGetLoading(false);
            })
            .catch((err) => {
              toast.error(err.response.data.error);
              setFilterLoading(false);
              setGetLoading(false);
            });
        }
      },
    });

  const handlePageChange = () => {
    if (activeSearch) handleSubmit(values.search);
  };

  const handleClearFilters = () => {
    const { cachedResponse, cachedTotal } = pageCache[pageCache.pageNo];
    setPageNo(pageCache.pageNo);
    setActiveSearch(false);
    setTotal(cachedTotal);
    setSearchCache({});
    setResponse(cachedResponse);
    resetForm();
    toast.success("Student search cleared");
  };

  const hanldeDeleteUser = async (id) => {
    setDeleteLoading(true);
    await deleteStudent(
      id,
      response.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo
    )
      .then((res) => {
        const ungroupData = res.data.result;

        const groupedById = {};

        ungroupData.forEach((obj) => {
          if (!groupedById[obj.userID]) {
            groupedById[obj.userID] = [];
          }
          groupedById[obj.userID].push(obj);
        });

        const newData = Object.values(groupedById);
        const newTotal = res.data.total;
        setResponse(newData);
        setTotal(newTotal);
        setPageCache({
          [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        });
        if (activeSearch) {
          setPageNo(1);
        } else {
          setPageNo(response.length > 1 ? pageNo : pageNo - 1);
        }
        setActiveSearch(false);
        setSearchCache({});
        resetForm();
        setDeleteUser("");
        toast.success("Student deleted successfully");
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteUser("");
        toast.error(err.response.data.error);
        setDeleteLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setResponse(cachedResponse);
        setTotal(cachedTotal);
      } else {
        setGetLoading(true);
        try {
          const res = await getAllStudents(pageNo);
          const ungroupData = res.data.result;

          const groupedById = {};

          ungroupData.forEach((obj) => {
            if (!groupedById[obj.userID]) {
              groupedById[obj.userID] = [];
            }
            groupedById[obj.userID].push(obj);
          });

          const newData = Object.values(groupedById);

          const newTotal = res.data.total;

          // Update the cache with new data
          setPageCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));

          // Set state with new data
          setResponse(newData);
          setTotal(newTotal);
        } catch (err) {
          toast.error(err.response.data.error);
        } finally {
          setGetLoading(false);
        }
      }
    };
    if (!activeSearch) {
      fetchData();
    }
  }, [pageNo, pageCache]);

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <DeleteModal
            loading={deleteLoading}
            showModal={deleteUser}
            setDelete={setDeleteUser}
            handleSubmit={() => {
              hanldeDeleteUser(deleteUser);
            }}
          />
          {/* <!-- Page Content Holder --> */}
          <section className="manage-students">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h4 className="card-title">Manage Students</h4>
                    <div className="flex justify-content-end">
                      <button
                        className="btn_brnd mt-4"
                        onClick={() => {
                          navigate("/setupuseraccount");
                        }}
                      >
                        Set-up User
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <form onSubmit={handleSubmit}>
                      <div className="table-search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          style={{ paddingRight: "35px" }}
                          onChange={handleChange}
                          name="search"
                          value={values.search}
                        />
                        <button
                          disabled={filterLoading || getloading}
                          type="submit"
                          onClick={() => {
                            setPageCache((prevCache) => ({
                              ...prevCache,
                              pageNo: pageNo,
                            }));
                            setPageNo(1);
                            setSearchCache({});
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                      {errors.search && touched.search ? (
                        <p className="text-danger">{errors.search}</p>
                      ) : null}
                      {activeSearch && (
                        <button
                          className="btn_brnd mt-3 me-3 flex"
                          onClick={handleClearFilters}
                        >
                          Clear Search
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table  table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Attorney</th>
                      <th scope="col">Code</th>
                      <th scope="col">Course 1</th>
                      <th scope="col">Course 2</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getloading ? (
                      <tr>
                        <td colSpan={6}>
                          <ThreeDots
                            height="40"
                            width="80"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        </td>
                      </tr>
                    ) : response && response.length > 0 ? (
                      response.map((users, index) =>
                        users.map((user, ind) => (
                          <tr key={ind}>
                            <th
                              scope="row"
                              style={{
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.type === "Joint"
                                ? user.jointFirstName === "" &&
                                  user.jointLastName === ""
                                  ? user.userFirstName +
                                    " " +
                                    user.userLastName +
                                    "'s Joint"
                                  : user.jointFirstName +
                                    " " +
                                    user.jointLastName +
                                    " (Joint)"
                                : user.userFirstName + " " + user.userLastName}
                            </th>
                            <td
                              style={{
                                textTransform: "none",
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.UserEmail}
                            </td>
                            <td
                              style={{
                                textTransform: "none",
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.attorneyname}
                            </td>
                            <td
                              style={{
                                textTransform: "none",
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {user.code}
                            </td>
                            <td
                              style={{
                                textTransform: "none",
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {checked.includes(user.activated_first)
                                ? user.perFirstCourse.toFixed(0) + "%"
                                : "Not Enrolled"}
                            </td>
                            <td
                              style={{
                                borderBottomWidth:
                                  ind !== users.length - 1 ? "0px" : "",
                              }}
                            >
                              {checked.includes(user.activated_second)
                                ? user.perSecondCourse.toFixed(0) + "%"
                                : "Not Enrolled"}
                            </td>
                            {ind === 0 ? (
                              <>
                                <td
                                  style={{
                                    borderBottomWidth:
                                      ind !== users.length - 1 ? "0px" : "",
                                  }}
                                >
                                  <div className="action-btns">
                                    <button
                                      className="btn-delete"
                                      onClick={() => {
                                        setDeleteUser(user.userID);
                                      }}
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                    <Link to={`/editstudent?id=${user.userID}`}>
                                      <button className="btn-edit">
                                        <i className="fa-regular fa-pen-to-square"></i>
                                      </button>
                                    </Link>
                                  </div>
                                </td>
                              </>
                            ) : (
                              <td
                                style={{
                                  borderBottomWidth:
                                    ind !== users.length - 1 ? "0px" : "",
                                }}
                              ></td>
                            )}
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={6}>No students found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {!filterLoading && !getloading && total !== 0 && total > 50 && (
                  <PaginatedItems
                    total={total}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageStudents;

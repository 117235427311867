import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import navbarCloseIcon from "../assets/images/navbar-close-icon.svg";
import manageclients from "../assets/images/manageclients.svg";
import requestclientcard from "../assets/images/requestclientcard.svg";
import reportissue from "../assets/images/reportadnissue.svg";
import suggestion from "../assets/images/makeasuggestion.svg";
import testimonial from "../assets/images/testimonial.svg";

const AttorneyDashBoardSidebar = () => {
  const tabs = [
    {
      label: "Manage Clients",
      link: "/manage-clients",
      img: manageclients,
    },
    {
      label: "Request Client Cards",
      link: "/attoney-card-request",
      img: requestclientcard,
    },
    {
      label: "Report an Issue",
      link: "/attorney-issue-report",
      img: reportissue,
    },
    {
      label: "Make a Suggestion",
      link: "/make-suggestion",
      img: suggestion,
    },
    {
      label: "Leave a Testimonial",
      link: "/leave-testimonial",
      img: testimonial,
    },
    // {
    //   label: "Printable Info Sheets",
    //   link: "/printable-info-sheets",
    //   img: img3,
    // },
  ];

  return (
    <div>
      <nav id="sidebar">
        <div class="sidebar-header">
          <div class="logo_Wrap">
            <Link className="logo_hover" to={"/"}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div class="navbar_header mb_show_cross">
            <button type="button" id="sidebarCollapse_close" class=" close_btn">
              <img
                src={navbarCloseIcon}
                alt="navbarCloseIcon"
                class="close_btn"
              />
            </button>
          </div>
        </div>
        <ul class="list-unstyled components">
          {tabs.map((tab) => (
            <li>
              <NavLink to={tab.link} activeClassName="active">
                <img src={tab.img} alt="img1" /> {tab.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default AttorneyDashBoardSidebar;

import React, { useState } from "react";

import logo from "../../assets/images/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { forgotPassword, resetPassword } from "../../api/server";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const navigate = useNavigate();
  const [postLoading, setPostLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("New password is required")
      .min(8, "New password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Z])/,
        "New password must contain at least one uppercase letter"
      ),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Confirm password is required"),
  });
  const {
    handleChange,
    touched,
    values,
    resetForm,
    errors,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPostLoading(true);
      resetPassword({ password: values.password, token: token })
        .then((res) => {
          setPostLoading(false);
          if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/login");
          }
        })
        .catch((err) => {
          setPostLoading(false);
          if (err.response && err.response.data && err.response.data.error) {
            toast.error(err.response.data.error);
          } else {
            toast.error("An unexpected error occurred.");
          }
        });
    },
  });
  return (
    <div>
      <section className="login_wrap">
        <div className="info_login_content">
          <div className="content_login">
            <h1>
              Your path <span style={{ textTransform: "lowercase" }}>to</span>{" "}
              financial recovery!
            </h1>
          </div>
        </div>
        <div className="login_form w-100">
          <div
            className="content_login_form w-100"
            style={{ maxWidth: "500px" }}
          >
            <div className="login_logo">
              <Link to={`/`}>
                <img src={logo} alt="logo" />
              </Link>
              <p className="f18">Please enter your New Password</p>
            </div>

            <div className="mt-60">
              <form onSubmit={handleSubmit}>
                <div className=" input_wrap ">
                  <label className="label-title">Password</label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    value={values.password}
                    type={showPass ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter password"
                  />
                  {showPass ? (
                    <i
                      className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                      style={{ top: "20px", right: "10px" }}
                      onClick={() => {
                        setShowPass(false);
                      }}
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                      style={{ top: "20px", right: "10px" }}
                      onClick={() => {
                        setShowPass(true);
                      }}
                      aria-hidden="true"
                    ></i>
                  )}
                  {errors.password && touched.password ? (
                    <p className="text-danger">{errors.password}</p>
                  ) : null}
                </div>
                <div className=" input_wrap mt-40">
                  <label className="label-title">Confirm Password</label>
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    type={showConfirmPass ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Confirm Password"
                  />
                  {showConfirmPass ? (
                    <i
                      className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                      style={{ top: "20px", right: "10px" }}
                      onClick={() => {
                        setShowConfirmPass(false);
                      }}
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                      style={{ top: "20px", right: "10px" }}
                      onClick={() => {
                        setShowConfirmPass(true);
                      }}
                      aria-hidden="true"
                    ></i>
                  )}
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <p className="text-danger">{errors.confirmPassword}</p>
                  ) : null}
                </div>
                <button
                  className="btn_brnd mt-4 me-3  login_btn"
                  type="submit"
                  disabled={postLoading}
                >
                  {postLoading ? (
                    <ThreeDots
                      height="20"
                      width="60"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{
                        display: "block",
                      }}
                      wrapperClassName="spinner"
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;

import React from "react";
import { ThreeDots } from "react-loader-spinner";
import cross from "../assets/images/SiteImg/cross.png";
import { useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  card: Yup.number().required("Card number is required"),
  cvc: Yup.number().required("CVC is required"),
  expiry: Yup.number().required("Expiry Date is required"),
});

export default function StripePopup({
  showModal,
  setShowModal,
  pay,
  isLoading,
  setIsLoading,
  currentCourse = "",
  amount,
  values = {},
}) {
  const user = useSelector((state) => state.user);

  const handleSubmit = async (details) => {
    setIsLoading(true);

    const cardDetails = {
      ...details,
      expiry:
        details.expiry.toString().length < 4
          ? "0" + details.expiry.toString()
          : details.expiry.toString(),
    };
    if (values) {
      pay({
        cardDetail: {
          ...cardDetails,
          course: currentCourse,
          id: user.user.data.id,
          amount: amount,
        },
        enrollInfo: values,
      });
    } else {
      pay({
        cardDetail: {
          ...cardDetails,
          course: currentCourse,
          id: user.user.data.id,
          amount: amount,
        },
      });
    }
  };

  return (
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-hidden="true"
      className={`modal ${
        showModal !== "" ? "show" : ""
      } fade custom_modal stripe_modal`}
      style={{
        display: showModal !== "" ? "block" : "none",
        background: "rgba(0,0,0,0.5)",
      }}
    >
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ maxWidth: "600px", margin: "0px auto" }}
        >
          <button
            type="button"
            className="btn_close"
            data-bs-dismiss="modal"
            onClick={() => {
              setShowModal(false);
            }}
            disabled={isLoading}
          >
            <img src={cross} alt="cross" className="cross_btn" />
          </button>
          <div className="modal-body mt-5">
            <div className="modal_content">
              <Formik
                initialValues={{
                  name: "",
                  card: "",
                  cvc: "",
                  expiry: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input_wrap">
                        <label className="label-title">
                          Account Holder Name
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input_wrap">
                        <label className="label-title">Card Number</label>
                        <Field
                          type="number"
                          name="card"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="card"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="input_wrap">
                        <label className="label-title">CVC</label>
                        <Field
                          type="number"
                          name="cvc"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="cvc"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input_wrap">
                        <label className="label-title">
                          Expiry (e.g. 1225)
                        </label>
                        <Field
                          type="number"
                          name="expiry"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="expiry"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrap text-center mt-4">
                    <button
                      className={`btn_brnd ${isLoading ? "deleting" : ""}`}
                      disabled={isLoading}
                      type="submit"
                    >
                      {isLoading ? (
                        <ThreeDots
                          height="20"
                          width="52"
                          radius="9"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{
                            display: "block",
                          }}
                          wrapperClassName="spinner"
                          visible={true}
                        />
                      ) : (
                        "Pay"
                      )}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import ceo1 from "../../assets/images/SiteImg/ceo1.png";
import ceo2 from "../../assets/images/SiteImg/ceo2.png";

const Attorneys4 = ({ page }) => {
  // const changePages = (pages) => {
  //   page(pages);
  // };
  return (
    <div>
      {/* <!-- Attorney Page 4 --> */}
      {/* <section className="Client_Reviews mt-250">
        <div className="theme_container">
          <div className="heading_Wrap  ">
            <h1 className="f-52 text-center">
              Client's <span>Reviews</span>
            </h1>
            <p className="f-16 text-center mt-35">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="row mt-60">
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  <img src={ceo1} alt="ceo1" />
                  <div className="client_content">
                    <h1 className="f-18 fw-700">Sarah Nguyen</h1>
                    <p className="f-18 f-500 mb-0">CEO</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p>
                  <p className="f-16 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  <img src={ceo2} alt="ceo1" />
                  <div className="client_content">
                    <h1 className="f-18 fw-700">Joseph Lee</h1>
                    <p className="f-18 f-500 mb-0">CEO</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p>
                  <p className="f-16 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  <img src={ceo1} alt="ceo1" />
                  <div className="client_content">
                    <h1 className="f-18 fw-700">Sarah Nguyen</h1>
                    <p className="f-18 f-500 mb-0">CEO</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p>
                  <p className="f-16 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  <img src={ceo1} alt="ceo1" />
                  <div className="client_content">
                    <h1 className="f-18 fw-700">Sarah Nguyen</h1>
                    <p className="f-18 f-500 mb-0">CEO</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p>
                  <p className="f-16 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  <img src={ceo2} alt="ceo2" />
                  <div className="client_content">
                    <h1 className="f-18 fw-700">Joseph Lee</h1>
                    <p className="f-18 f-500 mb-0">CEO</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p>
                  <p className="f-16 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  <img src={ceo1} alt="ceo1" />
                  <div className="client_content">
                    <h1 className="f-18 fw-700">Sarah Nguyen</h1>
                    <p className="f-18 f-500 mb-0">CEO</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p>
                  <p className="f-16 mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="btn_wrap mt-60 text-center d-flex w-200">
              <button
                className="btn_brnd-outline w-148 mr-15"
                onClick={() => changePages("page3")}
              >
                Previous
              </button>
            </div>
          </div> *//*}
        </div>
      </section> */}

      <section className="Client_Reviews" style={{marginBottom: '4rem'}}>
        <div className="theme_container">
          <div className="heading_Wrap">
            <h1 className="f-52 text-center">
              Client <span> Reviews</span>
            </h1>
            <p className="f-16 text-center mt-35">
              At the core of Evergreen's philosophy is this belief: A person's debt does not define who they are as a person. We believe that each person is priceless, regardless of how much money they have or owe. That's why we offer low-cost and non-judgmental program for supporting you in your journey toward financial empowerment. But don't take it from us! Hear what some of our satisfied clients have to say about Evergreen Financial Consulting.
            </p>
          </div>
          <div className="row mt-60">
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">S.R. Auburn</h1>
                    <p className="f-18 f-500 mb-0">Wa</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    Great presentation and options for those dealing with financial problems.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo2} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">T.W. Caldwell</h1>
                    <p className="f-18 f-500 mb-0">Idaho</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    There were a bunch of interesting information from this course that really hit home thank you.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">T.M. Lebanon</h1>
                    <p className="f-18 f-500 mb-0">Or</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    Very good course. We learned a lot about budgets, credit and keeping track of spending.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">B.C. Lowell</h1>
                    <p className="f-18 f-500 mb-0">Oregon</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    Unexpectedly good, informative review of possible avenues... Very appropriate.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo2} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">R.C. Newport News</h1>
                    <p className="f-18 f-500 mb-0">Va</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Needed Encouragement
                  </p> */}
                  <p className="f-16 mb-0">
                    I was very glad to hear the teacher speak on encouragement. No matter what you go through you are still a valuable person. That made me feel so good!!.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="clinet_card">
                <div className="clinet_details">
                  {" "}
                  {/* <img src={ceo1} alt="ceo1" /> */}
                  <div className="client_content">
                    <h1 className="f-18 fw-700">R.E. Vancouver</h1>
                    <p className="f-18 f-500 mb-0">Washington</p>
                  </div>
                </div>
                <div className="clinet_body mt-12">
                  {/* <p className="text-success f-14 mb-1">
                    Pre-Filing Credit Counseling
                  </p> */}
                  <p className="f-16 mb-0">
                    The information was very useful and informational. It helps to understand the ways your spending plan can affect you on a daily basis.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Attorneys4;

import React from 'react';

const DashboardCourse = () => {
    return (
        <div>
            
	<div className="container my-5">
		<div className="course-list-video">
			<div className="row">
				<div className="col-lg-4">
					<h4>Pre-Filing Credit Counselling...</h4>
					<ul className="video-list">
						<li>
							<span className="checkbox"><i className="fa-solid fa-circle-check"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
							</div>
						</li>
						<li>
							<span className="checkbox"><i className="fa-solid fa-circle-check"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li className="active">
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
						<li>
							<span className="play"><i className="fa-regular fa-circle-play"></i></span>
							<div>
							<p><span>className 1:</span> Pre-Filing Credit Counselling...</p>
							<p>9 min</p>
						</div>
						</li>
					</ul>
				</div>
				<div className="col-lg-8">
					<h4>Pre-Filing Credit Counselling...</h4>
					<iframe width="100%" height="450" src="https://www.youtube.com/embed/9xwazD5SyVg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					<div className="text-end my-4">
						<button className="btn_brnd">Mark as Complete</button>
					</div>
					<h4>Transcript.</h4>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
					<p><b>Lorem Ipsum is simply dummy text:</b> of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
					<p><b>Lorem Ipsum is simply dummy text:</b> of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
				</div>
			</div>
		</div>
	</div>
        </div>
    );
};

export default DashboardCourse;
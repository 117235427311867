import React from "react";
import Header from "../../layouts/header";

const Privacy = () => {
  return (
    <div>
      <Header />
      <section className="about_us_page mt-250">
        <div className="theme_container">
          <h1 className="f-52 text-center">
            Privacy <span>Policy</span>
          </h1>
          <div className="row   mt-40 mt_one align-items-center">
            <div className="col-lg-12">
              <div className="about_content">
                <p className="mt-20 f-16">
                  Your privacy is important to Evergreen Financial Counseling.
                  While information is the cornerstone of our ability to provide
                  superior service, our most important asset is our customers’
                  trust. Keeping customer information secure, and using it only
                  as our customers would want us to, is a top priority for all
                  of us at Evergreen Financial Counseling. Here is our promise
                  to our individual customers.
                </p>
                <p className="mt-20 f-16">
                  1. We will safeguard, according to strict standards of
                  security and confidentiality, any information our customers
                  share with us.
                </p>
                <p className="mt-20 f-16">
                  2. We will limit the collection and use of customer
                  information to the minimum we require to deliver superior
                  service to our customers, which includes advising our
                  customers about our products, services, and other
                  opportunities, and to administer our business.
                </p>
                <p className="mt-20 f-16">
                  3. We will permit only authorized employees, who are trained
                  in the proper handling of customer information, to have access
                  to that information. Employees who violate our Privacy Promise
                  will be subject to our normal disciplinary process.
                </p>
                <p className="mt-20 f-16">
                  4. We will not reveal customer information to any external
                  organization unless we have previously informed the customer
                  in disclosures or agreements, have been authorized by the
                  customer, or are required by law.
                </p>
                <p className="mt-20 f-16">
                  5. We will always maintain control over the confidentiality of
                  our customer information. We may, however, facilitate relevant
                  offers from reputable companies. These companies are not
                  permitted to retain any customer information unless the
                  customer has specifically expressed interest in their products
                  or services.
                </p>
                <p className="mt-20 f-16">
                  6. We will tell customers in plain language initially how they
                  may remove their names from marketing lists. At any time,
                  customers can contact us to remove their names from such
                  lists.
                </p>
                <p className="mt-20 f-16">
                  7. Whenever we hire other organizations to provide support
                  services, we will require them to conform to our privacy
                  standards and to allow us to audit them for compliance.
                </p>
                <p className="mt-20 f-16">
                  8. For purposes of credit reporting, verification and risk
                  management, we may exchange information about our customers
                  with reputable reference sources and clearinghouse services.
                </p>
                <p className="mt-20 f-16">
                  9. We will attempt to keep customer files complete,
                  up-to-date, and accurate. We will tell our customers how and
                  where to conveniently access their account information (except
                  when we’re prohibited by law) and how to notify us about
                  errors, which we will promptly correct. We will continuously
                  assess ourselves to ensure that customer privacy is respected
                  and our integrity is maintained. We will conduct our business
                  in a manner that fulfills our Promise everywhere we do
                  business.
                </p>
                <p className="mt-20 f-16">
                  10. We and third parties use various technologies to collect
                  and store information when you use our Services, including
                  sending cookies, pixel tags, or other anonymous identifiers to
                  your device. “Cookies” are pieces of data stored on your
                  computer, mobile phone, or other devices. A “pixel tag” is a
                  type of technology placed within a website or email for the
                  purpose of tracking activity, which is often used in
                  combination with cookies. Other “anonymous identifiers” are
                  random strings of characters used for the same purposes as
                  cookies, such as with mobile devices where cookie technology
                  is not available. These technologies help us know that you are
                  logged on, provide you with features based on your
                  preferences, understand when you are interacting with our
                  Services, and compile other information regarding the use of
                  our Services.
                </p>
                <p className="mt-20 f-16">
                  The information collected through these technologies, standing
                  alone, cannot be used to determine your identity. Such
                  information may, however, be combined in a way that makes it
                  become personal information (i.e., information that can
                  identify you). If this happens, we will treat the combined
                  information as personal information. Please note that, in most
                  cases, you can remove or block cookies (such as by using the
                  settings in your browser), but doing so may affect your
                  ability to use our Services.
                </p>
                <p className="mt-20 f-16">
                  The use of tracking technologies by our service providers,
                  technology partners, or other third-party assets (such as
                  social media) on the Services is not covered by our Privacy
                  Policy. These third parties may use cookies, clear gifs,
                  images, and scripts to help them better manage their content
                  on our Services. We do not have access or control over these
                  technologies.
                </p>
                <p className="mt-20 f-16">
                  Technologies such as cookies, beacons, tags, and scripts are
                  used by our partners, affiliates, or analytics or service
                  providers. These technologies are used in analyzing trends,
                  administering the site, tracking users’ movements around the
                  site, and gathering demographic information about our user
                  base as a whole. We may receive reports based on the use of
                  these technologies by these companies on an individual as well
                  as aggregated basis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;

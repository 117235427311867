import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePopup from "./stripePopup";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeComponent = ({
  showModal,
  setShowModal,
  pay,
  isLoading,
  setIsLoading,
  currentCourse="",
  amount,
  values={}
}) => {
  return (
    <Elements stripe={stripePromise}>
      <StripePopup
        showModal={showModal}
        setShowModal={setShowModal}
        pay={pay}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        currentCourse={currentCourse}
        amount={amount}
        values={values}
      />
    </Elements>
  );
};

export default StripeComponent;

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import DashBoardSidebar from "../../layouts/dashBoard-Sidebar";
import DashboardNavbar from "../../layouts/dashboardNavbar";
import {
  VerifyUser,
  getCounty,
  getDistrict,
  getStates,
  userSignup,
} from "../../api/server";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const SetUpUserAccount = () => {
  const navigate = useNavigate();
  const [showJointFiler, setShowJointFiler] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    middleInitial: Yup.string(),
    lastName: Yup.string().required("Last Name is required"),
    ssn: Yup.string().required("Last Four Digits of SSN is required"),
    phone: Yup.string().required("Phone Number is required"),
    address1: Yup.string().required("Address Line 1 is required"),
    address2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string().required("Zip Code is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Your Email is required"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Emails must match")
      .required("Confirm Email is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[A-Z]/,
        "Password must contain at least one uppercase character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /[A-Z]/,
        "Password must contain at least one uppercase character"
      ),
    jointFiler_firstName: showJointFiler
      ? Yup.string().required("First name is required")
      : Yup.string(),
    jointFiler_lastName: showJointFiler
      ? Yup.string().required("Last name is required")
      : Yup.string(),
    jointFiler_ssn: Yup.string(),
    judicialdistrict_state: Yup.string().required("State is required"),
    judicialdistrict_county: Yup.string().required("County is required"),
    judicialdistrict: Yup.string().required("Judicial District is required"),
    jointFiler_username: showJointFiler
      ? Yup.string().required("Username is required")
      : Yup.string(),
    jointFiler_password: showJointFiler
      ? Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase character"
          )
      : Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase character"
          ),
    courseTaken: Yup.boolean(),
  });

  const [updateLoading, setUpdateLoading] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [countiesData, setCountiesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [userAttorney, setUserAttorney] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [atternyName, setAtternyName] = useState("");
  const [atternyEmail, setAtternyEmail] = useState("");
  const [atternyNumber, setAtternyNumber] = useState("");
  const [attCodeValidation, setattCodeValidation] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showAttymPass, setShowAttymPass] = useState(false);
  const [noCode, setNoCode] = useState(false);

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< handle Verify Attorney code >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const handleVerifyAttorney = async (e) => {
    setattCodeValidation(false);
    // e.preventDefault();
    const body = {
      code: userAttorney,
    };
    try {
      setLoading(true);
      const response = await VerifyUser(body);
      if (response.status === 200) {
        setReadOnly(true);
        const data = response.data;
        valuesSetter(response?.data?.data);
        setLoading(false);
      } else {
        setattCodeValidation(true);
      }
    } catch (error) {
      setattCodeValidation(true);
      console.error("Error fetching states data:", error);
      setLoading(false);
    }
  };
  //<<<<<<<<<<<<<<<<<<<<  Set Value If Coupon Exist  >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  function valuesSetter(data) {
    setAtternyEmail(data.email);
    setAtternyName(data?.firstname);
    setAtternyNumber(data?.phone);
  }

  const handleSubmit = async (values, { resetForm }) => {
    if (userAttorney && (!atternyNumber || !atternyEmail || !atternyName)) {
      toast.error("Please verify attorney code.");
    } else {
      setUpdateLoading(true);
      let payload = { ...values };
      if (readOnly) {
        payload.attycode = userAttorney;
      }
      payload.attyphone = atternyNumber;
      payload.attyemail = atternyEmail;
      payload.attorneyname = atternyName;
      await userSignup(payload)
        .then((res) => {
          toast.success("User added successfully");
          navigate(`/editstudent?id=${res.data.data.id}`);
          setUpdateLoading(false);
          resetForm();
          setAtternyEmail("");
          setAtternyName("");
          setAtternyNumber("");
          setUserAttorney("");
          setReadOnly(false);
        })
        .catch((error) => {
          setUpdateLoading(false);
          toast.error(error.response.data.error);
          console.error("Error updating user data:", error);
        });
    }
  };

  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await getStates();
        const data = response.data;
        setStatesData(data);
      } catch (error) {
        console.error("Error fetching states data:", error);
      }
    };
    fetchStatesData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setCountiesData([]);
      try {
        if (selectedState) {
          /////////////////////// get data of county////////////////
          const response = await getCounty({ state: selectedState });
          const countiesData = response.data;
          setCountiesData(countiesData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedState]);

  useEffect(() => {
    const fetchData = async () => {
      setDistrictsData([]);
      try {
        if (selectedCounty) {
          /////////////////////// get data of District////////////////
          const districtResponse = await getDistrict({
            state: selectedState,
            county: selectedCounty,
          });
          const distData = districtResponse.data;

          setDistrictsData(distData);
        }
      } catch (error) {}
    };

    fetchData();
  }, [selectedCounty]);

  const handleCheckboxChange = (e) => {
    setShowJointFiler(e.target.checked);
  };

  return (
    <div>
      <div className="wrapper">
        <DashBoardSidebar />
        <div id="content">
          <DashboardNavbar />
          <Formik
            initialValues={{
              firstName: "",
              middleInitial: "",
              lastName: "",
              phone: "",
              ssn: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
              email: "",
              confirmEmail: "",
              username: "",
              password: "",
              confirmPassword: "",
              jointFiler_firstName: "",
              jointFiler_middleInitial: "",
              jointFiler_lastName: "",
              attyemail: "",
              attycode: "",
              attyphone: "",
              attorneyname: "",
              attypref: "checked",
              // couponCode: "",
              judicialdistrict_state: "",
              judicialdistrict_county: "",
              judicialdistrict: "",
              jointFiler_username: "",
              jointFiler_password: "",
              jointFiler_ssn: "",
              courseTaken: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setValues, errors }) => (
              <Form>
                <div className="head">
                  <h1 className="f18">Main Filer Info</h1>
                </div>
                <div className="row mt-60">
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">First Name</label>
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Middle Initial</label>
                      <Field
                        type="text"
                        name="middleInitial"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="middleInitial"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Last Name</label>
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">
                        Last Four Digits of SSN
                      </label>
                      <Field type="text" className="form-control" name="ssn" />
                      <ErrorMessage
                        name="ssn"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Phone Number</label>
                      <Field
                        type="text"
                        name="phone"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                </div>

                {/* Contact Info */}
                <div className="head">
                  <h1 className="f18">Contact Info</h1>
                </div>
                <div className="row mt-60">
                  <div className="col-lg-6">
                    <div className="input_wrap">
                      <label className="label-title">Address Line 1</label>
                      <Field
                        type="text"
                        name="address1"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="address1"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input_wrap">
                      <label className="label-title">Address Line 2</label>
                      <Field
                        type="text"
                        name="address2"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">City</label>
                      <Field type="text" name="city" className="form-control" />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">State</label>
                      <Field name="state">
                        {({ field }) => (
                          <select
                            {...field}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                state: e.target.value,
                              });
                            }}
                            className="form-control"
                          >
                            {statesData.length > 0 && (
                              <option value="">Select a State</option>
                            )}

                            {statesData.length > 0 ? (
                              statesData.map((state, index) => (
                                <option key={index} value={state.state}>
                                  {state.state}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                        )}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Zip Code</label>
                      <Field type="text" name="zip" className="form-control" />
                      <ErrorMessage
                        name="zip"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                </div>

                {/* Account Info */}
                <div className="head">
                  <h1 className="f18">Account Info...</h1>
                </div>
                <div className="row mt-60">
                  <div className="col-lg-6">
                    <div className="input_wrap">
                      <label className="label-title">Your Email</label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input_wrap">
                      <label className="label-title">Confirm Email</label>
                      <Field
                        type="text"
                        name="confirmEmail"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="confirmEmail"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Choose a Username</label>
                      <Field
                        type="text"
                        name="username"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Choose a Password</label>
                      <Field
                        type={showPass ? "text" : "password"}
                        name="password"
                        className="form-control"
                      />
                      {showPass ? (
                        <i
                          className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                          style={{ top: "20px", right: "10px" }}
                          onClick={() => {
                            setShowPass(false);
                          }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                          style={{ top: "20px", right: "10px" }}
                          onClick={() => {
                            setShowPass(true);
                          }}
                          aria-hidden="true"
                        ></i>
                      )}
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="input_wrap">
                      <label className="label-title">Confirm Password</label>
                      <Field
                        type={showConfirmPass ? "text" : "password"}
                        name="confirmPassword"
                        className="form-control"
                      />
                      {showConfirmPass ? (
                        <i
                          className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                          style={{ top: "20px", right: "10px" }}
                          onClick={() => {
                            setShowConfirmPass(false);
                          }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                          style={{ top: "20px", right: "10px" }}
                          onClick={() => {
                            setShowConfirmPass(true);
                          }}
                          aria-hidden="true"
                        ></i>
                      )}
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>
                </div>

                <div className="head mt-40">
                  <h1 className="f18">Attorney Info</h1>
                </div>
                <div className="row mt-40">
                  {!noCode && (
                    <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">
                          Enter Attorney Code
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          value={userAttorney}
                          onChange={(e) => {
                            setUserAttorney(e?.target?.value);
                            setattCodeValidation(false);
                          }}
                          name="attycode"
                          placeholder="Enter Attorney code"
                        />
                        {attCodeValidation && (
                          <p className="text-danger">Invalid Attorney Code </p>
                        )}
                        {/* <ErrorMessage
                          name="attycode"
                          component="div"
                          className="error-message text-danger"
                        /> */}
                      </div>
                    </div>
                  )}
                  <div className="col-lg-4 col-md-6">
                    {!noCode && (
                      <button
                        className="btn_brnd-outline mr-22"
                        // disabled={postLoading}
                        type="button"
                        onClick={handleVerifyAttorney}
                      >
                        {loading ? (
                          <ThreeDots
                            height="20"
                            width="52"
                            radius="9"
                            color="#ed1717"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                              display: "block",
                            }}
                            wrapperClassName="spinner"
                            visible={true}
                          />
                        ) : (
                          "Verify"
                        )}
                      </button>
                    )}
                    <button
                      className="btn_brnd my-3"
                      type="button"
                      onClick={() => {
                        setNoCode(!noCode);
                        setReadOnly(false);
                        setUserAttorney("");
                        setAtternyName("");
                        setAtternyEmail("");
                        setAtternyNumber("");
                      }}
                    >
                      {noCode ? "Add Code" : " No Code ?"}
                    </button>
                  </div>
                </div>
                <div className="row mt-40">
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Attorney Email</label>
                      <Field
                        type="email"
                        className="form-control"
                        name="attyemail"
                        placeholder="Attorney Email"
                        readOnly={!noCode}
                        onChange={(e) => {
                          setAtternyEmail(e.target.value);
                        }}
                        value={atternyEmail}
                      />
                      <ErrorMessage
                        name="attyemail"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Attorney Name</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="attorneyname"
                        placeholder="Attorney Name"
                        readOnly={!noCode}
                        onChange={(e) => {
                          setAtternyName(e.target.value);
                        }}
                        value={atternyName}
                      />
                      <ErrorMessage
                        name="attorneyname"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Attorney Phone</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="attyphone"
                        placeholder="Attorney Phone"
                        readOnly={!noCode}
                        onChange={(e) => {
                          setAtternyNumber(e.target.value);
                        }}
                        value={atternyNumber}
                      />
                      <ErrorMessage
                        name="attyphone"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-6">
                      <div className="input_wrap">
                        <label className="label-title">Coupon Code</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="couponCode"
                          placeholder="Enter Coupon Code"
                        />
                        <ErrorMessage
                          name="couponCode"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    </div> */}
                  <div className="col-lg-4 col-md-6">
                    <div className="input_wrap">
                      <label className="label-title">Filing State</label>
                      <Field name="judicialdistrict_state">
                        {({ field }) => (
                          <select
                            {...field}
                            value={selectedState}
                            onChange={(e) => {
                              setSelectedState(e.target.value);
                              setSelectedCounty("");
                              setValues({
                                ...values,
                                judicialdistrict_state: e.target.value,
                              });
                            }}
                            className="form-control"
                          >
                            {statesData.length > 0 && (
                              <option value="">Select a State</option>
                            )}

                            {statesData.length > 0 ? (
                              statesData.map((state, index) => (
                                <option key={index} value={state.state}>
                                  {state.state}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                        )}
                      </Field>
                      <ErrorMessage
                        name="judicialdistrict_state"
                        component="div"
                        className="error text-danger"
                      />
                    </div>
                  </div>

                  {selectedState && (
                    <>
                      <div className="col-lg-4 col-md-6">
                        <div className="input_wrap">
                          <label className="label-title">Filing County</label>
                          <Field name="judicialdistrict_county">
                            {({ field }) => (
                              <select
                                {...field}
                                className="form-control"
                                value={selectedCounty}
                                onChange={(e) => {
                                  setSelectedCounty(e.target.value);
                                  setValues({
                                    ...values,
                                    judicialdistrict_county: e.target.value,
                                  });
                                }}
                              >
                                {countiesData.length > 0 && (
                                  <option value="">Select a county</option>
                                )}
                                {/* {values.judicialdistrict_county === "" && (
                                <option value="">Select a county</option>
                              )} */}
                                {countiesData.length > 0 ? (
                                  countiesData.map((county, index) => (
                                    <option key={index} value={county.county}>
                                      {county.county}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            )}
                          </Field>
                          <ErrorMessage
                            name="judicialdistrict_county"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>

                      {selectedCounty && (
                        <div className="col-lg-4 col-md-6">
                          <div className="input_wrap">
                            <label className="label-title">
                              Filing District
                            </label>
                            <Field name="judicialdistrict">
                              {({ field }) => (
                                <select {...field} className="form-control">
                                  {districtsData.length > 0 && (
                                    <option value="">
                                      Select a Judicial District
                                    </option>
                                  )}
                                  {/* {values.judicialdistrict === "" && (
                                <option value="">
                                  Select a Judicial District
                                </option>
                              )} */}
                                  {districtsData.length > 0 ? (
                                    districtsData.map((district, index) => (
                                      <option
                                        key={index}
                                        value={district.subdistrict}
                                      >
                                        {district.subdistrict}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Loading...</option>
                                  )}
                                </select>
                              )}
                            </Field>
                            <ErrorMessage
                              name="judicialdistrict"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="col-12 mb-4" style={{ userSelect: "none" }}>
                  <div className="iagree_radio">
                    <Field
                      name="takenByPhone"
                      id="courseTaken"
                      className=""
                      type="checkbox"
                    />

                    <label htmlFor="courseTaken">
                      Course Taken By Telephone
                    </label>
                  </div>
                </div>

                <div className="col-12 mb-4" style={{ userSelect: "none" }}>
                  <div className="iagree_radio">
                    <input
                      type="checkbox"
                      name="isJointFiler"
                      id="joint"
                      onChange={handleCheckboxChange}
                      className=""
                    />

                    <label htmlFor="joint">Add a joint filer</label>
                  </div>
                </div>
                {/* Joint Filer Info */}

                {showJointFiler && (
                  <>
                    <div className="head">
                      <h1 className="f18">Joint Filer Info...</h1>
                    </div>
                    <div className="row mt-60">
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">First Name</label>
                          <Field
                            type="text"
                            name="jointFiler_firstName"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="jointFiler_firstName"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">Middle Initial</label>
                          <Field
                            type="text"
                            name="jointFiler_middleInitial"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="jointFiler_middleInitial"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">Last Name</label>
                          <Field
                            type="text"
                            name="jointFiler_lastName"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="jointFiler_ssn"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">
                            Last Four Digits of SSN
                          </label>
                          <Field
                            type="text"
                            name="jointFiler_ssn"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="jointFiler_ssn"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">
                            Joint Filer's Username
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="jointFiler_username"
                          />
                          <ErrorMessage
                            name="jointFiler_username"
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input_wrap">
                          <label className="label-title">
                            Joint Filer's Password
                          </label>
                          <Field
                            type={showAttymPass ? "text" : "password"}
                            className="form-control"
                            name="jointFiler_password"
                          />
                          {showAttymPass ? (
                            <i
                              className="fa fa-eye position-absolute cursor-pointer pt-lg-1"
                              style={{ top: "20px", right: "10px" }}
                              onClick={() => {
                                setShowAttymPass(false);
                              }}
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash position-absolute cursor-pointer pt-lg-1"
                              style={{ top: "20px", right: "10px" }}
                              onClick={() => {
                                setShowAttymPass(true);
                              }}
                              aria-hidden="true"
                            ></i>
                          )}
                          <ErrorMessage
                            name="jointFiler_password"
                            component="div"
                            className="error-message text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="text-center">
                  <button
                    className="btn_brnd my-3"
                    type="submit"
                    disabled={updateLoading}
                    onClick={() => {
                      if (Object.keys(errors).length > 0) {
                        toast.error("Form validation failed");
                      }
                    }}
                  >
                    {updateLoading ? (
                      <ThreeDots
                        height="20"
                        width="60"
                        radius="9"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          display: "block",
                        }}
                        wrapperClassName="spinner"
                        visible={true}
                      />
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SetUpUserAccount;
